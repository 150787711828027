import MainTable from "components/Table/MainTable";
import { PAID_CLAIM_DETAILS_TABLE } from "constants/personalization";
import { Button, Modal } from "react-bootstrap";

const PaidClaimDetailsModal = (props) => {
  const { handleClose, detailsObj } = props;

  return (
    <Modal show backdrop="static" animation={true} onHide={handleClose} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Paid Claim Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="allowVerticalScroll" style={{ paddingTop: 0 }}>
        <div className="d-flex justify-content-between mb-4">
          <div className="paidClaim-subHeading">
            <span className="fw-bold">Filtered Span: </span>
            <span className="text-capitalize">{detailsObj.selectedSpan}</span>
          </div>
          <div className="paidClaim-subHeading">
            <span className="fw-bold">Filtered Time: </span>
            <span className="text-capitalize">{detailsObj.date}</span>
          </div>
        </div>
        <MainTable columns={PAID_CLAIM_DETAILS_TABLE} rows={detailsObj.rows} cssClass={"non-scrollable-table"} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaidClaimDetailsModal;
