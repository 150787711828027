import React, { useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import { formatDateMDY, formatDateMDYTime, toTitleCase } from "utils";
import api from "api";
import { Audit_Types, Zone_Audit_Types, AdditionalKeys, applyCustomOrder, fieldLabel } from "constant";
import { Audit_Parse } from "constant";
import OrderLogsCollapseTable from "./Order/components/OrderLogsCollapseTable";
import { tdRowDropdown } from "utils";

const AuditTrailEntries = (props) => {
  const { item, auditTrails, user, handleOpenDetailModal } = props;
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);

  const handleExpand = () => {
    const sortList = [...auditTrails].sort((a, b) =>
      b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
    );
    let logs = sortList.filter((f) => Audit_Types[f.eventType] === item);
    setItems(logs);
    setShow(!show);
  };

  const TableRow = ({ log, index }) => {
    const [open, setOpen] = useState(null);
    return (
      <>
        <tr>
          {tdRowDropdown(
            log.changeObject &&
              log.changeObject.newRecord &&
              applyCustomOrder(Object.keys(log.changeObject.newRecord), Object.keys(fieldLabel)).filter(
                (f) => !AdditionalKeys.includes(f)
              ).length > 0,
            open,
            () => setOpen(!open ? log.changeObject : null)
          )}
          <td>{index + 1}</td>
          <td>{formatDateMDY(log.createdAt)}</td>
          <td
            style={{
              textAlign: "center",
              cursor: log.eventType === "Eligibility" ? "pointer" : "",
            }}
            onClick={() => log.eventType === "Eligibility" && handleOpenDetailModal(log)}
          >
            {Audit_Parse[log.eventType]}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {log.result || log.action || log.reason || (log.eventData && (Audit_Parse[log.eventData] || log.eventData))}
          </td>
          <td style={{ textAlign: "center" }}>{log.userName || "System"}</td>
          {/* <td
            onClick={async () => {
              await api.deleteLogRecord(log.id);
            }}
          >
            <i className="fas fa-trash cursor-pointer" />
          </td> */}
        </tr>
        {open && <OrderLogsCollapseTable open={open} />}
      </>
    );
  };
  return (
    <>
      <tr
        onClick={handleExpand}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
      >
        {tdRowDropdown(true, show)}
        <td>{item}</td>
      </tr>
      {items.length > 0 && show && (
        <tr className="subTableRow">
          <td colSpan={"12"} className="expended-table-wrapper">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTable">
                      <thead>
                        <tr>
                          <th />
                          <th style={{ width: "10%" }}>Sr#</th>
                          <th>Date</th>
                          <th className="border-0 centered">Event</th>
                          <th className="border-0 centered w-25">Action</th>
                          {/* <th className="border-0 centered">Detail</th> */}
                          <th className="border-0 centered">User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0 &&
                          items.map((item, i) => {
                            return <TableRow key={i} index={i} log={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default AuditTrailEntries;
