import { API_ACTIONS } from "constant";
import { GET_CLAIM_STATS, EMPLOYEE_CLAIM_FETCH } from "store/features/employeeClaims/employeeClaimsConstant";
import { setLoader } from "../features/general/generalSlice";

const loaderMiddleware = (store) => (next) => (action) => {
  const { dispatch } = store;
  const notToCheck = [GET_CLAIM_STATS, EMPLOYEE_CLAIM_FETCH].map((key) => key + "/" + API_ACTIONS.pending);
  if (action.type.endsWith(API_ACTIONS.fulfilled) || action.type.endsWith(API_ACTIONS.rejected)) {
    dispatch(setLoader(false)); // Dispatch setLoader(false) after async action completes or throws an error
  } else if (action.type.endsWith(API_ACTIONS.pending) && !notToCheck.includes(action.type)) {
    dispatch(setLoader(true)); // Dispatch setLoader(true) before async action starts
  }
  return next(action);
};

export default loaderMiddleware;
