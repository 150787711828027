import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import AddGeneralSettingsModal from "components/GeneralSettings/AddGeneralSettingsModal";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import HeaderItem from "components/Table/HeaderItem";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCliList, updateCompanySettingCLIList } from "store/features/companySetting/companySettingSlice";
import "../../../src/tableStyling.css";
import EditIcon from "../../assets/img/edit-icon.png";
import TrashIcon from "../../assets/img/trash-icon.png";
import AddProcedureCodeSettingsModal from "../../components/GeneralSettings/AddProcedureCodeSettingsModal";
import { CLI_ACTIONS, CLI_DEFAULT_OPTIONS } from "../../constant";
import {
  cptCodeSelector,
  openCreateCPTModalSelector,
  selectedCPTItemSelector,
} from "../../store/features/cptCodes/cptCodeSelectors";
import { t } from "../../stringConstants";
import Filter from "components/Filter";
import { calculateTdWidth, formatNumber } from "../../utils";
import {
  fetchCPTCodeAsync,
  setOpenCreateCPTModal,
  setProcedureList,
  setSelectedCPTItem,
} from "../../store/features/cptCodes/cptCodeSlice";
import { debounce } from "debounce";

const ProcedureCodeSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const procedureList = useSelector(cptCodeSelector);
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [width, setWidth] = useState(0);
  const mainWidth = useRef();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const selectedCPTItem = useSelector(selectedCPTItemSelector);
  const openCreateCPTModal = useSelector(openCreateCPTModalSelector);

  const searchTerms = ["Procedure Code"];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 160, 2);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    dispatch(setProcedureList(sortUsers(procedureList, sortBy)));
  }, [sortBy, sortDescending]);

  const fetchFromApi = useCallback(
    debounce(async (filter) => {
      dispatch(fetchCPTCodeAsync(Object.keys(filter).length > 0 ? filter : { code: "a" }));
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(filter);
  }, [filter]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const TableRow = ({ item, index }) => {
    return (
      <tr>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          <img
            src={EditIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => {
              dispatch(setSelectedCPTItem({ item, index }));
              dispatch(setOpenCreateCPTModal(true));
            }}
          />
        </td>
        <td className="ellipsis" title={item.code}>
          {item.code}
        </td>

        <td className="ellipsis" title={item.description}>
          {item.description}
        </td>
        <td className="ellipsis text-center" title={item.charges}>
          {item.charges}
        </td>

        <td className="ellipsis text-center" title={item.isActive}>
          {item.isActive ? <ViewCheckBox id="filter" bgColor="green" /> : "-"}
        </td>
        {/* <td
          className="icon"
          style={{
            textOverflow: "visible",
            width: "45px",
            minWidth: "45px",
            maxWidth: "45px",
          }}
        >
          <img
            src={TrashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => {
              updateProcCodeData({ ...item, action: CLI_ACTIONS.delete }, index);
            }}
          />
        </td> */}
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <>
          <Row>
            <Col>
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {t("procedureCode")}
                  </Card.Title>
                  <div className="buttonHeader">
                    <Icon
                      handleClick={() => setShowFilter(!showFilter)}
                      title={"Filter"}
                      label={"Filter"}
                      iconType={"filter"}
                    />
                    <Icon
                      handleClick={() => dispatch(setOpenCreateCPTModal(true))}
                      title={"Create Procedure"}
                      label={"Create"}
                      iconType={"addUserIcon"}
                    />
                  </div>
                  {showFilter && (
                    <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} />
                  )}
                </Card.Header>

                <Card.Body className="table-full-width desktop-noScroll">
                  <div className="table-responsive pendingReleaseTable">
                    <Table>
                      <thead ref={componentRef}>
                        <tr>
                          <th />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey={"code"}
                            title={"Procedure Code"}
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned={"left"}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey={"description"}
                            title={"Procedure Code Desc"}
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned={"left"}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey={"charges"}
                            title={"Charge"}
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned={"centered"}
                          />

                          <HeaderItem
                            width={tdWidth}
                            ItemKey={"isActive"}
                            title={"Active"}
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned={"centered"}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {procedureList &&
                          procedureList.map((item, i) => {
                            return <TableRow key={i} item={item} index={i} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {openCreateCPTModal && (
              <AddProcedureCodeSettingsModal
                handleClose={() => {
                  dispatch(setOpenCreateCPTModal(false));
                  dispatch(setSelectedCPTItem(null));
                }}
                selectedItem={selectedCPTItem?.item || null}
              />
            )}
          </Row>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ProcedureCodeSettings;
