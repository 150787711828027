import ErrorMessage from "components/Message/ErrorMessage";
import RadioButtonOptions from "components/RadioButton/RadioButtonOptions";
import {
  INSURANCE_PROVIDER,
  PAID_CLAIM_FILTERING_SPANS,
  PAID_CLAIM_FILTERING_SPANS_OPTS,
  PAID_CLAIM_SPANS_MODAL_FORMAT,
} from "constant";
import moment from "moment";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import Select from "react-select";

const PaidClaimsFilterModal = (props) => {
  const { handleClose, handleChange } = props;
  const [updatedDate, setUpdatedDate] = useState({
    date: "",
    selectedSpan: PAID_CLAIM_FILTERING_SPANS.yearly,
    payerid: "",
  });
  const [error, setError] = useState("");

  const handleSave = () => {
    if (!updatedDate.selectedSpan) {
      setError("Please select any of the given time spans!");
      return;
    }
    if (!updatedDate.date) {
      setError("Please select a time!");
      return;
    }
    handleChange(updatedDate);
  };

  const handleDatechange = (date) => {
    moment(date).format(PAID_CLAIM_SPANS_MODAL_FORMAT[updatedDate.selectedSpan].momentFormat) != "Invalid date" &&
      setUpdatedDate({
        ...updatedDate,
        date: moment(date).format(PAID_CLAIM_SPANS_MODAL_FORMAT[updatedDate.selectedSpan].momentFormat),
      });
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={handleClose} centered size={"md"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Filter Paid Claims
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <RadioButtonOptions
          label={"Select Span: "}
          options={PAID_CLAIM_FILTERING_SPANS_OPTS}
          name={`PAID_CLAIM_FILTERING_SPANS_OPTS`}
          checkedVal={updatedDate.selectedSpan}
          handleChange={(e, obj) => e.target.checked && setUpdatedDate({ ...updatedDate, selectedSpan: obj.value })}
        />
        <Form.Group className="form-group-wrapper">
          {updatedDate.selectedSpan && (
            <>
              <DatePicker
                className="w-100 orderTestDob"
                format={PAID_CLAIM_SPANS_MODAL_FORMAT[updatedDate.selectedSpan].datePickerFormat}
                dateFormat={"MM/YYYY"}
                onClickDay={(v, e) => handleDatechange(v)}
                onClickMonth={(v, e) => handleDatechange(v)}
                onClickYear={(v, e) => handleDatechange(v)}
                value={
                  new Date(updatedDate.date) != "Invalid Date"
                    ? moment(
                        updatedDate.date,
                        PAID_CLAIM_SPANS_MODAL_FORMAT[updatedDate.selectedSpan].momentFormat
                      ).toDate()
                    : null
                }
                maxDate={new Date()}
                minDate={new Date("1900-01-01")}
                view={PAID_CLAIM_SPANS_MODAL_FORMAT[updatedDate.selectedSpan].datePickerView}
              />
            </>
          )}
        </Form.Group>
        <Form.Group className="form-group-wrapper">
          <Select
            className="w-100"
            options={INSURANCE_PROVIDER}
            placeholder="Select Insurance"
            value={INSURANCE_PROVIDER.find((ins) => ins.value === updatedDate.payerid)}
            onChange={(e) => setUpdatedDate({ ...updatedDate, payerid: e ? e.value : "" })}
            isClearable={true}
            clear
          />
        </Form.Group>
      </Modal.Body>
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={handleSave}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaidClaimsFilterModal;
