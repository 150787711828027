import api from "api";
import { Storage } from "aws-amplify";
import Filter from "components/Filter";
import FilterSelect from "components/FilterSelect";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import AssignClaimModal from "components/Modal/AssignClaimModal/AssignClaimModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import NotesModal from "components/Modal/NotesModal";
import CreateClaimResubmissionModal from "./CreateClaimResubmissionModal";
import EraModal from "components/Modal/Order/EraModal";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import MFPagination from "components/Pagination/MFPagination";
import SingleStatsCard from "components/SingleStatsCard";
import MainTable from "components/Table/MainTable";
import TimeFilter from "components/TimeFilter";
import { ErrorBoundary } from "react-error-boundary";
import {
  AltaSetting,
  ClaimDropDownOptions,
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  currencyformatter,
  EMPLOYEE_CLAIM,
  EMP_CLAIM_BLANK_OPTS,
  ERA_VIEW_STATUS,
  INSURANCE_PROVIDER,
  MESSAGE_MODES,
  NEW_PROVIDER_OBJECT,
  PAGE_LIMIT,
  TABLE_QUICK_TOOLS,
  usersNo,
} from "constant";
import { EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import { debounce } from "debounce";
import graphqlApi from "graphqlApi";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { selectedClients } from "store/features/clients/clientsSelectors";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { fetchCompanySetting } from "store/features/companySetting/companySettingSlice";
import {
  acknowledgeClaim,
  deleteEmployeeClaim,
  fetchAllEmployeeClaims,
} from "store/features/employeeClaims/employeeClaimsActions";
import { claimSelector } from "store/features/employeeClaims/employeeClaimsSelectors";
import {
  assignEmployeeClaimAsync,
  setFilteredEmployeeClaims,
  setPageNo,
  setStatsObj,
  updateNotes,
  updateBulkSubmitEmployeeClaimAsync,
  getStatsSummaryClaimAsync,
} from "store/features/employeeClaims/employeeClaimsSlice";
import { employeesSelector } from "store/features/employees/employeesSelectors";
import { fetchAllEmployees } from "store/features/employees/employeesSlice";
import { setMessage } from "store/features/general/generalAction";
import { selectedProvider } from "store/features/providers/providersSelectors";
import { t } from "stringConstants";
import "tableStyling.css";
import {
  CapsFirstLetter,
  downloadDataAsCSV,
  draggablePersonalizationLocalStorage,
  formatDateMDY,
  formatEmployeeClaim,
  formatNumber,
  getValidDep,
  isMatchString,
  parseClaimDate,
  phoneFormatter,
  RemoveSpaceAndChar,
  trimObject,
  userCompanyID,
} from "utils";
import ChangeClaimStatusModal from "./ChangeClaimStatusModal";
import { USERS } from "constant";
import { STATUS_KEYS_RE_SUBMIT } from "constant";
import { claimReSubmissionSelector } from "store/features/employeeClaims/employeeClaimsSelectors";
import { setClaimReSubmissionModal } from "store/features/employeeClaims/employeeClaimsSlice";
import ErrorMessageModal from "components/Modal/ErrorMessageModal";

const EmployeeClaimAPI = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openClaimSubmitConfirmation, setOpenClaimSubmitConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [openEraModal, setOpenEraModal] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [claimNote, setClaimNote] = useState(null);
  const [changeClaimStatusModal, setChangeClaimStatusModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { employeeClaims, error, pageNo, filteredEmployeeClaims, totalRecord, statsObj } = useSelector(claimSelector);
  const setting = useSelector(selectedCompanySetting);
  const { clients } = useSelector(selectedClients);
  const { providers } = useSelector(selectedProvider);
  const loginUser = useSelector(selectLoginUser);
  const { employees } = useSelector(employeesSelector);
  const { permission } = useSelector(authSelector);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const claimReSubmissionModal = useSelector(claimReSubmissionSelector);
  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    (permission[EMPLOYEE_CLAIM]?.write || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.edit,
    (permission[EMPLOYEE_CLAIM]?.write || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.notes,
  ];
  const { filterDates } = TimeFilter;

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      const nextTerm = location.state.filterTerm;
      const nextTermName = location.state.filterName;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
      if (nextTerm && nextTermName) {
        setFilter({
          ...filter,
          [nextTerm]: nextTermName,
          [filterTerm]: filterTermName,
        });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Address",
    "Apt/Unit#",
    "City",
    "State",
    "Zip Code",
    "Date Of Birth",
    "Gender",
    "Insurance",
    "Insurance #",
    "Claim ID",
    "Claim Amount",
    "Adjusted Amount",
    "Paid Amount",
    "DOS",
    "Status",
    "LTCS ID",
    "B-ID",
    "Assigned To",
    "Paid By",
    "Processed By Secondary Ins",
    "Resubmitted Claims",
    "Crossover Claims",
    "Assign To Me",
    "Blank",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  useEffect(() => {
    if (pageNo > 1) {
      dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
    }
  }, [pageNo]);

  const sortUsers = (users, sortBy) => {
    if (!sortBy) {
      return [...users].sort((a, b) =>
        `${b["pat_name_f"]}${b["pat_name_l"]}` < `${a["pat_name_f"]}${a["pat_name_l"]}`
          ? 1
          : `${a["pat_name_f"]}${a["pat_name_l"]}` < `${b["pat_name_f"]}${b["pat_name_l"]}`
          ? -1
          : 0
      );
    }

    if (sortDescending) {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return fieldA < fieldB ? -1 : 1;
        }
      });
    } else {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        } else {
          return fieldB < fieldA ? -1 : 1;
        }
      });
    }
  };

  const handleCrash = (error, info) => {
    console.log("error", error, info);
  };

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render
    return (
      <ErrorMessageModal
        error={error.message}
        handleClose={() => {
          dispatch(setClaimReSubmissionModal(null));
          setOpenOrderDetail(false);
        }}
      />
    );
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (filters[key] == "" && !Object.keys(filters[key]).length) {
          return true;
        }

        if (key === "from_date_1") {
          return Object.keys(filters[key]).length > 0
            ? new Date(obj.from_date_1) >= filters[key].startDate?._d &&
                new Date(obj.from_date_1) <= filters[key].endDate?._d
            : obj.from_date_1;
        }
        if (key === "paidAmount" || key === "adjustedAmount") {
          return obj[key] && obj[key].toString().includes(filters[key]);
        }
        if (key === "pat_dob") {
          return obj[key] && formatDateMDY(obj[key]).includes(filters[key]);
        }
        if (key === "notDraft") {
          return obj.status !== CLAIM_SUBMIT_STATUS.draft;
        }
        if (key === "Blank" && filter[key].length > 0) {
          return filter[key].some((f) => !obj[EMP_CLAIM_PERSONALIZE.find((t) => t.title === f)?.itemKey]);
        }
        if (key === "status_code") {
          return filter[key] && obj[key] === 2;
        }
        if (key === "reSubmissionDate") {
          return filter[key] && obj[key];
        }
        if (key === "crossover_carrier") {
          return filter[key] && obj[key];
        }
        if (key === "assignToMe") {
          return;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredEmployeeClaims);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(employeeClaims);

  useEffect(() => {
    dispatch(getStatsSummaryClaimAsync());
  }, []);

  const getCompanystats = async () => {
    const stats = clients[0].stats;
    const ttlSubmitted =
      stats.submitted + stats.approved + stats.paid + stats.partialProcessed + stats.rejectted + stats.denied;
    const ttlSubmittedAmt =
      stats.submittedAmount +
      stats.approvedAmount +
      stats.paidAmount +
      stats.partialProcessedAmount +
      stats.rejecttedAmount +
      stats.deniedAmount;
    dispatch(setStatsObj({ ...stats, submitted: ttlSubmitted, submittedAmount: ttlSubmittedAmt }));
  };

  const fetchFromApi = useCallback(
    debounce((timeFilter, pageNo, filter, val, users) => {
      dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, val, users));
    }, 1000),
    []
  );

  useEffect(() => {
    if (filteredEmployeeClaims.length > 0)
      dispatch(
        setFilteredEmployeeClaims(
          sortUsers(filterDates(nestedFilter(filteredEmployeeClaims, filter), timeFilter), sortBy)
        )
      );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    fetchFromApi(timeFilter, pageNo, filter, true, appContext.users);
    if (employeeClaims.length > 0) {
      setCurrentPage(1);
      dispatch(setPageNo(1));
    }
  }, [filter, timeFilter]);

  useEffect(() => {
    setPersonalize(draggablePersonalizationLocalStorage.get(loginUser, "employeeClaim", EMP_CLAIM_PERSONALIZE));
  }, [setting]);

  if (filteredEmployeeClaims.length > 0) {
    pageNumbers = getPageNumbers(filteredEmployeeClaims);
  }

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredEmployeeClaims.length;
    if (direction === "next" && page === pageNumbers && totalRecord > 0 && currentRecord < totalRecord) {
      dispatch(setPageNo(pageNo + 1));
    }
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "employeeClaim");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(loginUser.phone_number, personalisationData);
  };

  // const getDuplicateData = async () => {
  //   const emps = filteredEmployeeClaims.filter(
  //     (f, i, arr) =>
  //       arr.findIndex(
  //         (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
  //       ) !== i
  //   );
  //   const duplicateData = filteredEmployeeClaims.filter(
  //     (f, i) =>
  //       emps.findIndex(
  //         (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
  //       ) !== -1
  //   );

  //   await downloadDataAsCSV(duplicateData, "SevantDuplicateEmployees.csv");
  // };

  const handleImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      dispatch(setMessage("Please select Claim date", MESSAGE_MODES.error));
      return;
    }
    if (!claimStatus) {
      dispatch(setMessage("Please Select Claim Status", MESSAGE_MODES.error));
      return;
    }

    const subAgent = await api.getSubAgentById(subAgentID || "886a8f16-7d65-4a18-8f8e-51c14e71b8ed");
    const location = await api.getLocationById(subAgent.locationID);
    const client = await api.getClientById(subAgent.clientID);
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];
    let fileData = [];
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };
      if (!emp.pat_name_f && !emp.pat_dob) continue;
      emp.clientID = client.id;
      emp.clientName = client.name;
      emp.locationID = location.id;
      emp.locationName = location.name;
      emp.subAgentID = subAgent.id;
      emp.subAgentName = subAgent.name;
      emp.payerid = emp.payerid.padStart(5, 0);
      emp.pat_phone = phoneFormatter(emp.pat_phone || "");
      emp.bill_phone = phoneFormatter(emp.bill_phone || "");
      emp.ins_phone = phoneFormatter(emp.ins_phone || "");
      emp.other_payer_phone = phoneFormatter(emp.other_payer_phone || "");
      emp.pat_dob = parseClaimDate(emp.pat_dob || "");
      emp.ins_dob = parseClaimDate(emp.ins_dob || "");
      emp.other_ins_dob = parseClaimDate(emp.other_ins_dob || "");
      emp.createdAt = moment(`${orderMonth} 20:00:00`).toISOString();
      emp.units_1 = parseFloat(emp.units_1);
      emp.status = claimStatus;
      emp.ins_number = RemoveSpaceAndChar(emp.ins_number);
      emp.orderId = emp.remote_claimid.slice(7);
      emp.pat_name_f = getValidDep(emp.pat_name_f || "");
      emp.pat_name_l = getValidDep(emp.pat_name_l || "");
      emp.pat_name_m = getValidDep(emp.pat_name_m || "");
      emp.ins_name_f = getValidDep(emp.ins_name_f || "");
      emp.ins_name_l = getValidDep(emp.ins_name_l || "");
      emp.ins_name_m = getValidDep(emp.ins_name_m || "");

      if (claimStatus !== "submitted") {
        const findClaim = employeeClaims.find(
          (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
        );
        if (findClaim) {
          await graphqlApi.updateEmployeeClaimStatusGQL(findClaim, claimStatus);
          updateArr.push(emp);
        }
        continue;
      }
      let proc_array = [];
      const procArr = Object.keys(emp).filter((f) => f.includes("proc_code"));
      procArr.map((key, index) => {
        const i = index + 1;
        const splitDiagRef = emp[`diag_ref_${i}`].split(",");
        const obj = {
          proc_code: emp[`proc_code_${i}`],
          from_date: parseClaimDate(emp[`from_date_${i}`]),
          diag_ref_1: splitDiagRef[0],
          diag_ref_2: splitDiagRef[1],
          diag_ref_3: splitDiagRef[2],
          diag_ref_4: splitDiagRef[3],
          charge: emp[`charge_${i}`],
          units: emp[`units_${i}`],
          place_of_service: emp[`place_of_service_${i}`],
          mod1: emp[`mod1_${i}`],
          mod2: emp[`mod2_${i}`],
          mod3: emp[`mod3_${i}`],
          mod4: emp[`mod4_${i}`],
          [`remote_chgid_${i}`]: emp[`remote_chgid_${i}`],
        };
        proc_array.push(obj);
      });
      emp.proc_array = proc_array;
      const {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      } = emp;
      const renderProviderID = providers.find(
        (f) => f.lastName.trim().toLowerCase() === prov_name_l.trim().toLowerCase()
      );
      const referingProviderID = providers.find(
        (f) => f.lastName.trim().toLowerCase() === ref_name_l.trim().toLowerCase()
      );
      const orderingProviderID = providers.find(
        (f) => f.lastName.trim().toLowerCase() === ord_prov_name_l.trim().toLowerCase()
      );
      emp.provider = {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_id: renderProviderID?.id || "",
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_id: orderingProviderID?.id || "",
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_id: referingProviderID?.id || "",
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      };
      emp.employeeID = employees.find((f) => f.schrID === emp.pcn.trim())?.id ?? "";
      emp.updatedByName = loginUser.name;
      emp.updatedBy = loginUser.sub;

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        continue;
      }

      try {
        const res = await graphqlApi.createClaimGQL(emp);
        if (res.data) {
          fileData.push(res.data);
        }
        if (res) arr.push(emp);
      } catch (err) {
        console.log("Error:-", err);
      }
    }

    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(fileData, fileName);
    setLoading(false);
    dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
    if (updateArr.length > 0)
      dispatch(setMessage(`${updateArr.length} Claim(s) Updated Successfully!`, MESSAGE_MODES.success));
    if (arr.length > 0) dispatch(setMessage(`${arr.length} Claim(s) Imported Successfully!`, MESSAGE_MODES.success));
  };

  const searchRecord = (firstName, lastName, zip, ins_number, dob) => {
    return employees.find((item) => {
      if (firstName && lastName && ins_number && dob) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.insuranceCompany === ins_number &&
          item.dob === dob
        );
      } else if (firstName && lastName && dob) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.dob === dob
        );
      } else if (firstName && lastName && ins_number) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.insuranceCompany === ins_number
        );
      } else if (firstName && lastName && zip) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.zip === zip
        );
      } else if (firstName && lastName) {
        return isMatchString(item.firstName, item.lastName, firstName, lastName);
      }

      // Add more conditions if needed...

      return false; // No match found
    });
  };

  const handleImportClaims = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      dispatch(setMessage("Please select Claim date", MESSAGE_MODES.error));
      return;
    }
    if (!claimStatus) {
      dispatch(setMessage("Please Select Claim Status", MESSAGE_MODES.error));
      return;
    }

    const subAgent = await api.getSubAgentById(subAgentID || "886a8f16-7d65-4a18-8f8e-51c14e71b8ed");
    const location = await api.getLocationById(subAgent.locationID);
    const client = await api.getClientById(subAgent.clientID);
    setOpenImportModal(false);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];
    let fileData = [];

    let newClaims = [];
    let emps = [];
    for (let i = 0; i < ttlLength; i++) {
      const { data } = empData[i];
      const claim = trimObject(data);
      claim.clientID = client.id;
      claim.clientName = client.name;
      claim.locationID = location.id;
      claim.locationName = location.name;
      claim.subAgentID = subAgent.id;
      claim.subAgentName = subAgent.name;
      claim.pat_phone = phoneFormatter(claim.phone || "");
      claim.ins_phone = phoneFormatter(claim.phone || "");
      claim.pat_dob = parseClaimDate(claim.dob || "");
      claim.ins_dob = parseClaimDate(claim.dob || "");
      claim.pat_name_f = getValidDep(claim.firstName || "");
      claim.pat_name_l = getValidDep(claim.lastName || "");
      claim.pat_name_m = getValidDep(claim.middleName || "");
      claim.ins_name_f = getValidDep(claim.firstName || "");
      claim.ins_name_l = getValidDep(claim.lastName || "");
      claim.ins_name_m = getValidDep(claim.middleName || "");
      claim.dos = parseClaimDate(claim.dos);
      const findVal = INSURANCE_PROVIDER.find(
        (f) => f.label.toLowerCase().replace(/\s/g, "") === claim.ins_name.toLowerCase().replace(/\s/g, "")
      );
      claim.payer_name = findVal?.label || "";
      claim.payerid = findVal?.value || "";
      claim.updatedByName = loginUser.name;
      claim.updatedBy = loginUser.sub;

      const existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip, claim.ins_number, claim.dob);
      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip, null, claim.dob);
      }

      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip, claim.ins_number, null);
      }

      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip);
      }
      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName);
      }

      if (existEmp) {
        claim.employeeID = existEmp.id;
        emps.push({ employee: existEmp, claim });
      } else {
        newClaims.push(claim);
      }
    }
  };

  const handleRejcetedImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      dispatch(setMessage("Please select Claim date", MESSAGE_MODES.error));
      return;
    }
    if (!claimStatus) {
      dispatch(setMessage("Please Select Claim Status", MESSAGE_MODES.error));
      return;
    }
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        const logStatus = emp.messages.includes("ERA DENIAL") ? "denied" : "rejected";
        // await graphqlApi.updateEmployeeClaimStatusGQL(findClaim, logStatus,emp.messages);
        updateArr.push({
          ...findClaim,
          message: emp.messages,
          status: logStatus,
          local_status_time: emp.local_status_time,
        });
      }
    }

    // const findClaim = employeeClaims.map((f)=>{
    //   return {...f , message: 'Submit for Bill', status: 'submitted', local_status_time: f.createdAt }
    // });

    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(updateArr, fileName);

    setLoading(false);
    dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
    if (updateArr.length > 0)
      dispatch(setMessage(`${updateArr.length} Claim(s) Updated Successfully!`, MESSAGE_MODES.success));
    if (arr.length > 0) dispatch(setMessage(`${arr.length} Claim(s) Imported Successfully!`, MESSAGE_MODES.success));
  };

  const handleDownloadExportCsv = async () => {
    const claimList = filteredEmployeeClaims.map((claim) => {
      return formatEmployeeClaim(claim, AltaSetting);
    });
    await downloadDataAsCSV(claimList, "claim_listing.csv");
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredEmployeeClaims.map((t) => t.id) : []);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    const empsFilter = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && f.status === CLAIM_SUBMIT_STATUS.draft
    );
    let ttl = empsFilter.length;

    // if (ttl === 0 && userToDelete.status !== CLAIM_SUBMIT_STATUS.draft && loginUser?.phone_number !== usersNo.ibrahim) {
    //   dispatch(setMessage("Only draft claims are able to be delete", MESSAGE_MODES.error));
    //   setCheckboxes([]);
    //   return;
    // }
    try {
      dispatch(deleteEmployeeClaim(userToDelete, empsFilter));
      setUserToDelete(null);
      setCheckboxes([]);
      // getEmployeeClaims(timeFilter, pageNo, filter);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleBulkSubmitClaims = async (isConfirm) => {
    setOpenClaimSubmitConfirmation(false);
    if (!isConfirm) {
      return;
    }
    const claimDraft = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && f.status === CLAIM_SUBMIT_STATUS.draft
    );
    const checkStatus = {
      rejected: "rejected",
      denied: "denied",
    };
    const claimRecjectesAndDenied = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && checkStatus[f.status]
    );
    if (claimDraft.length > 0) {
      await dispatch(
        updateBulkSubmitEmployeeClaimAsync({
          claimSubmissions: claimDraft,
          claimStatus: "submitted",
          claimtype: "claim",
        })
      );
    }
    if (claimRecjectesAndDenied.length > 0) {
      await dispatch(
        updateBulkSubmitEmployeeClaimAsync({
          claimSubmissions: claimRecjectesAndDenied,
          claimStatus: "submitted",
          claimtype: "reclaim",
        })
      );
    }
    setCheckboxes([]);
    setAllSelected(false);
  };

  const tdFormat = (item, row) => {
    if (item === "submissionDate") return formatDateMDY(row.submissionDate);
    if (item === "reSubmissionDate") return formatDateMDY(row.reSubmissionDate);
    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    if (item === "reSubmissionDate") return formatDateMDY(row.reSubmissionDate);
    return row[item];
  };

  const tdStatus = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={`${row.message || ""}${row.crossover_carrier ? `\n ${row.crossover_carrier}` : ""}`}
        onClick={() => handleCellClick(item.itemKey, row)}
      >
        <div
          className="statusPill custom-ellipsis d-block"
          style={{
            backgroundColor: item.colorObj[row[item.itemKey]],
          }}
        >
          {CapsFirstLetter(row.status)}
        </div>
      </td>
    );
  };

  const renderTd = (item, row) => {
    if (item.itemKey === "status") return tdStatus(item, row);
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCreateClaim = async () => {
    if (checkboxes.length === 1) {
      let res = filteredEmployeeClaims.filter((f) => checkboxes.indexOf(f.id) !== -1);
      dispatch(setClaimReSubmissionModal(res.map((m) => ({ employeeId: m.employeeID }))));
    } else {
      dispatch(setClaimReSubmissionModal([]));
    }
  };

  const handleOpenEraFile = async (user) => {
    const eraid = user.eraIds.split(",");
    let path = await api.getPatientERA({ ...user, eraid: eraid[0] });
    if (!path) {
      window.open(`https://medflow-images.s3.eu-west-1.amazonaws.com/era_view.pdf`);
      return;
    }
    try {
      const res = await Storage.get(path, {
        bucket: CONFIG.eligibilityBucket,
      });

      window.open(`${res}`);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const handleAssignClaim = (userToAssign, message) => {
    let claimsToAssign = filteredEmployeeClaims.filter((f) => checkboxes.indexOf(f.id) !== -1);
    setShowAssignModal(false);

    try {
      const updatedClaimsArr = claimsToAssign.map((claim) => {
        return {
          ...claim,
          assign_details: {
            claimId: claim.id,
            message,
            userId: userToAssign.id,
            name: `${userToAssign.firstName} ${userToAssign.lastName}`,
          },
          assignUserNumber: userToAssign.phone_number,
        };
      });

      dispatch(assignEmployeeClaimAsync(updatedClaimsArr));
      setCheckboxes([]);
    } catch (error) {
      console.log(error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        dispatch(setClaimReSubmissionModal([row]));
        if (
          (row.status === CLAIM_SUBMIT_STATUS.denied || row.status === CLAIM_SUBMIT_STATUS.rejected) &&
          !row.acknowledgeBy
        ) {
          dispatch(acknowledgeClaim(row, loginUser));
        }
        break;

      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setClaimNote(row);
        break;

      case "status":
        row.status === ERA_VIEW_STATUS[row.status] && setOpenEraModal(row);
        break;

      case "eraIds":
        row.eraIds && handleOpenEraFile(row);
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {t("employeeClaim")} ({totalRecord ? formatNumber(totalRecord) : employeeClaims.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (filteredEmployeeClaims && filteredEmployeeClaims.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                      // const fileName = `claim/test_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
                      // await api.saveFileOnBucket(
                      //   employeeClaims.filter((f) => f.status === "rejected" || f.status === "denied"),
                      //   fileName
                      // );
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <FilterSelect setTimeFilter={setTimeFilter} />
                  {(permission[EMPLOYEE_CLAIM]?.delete || !CONFIG.isLabType) && (
                    <Icon
                      handleClick={() => setOpenDeleteConfirmation(true)}
                      label="Delete"
                      title="Delete"
                      disabled={checkboxes.length === 0}
                      iconType={"binIcon"}
                    />
                  )}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <Icon
                    handleClick={handleDownloadExportCsv}
                    title={"Export Claim"}
                    label={"Export"}
                    iconType={"downloadIcon"}
                  />

                  {(permission[EMPLOYEE_CLAIM]?.write || !CONFIG.isLabType) && (
                    <Icon
                      title={t("createClaim")}
                      label={t("createClaim")}
                      iconType={"acknowledgedPositiveIcon"}
                      handleClick={() => {
                        handleCreateClaim();
                      }}
                    />
                  )}

                  {/* <Icon
                    handleClick={() => {
                      setOpenImportModal(true);
                    }}
                    title={t("loadOrderData")}
                    label={"Import"}
                    iconType={"importIcon"}
                  /> */}

                  {loginUser.phone_number === usersNo.ibrahim && (
                    <Icon
                      title={t("updateClaimStatus")}
                      label={t("updateClaimStatus")}
                      iconType={"acknowledgedPositiveIcon"}
                      disabled={checkboxes.length === 0}
                      handleClick={() => setChangeClaimStatusModal(true)}
                    />
                  )}

                  <Icon
                    title={t("submitClaim")}
                    label={t("submitClaim")}
                    iconType={"acknowledgedPositiveIcon"}
                    disabled={
                      filteredEmployeeClaims.filter((f) => checkboxes.includes(f.id) && STATUS_KEYS_RE_SUBMIT[f.status])
                        .length === 0
                    }
                    handleClick={() => setOpenClaimSubmitConfirmation(true)}
                  />

                  {permission[USERS]?.access && (
                    <Icon
                      title={t("assignTo")}
                      label={t("assignTo")}
                      iconType={"addUserIcon"}
                      disabled={checkboxes.length === 0}
                      handleClick={() => setShowAssignModal(true)}
                    />
                  )}
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    employeeClaim={true}
                  />
                )}

                <div className="dashboard-wrapper">
                  <div className="d-flex w-100">
                    <SingleStatsCard
                      title={"Draft"}
                      totalCounts={statsObj.draft}
                      amount={currencyformatter.format(statsObj.draftAmount)}
                      filterKey="status"
                      value="draft"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Submitted"}
                      totalCounts={statsObj.submitted}
                      amount={currencyformatter.format(statsObj.submittedAmount)}
                      filterKey="status"
                      value="submitted"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Rejected"}
                      totalCounts={statsObj.rejectted}
                      amount={currencyformatter.format(statsObj.rejecttedAmount)}
                      filterKey="status"
                      value="rejected"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Approved"}
                      totalCounts={statsObj.approved}
                      amount={currencyformatter.format(statsObj.approvedAmount)}
                      filterKey="status"
                      value="approved"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Partially Processed"}
                      totalCounts={statsObj.partialProcessed}
                      amount={currencyformatter.format(statsObj.partialProcessedAmount)}
                      filterKey="status"
                      value="partial"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Paid"}
                      totalCounts={statsObj.paid}
                      amount={currencyformatter.format(statsObj.paidAmount)}
                      filterKey="status"
                      value="paid"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Denied"}
                      totalCounts={statsObj.denied}
                      amount={currencyformatter.format(statsObj.deniedAmount)}
                      filterKey="status"
                      value="denied"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Cancelled"}
                      totalCounts={statsObj.cancelled}
                      amount={currencyformatter.format(statsObj.cancelledAmount || 0)}
                      filterKey="status"
                      value="cancel"
                      handelClick={(obj) => setFilter({ ...obj })}
                      color="black"
                    />
                  </div>
                </div>
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  totalRecord={totalRecord}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  individualRowCssClass={(row) =>
                    row.status &&
                    !row.acknowledgeBy &&
                    (row.status === CLAIM_SUBMIT_STATUS.rejected || row.status === CLAIM_SUBMIT_STATUS.denied) &&
                    "isClaimFalse"
                  }
                  columns={personalize}
                  rows={usersToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"employeeClaim"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={155}
                  tools={quickTools}
                  dropDownOptions={
                    permission[EMPLOYEE_CLAIM]?.delete || !CONFIG.isLabType
                      ? ClaimDropDownOptions
                      : ClaimDropDownOptions.filter((f) => f.value !== "delete")
                  }
                  handleDropDownClick={(type, row) => {
                    if (type === "logs") {
                      setOpenOrderDetail(true);
                      setSelectedOrder(row);
                    }
                    if (type === "delete") {
                      setOpenDeleteConfirmation(true);
                      setUserToDelete(row);
                    }
                  }}
                  selectedRows={checkboxes}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {claimReSubmissionModal && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CreateClaimResubmissionModal
            // orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1)}
            user={claimReSubmissionModal}
            handleClose={(val) => {
              dispatch(setClaimReSubmissionModal(null));
              setCheckboxes([]);
              if (val) {
                dispatch(fetchCompanySetting());
              }
            }}
            fileDir="claim"
          />
        </ErrorBoundary>
      )}

      {changeClaimStatusModal && (
        <ChangeClaimStatusModal
          selectedClaimIds={checkboxes || []}
          cssClass={"seperateModal"}
          handleClose={(val) => {
            if (val) {
              dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
            }
            setChangeClaimStatusModal(false);
            setCheckboxes([]);
          }}
        />
      )}
      {openEraModal && <EraModal user={openEraModal} handleClose={() => setOpenEraModal(null)} />}
      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImportClaims}
          claim
          title="Import Claim Data"
        />
      )}

      {openOrderDetail && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <OrderLogsModal
            order={selectedOrder}
            show={openOrderDetail}
            user={loginUser}
            handleClose={() => {
              setOpenOrderDetail(false);
              setSelectedOrder("");
            }}
            claim
          />
        </ErrorBoundary>
      )}

      {claimNote && (
        <NotesModal
          handleClose={(obj, level) => {
            setClaimNote(null);
            if (obj) {
              obj.note
                ? dispatch(setMessage("add Notes Successfully", MESSAGE_MODES.success))
                : dispatch(setMessage("Remove Notes Successfully", MESSAGE_MODES.success));
              dispatch(updateNotes(obj));
              if (level === "patient") dispatch(fetchAllEmployees());
            }
          }}
          data={claimNote}
          claim={true}
          user={loginUser}
        />
      )}

      {showAssignModal && (
        <AssignClaimModal handleClose={() => setShowAssignModal(false)} handleSave={handleAssignClaim} />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Claim"
          message="Are you sure, you want to remove claim?"
          handleConfirm={handleConfirm}
        />
      )}

      {openClaimSubmitConfirmation && (
        <ConfirmationModal
          show={openClaimSubmitConfirmation}
          title="Submit Claim"
          message="Are you sure, you want to submit selected claim?"
          handleConfirm={handleBulkSubmitClaims}
        />
      )}
    </div>
  );
};

export default EmployeeClaimAPI;
