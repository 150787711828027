import api from "api";
import { Storage } from "aws-amplify";
import NewClaimSubmissionModal from "components/Claim/Modal/NewClaimSubmissionModal";
import EmployeeModal from "components/Employee/EmployeeModal";
import ExportToExcel from "components/ExportToExcel";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ClearClaimModal from "components/Modal/ClearClaimModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import NotesModal from "components/Modal/NotesModal";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import OrderModal from "components/Modal/Order/OrderModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import ReceivedAmountDetailsModal from "components/Modal/ReceivedAmountDetailsModal";
import MFPagination from "components/Pagination/MFPagination";
import Stats from "components/Stats";
import Status from "components/Status";
import MainTable from "components/Table/MainTable";
import { Billing_PERSONALIZE } from "constants/personalization";
import FileSaver from "file-saver";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { jsonToCSV } from "react-papaparse";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/features/general/generalAction";
import { personalizationLocalStorage } from "utils";
import "../../src/tableStyling.css";
import closeIcon from "../assets/img/close.svg";
import Filter from "../components/Filter";
import {
  BILLING_ORDERS_DROPDOWN_OPTIONS,
  CONFIG,
  INSURANCE_PROVIDER,
  MESSAGE_MODES,
  PAGE_LIMIT,
  TABLE_QUICK_TOOLS,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
  USER_TYPE_SUBAGENT,
  USER_TYPE_USER,
  claimActions,
  currencyformatter,
} from "../constant";
import { AppContext } from "../context/app-context";
import { t } from "../stringConstants";
import {
  currentDate,
  downloadDataAsCSV,
  formatEmployeesData,
  formatNumber,
  formatOrder,
  formatOrderedDateAws,
  formatPhoneNumber,
  getPhoneNumber,
  getValidDep,
  getValidGender,
  isValidDobField,
  isValidIDNumber,
  sortingFilterInLC,
} from "../utils";

const Billing = (props) => {
  const [personalize, setPersonalize] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const location = useLocation();

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [newItem, setNewItem] = useState({ scheduleName: "" });
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [orderModal, setOrderModal] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [noteOrder, setNoteOrder] = useState(false);
  const [openClearClaimModal, setOpenClearClaimModal] = useState(null);
  const [openAmountDetail, setOpenAmountDetail] = useState("");
  const [claimReSubmissionModal, setClaimReSubmissionModal] = useState(false);
  const dispatch = useDispatch();
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, !appContext.isReadOnly() && TABLE_QUICK_TOOLS.notes];

  const searchTerms = [
    "Order Date",
    "Claim Submit Date",
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Client",
    "Facility",
    "Sub Agent",
    "LTCS ID",
    "Claim ID",
    "Insurance Provider",
    "ID Number",
    "Order Note",
    "Order Status",
    "Order Qty",
    "Insurance Status",
    "Claim Status",
    "Amount Received Date",
    "Employee Status",
    "Ordered By",
    "Claim Paid",
  ];
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [employee, setEmployee] = useState({});

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      const nextTerm = location.state.filterTerm;
      const nextTermName = location.state.filterName;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
      if (nextTerm && nextTermName) {
        setFilter({
          ...filter,
          [nextTerm]: nextTermName,
          [filterTerm]: filterTermName,
        });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  const sortData = (schs, sortParam) => {
    let arr = [];

    if (!sortParam) {
      return [...schs].sort((a, b) =>
        `${b["firstName"]}${b["lastName"]}` < `${a["firstName"]}${a["lastName"]}`
          ? 1
          : `${a["firstName"]}${a["lastName"]}` < `${b["firstName"]}${b["lastName"]}`
          ? -1
          : 0
      );
    }

    if (sortDescending) {
      arr = [...schs].sort((a, b) => {
        let fieldA = a[sortParam];
        let fieldB = b[sortParam];
        let nameA = `${a["firstName"]}${a["lastName"]}`;
        let nameB = `${b["firstName"]}${b["lastName"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return fieldA < fieldB ? -1 : 1;
        }
      });
    } else {
      arr = [...schs].sort((a, b) => {
        let fieldA = a[sortParam];
        let fieldB = b[sortParam];
        let nameA = `${a["firstName"]}${a["lastName"]}`;
        let nameB = `${b["firstName"]}${b["lastName"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        } else {
          return fieldB < fieldA ? -1 : 1;
        }
      });
    }
    return arr;
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.orders && sortLS.orders.sortBy) {
      setSortBy(sortLS.orders.sortBy);
      setSortDescending(sortLS.orders.sortDescending);
    }
    // if (sortLS.orders && sortLS.orders.filter && !location.state) {
    //   setFilter(sortLS.orders.filter);
    // }
    setPersonalize(personalizationLocalStorage.get(appContext.user, "billing", Billing_PERSONALIZE));
  }, [appContext?.company]);

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalizationLocalStorage.saveAs(data, "billing");
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    if (!appContext.user.isUser()) {
      await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
    }
  };

  const getOrders = async () => {
    let userType = appContext.user.isUser() ? USER_TYPE_USER : USER_TYPE_ADMIN;
    let id = null;
    if (appContext.user.isUser()) {
      id = appContext.employeeRecord?.id;
      if (!id) {
        const empRecord = await api.getLoggedInUserEmployeeID(null, appContext.user.preferred_username);
        if (empRecord) {
          id = empRecord.id;
        }
      }
    } else if (appContext.user.isClient()) {
      userType = USER_TYPE_CLIENT;
    } else if (appContext.user.isSubAgent()) {
      userType = USER_TYPE_SUBAGENT;
    }

    const data = await api.getOrdersBilling(userType, id);
    const formatttedOrders = data.map((o) => formatOrder(o, appContext.employees));
    setOrders(formatttedOrders);
    setFilteredOrders(sortData(nestedFilter(formatttedOrders, filter), sortBy));
  };

  useEffect(async () => {
    getOrders();
  }, [appContext.employees]);

  const getTotalOrders = (list) =>
    formatNumber(
      list.reduce((val, obj) => {
        return (obj.id ? 1 : 0) + val;
      }, 0)
    );

  const filterDays = (val) => {
    if (!val) return "-";
    const arr = val.split(",");
    return arr;
  };

  const createMember = async (user) => {
    try {
      setLoading(true);

      await api.updateEmployee(user, appContext.user);
      if (!user.onAlert) dispatch(setMessage(t("clientUpdatedSuccessfully"), MESSAGE_MODES.success));

      await appContext.resetEmployees();

      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));

      setLoading(false);
    }
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleResetPassword = (obj) => {
    console.log("Password Obj", obj);
  };

  const onHandleSubmitClaim = async () => {
    console.log("filteredOrders", filteredOrders);

    if (filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1 && f.InsuranceStatus === "Denied").length === 0) {
      dispatch(setMessage("Please select the Denied Orders", MESSAGE_MODES.error));
      setCheckboxes([]);
      return;
    }

    setClaimReSubmissionModal(true);
  };

  const handleDownload = async (orders) => {
    if (orders.length === 0) {
      dispatch(setMessage("Insurance status Accepted", MESSAGE_MODES.error));
      return;
    }

    await downloadDataAsCSV(
      orders
        .filter((f) => f.orderDate === "20221001" && !f.claimStatus)
        .map((d) => {
          let phNo = formatPhoneNumber(d.phoneNumber);
          phNo = phNo.replace("+1", "");
          const emp = appContext.employees.find((e) => e.id === d.employeeID);

          return {
            accept_assign: "Y",
            amount_paid: 0,
            auto_accident: "",
            auto_accident_state: "",
            bill_addr_1: "2268 LINCOLN AVE",
            bill_addr_2: "",
            bill_city: "ALTADENA",
            bill_id: "291U00000X",
            bill_name: "ALTA DIAGNOSTIC LABORATORIES, INC",
            bill_npi: "1063180354",
            bill_phone: "8185730434",
            bill_state: "CA",
            bill_taxid: "871701212",
            bill_taxid_type: "E",
            bill_taxonomy: "",
            bill_zip: "910015432",
            clia_number: "",
            diag_1: "Z20.822",
            diag_2: "",
            diag_3: "",
            diag_4: "",
            diag_5: "",
            diag_6: "",
            diag_7: "",
            diag_8: "",
            employment_related: "N",
            facility_addr_1: "2268 LINCOLN AVE",
            facility_addr_2: "",
            facility_city: "ALTADENA",
            facility_id: "",
            facility_name: "ALTA DIAGNOSTIC LABORATORIES, INC",
            facility_npi: "1063180354",
            facility_state: "CA",
            facility_zip: "",
            ins_addr_1: d.street,
            ins_addr_2: d.street2,
            ins_city: d.city,
            ins_dob: moment(d.dob).format("MM/DD/YYYY"),
            ins_employer: "",
            ins_group: "",
            ins_name_f: d.firstName,
            ins_name_l: d.lastName,
            ins_name_m: "",
            ins_number: emp?.medicareNo || d.medicalNo,
            ins_phone: phNo,
            ins_plan: "",
            ins_sex: d.gender,
            ins_state: d.state,
            ins_zip: d.zip,
            narrative: "",
            ord_prov_name_f: "",
            ord_prov_name_l: "",
            ord_prov_name_m: "",
            ord_prov_npi: "",
            other_ins_dob: "",
            other_ins_group: "",
            other_ins_medicare_code: "",
            other_ins_name_f: "",
            other_ins_name_l: "",
            other_ins_name_m: "",
            other_ins_number: "",
            other_ins_payment_date: "",
            other_ins_plan: "",
            other_ins_sex: "",
            other_pat_rel: "",
            other_payer_addr_1: "",
            other_payer_addr_2: "",
            other_payer_city: "",
            other_payer_name: "",
            other_payer_phone: "",
            other_payer_state: "",
            other_payer_typecode: "",
            other_payer_zip: "",
            other_payerid: "",
            pat_addr_1: d.street,
            pat_addr_2: d.street2,
            pat_city: d.city,
            pat_country: "",
            pat_dob: moment(d.dob).format("MM/DD/YYYY"),
            pat_name_f: d.firstName,
            pat_name_l: d.lastName,
            pat_name_m: "",
            pat_phone: formatPhoneNumber(d.phoneNumber),
            pat_rel: "18",
            pat_sex: d.gender,
            pat_ssn: "",
            pat_state: d.state,
            pat_zip: d.zip,
            pay_addr_1: "",
            pay_addr_2: "",
            pay_city: "",
            pay_state: "",
            pay_zip: "",
            payer_addr_1: "",
            payer_addr_2: "",
            payer_city: "",
            payer_name: "CA Medicare South",
            payer_order: "Primary",
            payer_state: "",
            payer_zip: "",
            payerid: "01192",
            pcn: d.pcn,
            prior_auth: "",
            prov_id: "",
            prov_name_f: "",
            prov_name_l: "",
            prov_name_m: "",
            prov_npi: "",
            prov_taxid: "",
            prov_taxid_type: "",
            prov_taxonomy: "",
            ref_name_f: "",
            ref_name_l: "",
            ref_name_m: "",
            ref_npi: "",
            remote_batchid: "",
            remote_claimid: d.orderId,
            remote_fileid: "",
            total_charge: "200",
            charge_1: "200",
            diag_ref_1: "A",
            from_date_1: "",
            mod1_1: "",
            mod2_1: "",
            mod3_1: "",
            mod4_1: "",
            narrative_1: "",
            place_of_service_1: "81",
            proc_code_1: "K1034",
            remote_chgid_1: "",
            thru_date_1: "",
            units_1: "8",
            diag_ref_2: "",
            from_date_2: "",
            mod1_2: "",
            mod2_2: "",
            mod3_2: "",
            mod4_2: "",
            narrative_2: "",
            place_of_service_2: "",
            proc_code_2: "",
            remote_chgid_2: "",
            thru_date_2: "",
            units_2: "",
            primaryInsurance: d.payerName,
            orderDate: d.orderDate,
          };
        }),
      "reSubmitClaim"
    );
  };
  const handleDownloadEmployeeClaim = async (orders) => {
    if (orders.length === 0) {
      dispatch(setMessage("Insurance status Accepted", MESSAGE_MODES.error));
      return;
    }
    await downloadDataAsCSV(
      orders.map((d) => {
        return {
          clientID: d.clientID,
          locationID: d.locationID,
          subAgentID: d.subAgentID,
          clientName: d.clientName,
          locationName: d.locationName,
          subAgentName: d.subAgentName,
          status: d.InsuranceStatus,
          message: d.InsuranceMessage,
          orderId: d.orderId,
          orderDate: d.orderDate,
          localOrderId: d.id,
          accept_assign: "Y",
          bill_addr_1: "2268 LINCOLN AVE",
          bill_addr_2: "",
          bill_city: "ALTADENA",
          bill_id: "291U00000X",
          bill_name: "ALTA DIAGNOSTIC LABORATORIES, INC",
          bill_npi: "1063180354",
          bill_phone: "8185730434",
          bill_state: "CA",
          bill_taxid: "871701212",
          bill_taxid_type: "E",
          bill_zip: "910015432",
          diag_1: "Z20.822",
          employment_related: "N",
          ins_addr_1: d.street,
          ins_addr_2: d.street2,
          ins_city: d.city,
          ins_dob: moment(d.dob).format("MM/DD/YYYY"),
          ins_name_f: d.firstName,
          ins_name_l: d.lastName,
          ins_number: d.medicalNo,
          ins_sex: d.gender,
          ins_state: d.state,
          ins_zip: d.zip,
          pat_addr_1: d.street,
          pat_addr_2: d.street2,
          pat_city: d.city,
          pat_country: "",
          pat_dob: moment(d.dob).format("MM/DD/YYYY"),
          pat_name_f: d.firstName,
          pat_name_l: d.lastName,
          pat_phone: formatPhoneNumber(d.phoneNumber),
          pat_rel: "18",
          pat_sex: d.gender,
          pat_ssn: "",
          pat_state: d.state,
          pat_zip: d.zip,
          payer_name: "CA Medicare South",
          payer_order: "Primary",
          payerid: "01192",
          pcn: d.pcn,
          total_charge: "200",
          charge_1: "200",
          diag_ref_1: "A",
          from_date_1: "10/20/2022",
          place_of_service_1: "81",
          proc_code_1: "K1034",
          remote_chgid_1: "",
          thru_date_1: "10/20/2022",
          units_1: "1",
          isPost: "Yes",
          requestedBy: appContext.user.sub,
          requestedByName: appContext.user.name,
        };
      }),
      "EmployeeClaimFile"
    );
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "createdAt") {
          return Object.keys(filters[key]).length > 0
            ? new Date(obj.createdAt) > filters[key].startDate?._d && new Date(obj.createdAt) < filters[key].endDate?._d
            : obj.createdAt;
        }
        if (key === "claimSubmitDate") {
          return Object.keys(filters[key]).length > 0
            ? new Date(obj.claimSubmitDate) >= filters[key].startDate?._d &&
                new Date(obj.claimSubmitDate) <= filters[key].endDate?._d
            : obj.claimSubmitDate;
        }
        if (key === "amountRecievedDate") {
          return Object.keys(filters[key]).length > 0
            ? new Date(obj.amountRecievedDate) > filters[key].startDate?._d &&
                new Date(obj.amountRecievedDate) < filters[key].endDate?._d
            : obj.amountRecievedDate;
        }
        if (key === "orderDate") {
          return Object.keys(filters[key]).length > 0
            ? new Date(formatOrderedDateAws(obj.orderDate)) > filters[key].startDate?._d &&
                new Date(formatOrderedDateAws(obj.orderDate)) < filters[key].endDate?._d
            : obj.orderDate;
        }
        if (key === "siteID") {
          return obj[key] && obj[key] === filters[key];
        }
        if (key === "id") {
          return obj[key] && obj[key] === filters[key];
        }
        if (key === "subAgentID") {
          return obj[key] && obj[key] === filters[key];
        }
        if (key === "clientID") {
          return obj[key] && obj[key] === filters[key];
        }
        if (key === "claimStatus") {
          return obj[key]?.toLowerCase() === filters[key]?.toLowerCase();
        }
        if (key === "orderStatus") {
          return obj[key]?.toLowerCase() === filters[key]?.toLowerCase();
        }
        if (key === "InsuranceStatus") {
          return obj[key]?.toLowerCase() === filters[key]?.toLowerCase();
        }
        if (
          (key === "paidClaim" && obj.InsuranceStatus?.toLowerCase() === "paid") ||
          (key === "paidClaim" &&
            filters[key] === "Approved" &&
            (obj.InsuranceStatus?.toLowerCase() === "paid" || obj.InsuranceStatus?.toLowerCase() === "approved"))
        ) {
          return true;
        }
        if (key === "employeeStatus") {
          return filter[key]?.toLowerCase() === obj.employeeStatus?.toLowerCase();
        }
        if (key === "payerId") {
          return filters[key].indexOf(obj.payerId) !== -1;
        }
        if (key === "month") {
          return moment(obj.createdAt).isSame(new Date(), "month");
        }
        if (key === "schrID") {
          return obj.pcn && obj.pcn.toLowerCase().includes(filters[key]);
        }
        if (key === "note") {
          if (filters[key] === "1" && obj.note?.message) return true;
          if (filters[key] === "0") return true;
          return false;
        }
        if (key === "totalRejectedAmount") {
          return obj.claimStatus?.toLowerCase() === "rejected" || obj.InsuranceStatus?.toLowerCase() === "denied";
        }
        if (key === "testQty") {
          return obj[key] == filters[key];
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const currentItems = (schs) => {
    const indexOfLast = currentPage * PAGE_LIMIT;
    const indexOfFirst = indexOfLast - PAGE_LIMIT;
    return schs.slice(indexOfFirst, indexOfLast);
  };

  let itemsToMap = currentItems(filteredOrders);

  const getPageNumbers = (items) => {
    const pageNumbers = items.length > PAGE_LIMIT ? Math.ceil(items.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.orders = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (filteredOrders.length > 0) {
      handleSaveFilter();
      setFilteredOrders(sortData(filteredOrders, sortBy));
    }
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setFilteredOrders(sortData(nestedFilter(orders, filter), sortBy));
    setCurrentPage(1);
  }, [filter, orders]);

  let pageNumbers = getPageNumbers(orders);
  if (filteredOrders.length > 0) {
    pageNumbers = getPageNumbers(filteredOrders);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleEdit = (item) => {
    const obj = { ...item, isNew: false };
    setNewItem(obj);
    setOpenCreator(true);
  };

  const handleConfirmDelete = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await api.deleteOrder(itemToDelete.id);
      if (isDeleted) {
        await getOrders();
        dispatch(setMessage("Order deleted successfully", MESSAGE_MODES.success));
      }
      setItemToDelete(null);
    } catch (error) {
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
    setLoading(false);
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredOrders.map((t) => t.id) : []);
  };

  const formatDOB = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[0].padStart(2, 0)}/${arr[1].padStart(2, 0)}/${arr[2]}`;
  };

  const parseDateOfBirth = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[2]}-${arr[0].padStart(2, 0)}-${arr[1].padStart(2, 0)}`;
  };

  const insuranceProviderFormatter = (insuranceCompany) => {
    if (!insuranceCompany) return "-";
    const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
    if (!item) return "-";
    return item?.label;
  };

  const handleImportEra = async (empData) => {
    setOpenImportModal(false);
    if (empData.length === 0) return;
    const arr = [];
    const arrdata = [];
    const ttlLength = empData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };
      emp.eft_date = moment(emp.eft_date, "MM/DD/YYYY").format("MM/DD/YYYY");

      emp.fdos = moment(emp.fdos, "MM/DD/YYYY").format("MM/DD/YYYY");

      emp.ldos = moment(emp.ldos, "MM/DD/YYYY").format("MM/DD/YYYY");

      if (!emp.eftnum || !emp.eftnum.trim()) {
        emp.validation = "missing eftnum information";
        arr.push(emp);
        continue;
      }
      if (!emp.eft_date) {
        emp.validation = "missing eft_date information";
        arr.push(emp);
        continue;
      }
      if (emp.eft_date && !isValidDobField(emp.eft_date, "MM/DD/YYYY")) {
        emp.validation = "Invalid eft_date";
        arr.push(emp);
        continue;
      }

      if (!emp.fdos) {
        emp.validation = "missing fdos information";
        arr.push(emp);
        continue;
      }

      if (emp.fdos && !isValidDobField(emp.fdos, "MM/DD/YYYY")) {
        emp.validation = "Invalid fdos Date";
        arr.push(emp);
        continue;
      }
      if (!emp.ldos) {
        emp.validation = "missing ldos information";
        arr.push(emp);
        continue;
      }

      if (emp.ldos && !isValidDobField(emp.ldos, "MM/DD/YYYY")) {
        emp.validation = "Invalid ldos Date";
        arr.push(emp);
        continue;
      }

      if (!emp.payer_name || !emp.payer_name.trim()) {
        emp.validation = "missing payer_name information";
        arr.push(emp);
        continue;
      }
      if (!emp.provider_name || !emp.provider_name.trim()) {
        emp.validation = "missing provider_name information";
        arr.push(emp);
        continue;
      }
      if (!emp.Provider_ID || !emp.Provider_ID.trim()) {
        emp.validation = "missing Provider_ID information";
        arr.push(emp);
        continue;
      }
      if (!emp.pcn || !emp.pcn.trim()) {
        emp.validation = "missing pcn information";
        arr.push(emp);
        continue;
      }
      emp.validation = "Success";
      arr.push(emp);
    }
    const fileName = `era/era_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    console.log("import Array", arr);
    await api.saveFileOnBucket(arr, fileName);
  };

  const handleImport = async (empData, locationID, orderMonth) => {
    if (empData.length === 0) return;
    if (!locationID) {
      dispatch(setMessage("Facility/Agent was not selected", MESSAGE_MODES.error));
      return;
    }
    if (!orderMonth) {
      dispatch(setMessage("Please select order month", MESSAGE_MODES.error));
      return;
    }

    const client = await api.getLocationById(locationID);
    const programName = [];
    const arr = [];
    const arrdata = [];
    const newEmpIds = [];
    const ttlLength = empData.length;
    const filterheader = ["firstName", "lastName", "dob", "medicareNo"].every((c) =>
      Object.keys(empData[0].data).includes(c)
    );
    if (!filterheader) {
      dispatch(setMessage("Headers not matched", MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data, countryCode: "" };
      if (!emp.firstName && !emp.lastName && !emp.dob && !emp.medicareNo) continue;

      emp.firstName = getValidDep(emp.firstName || "");
      emp.lastName = getValidDep(emp.lastName || "");
      emp.phoneNumber = getPhoneNumber(emp.phone || "");
      emp.companyID = locationID;
      emp.clientID = client.clientID;
      emp.testAvailable = client.testLimit;
      emp.testOrdered = 0;
      emp.medicalNo = emp.medicareNo?.trim().toUpperCase();
      emp.medicareNo = emp.medicalNo;
      emp.insuranceGroupId = emp.groupId;
      emp.autoShipment = (emp["Automatic Monthly"] || "No") === "Yes";
      emp.orderDate = orderMonth.replace(/\D/g, "");
      if (emp.address) {
        emp.street = emp.address;
      }
      if (emp.address2) {
        emp.street2 = emp.address2;
      }
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }

      emp.dob = moment(emp.dob, "MM/DD/YYYY").format("MM/DD/YYYY");

      if (!emp.firstName || emp.firstName === " ") {
        emp.validation = "missing information";
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.lastName || emp.lastName === " ") {
        emp.validation = "missing information";
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.dob) {
        emp.validation = "missing information";
        newEmpIds.push(emp);
        continue;
      }

      if (
        emp.dob &&
        (!isValidDobField(emp.dob, "MM/DD/YYYY") ||
          !moment(emp.dob, "MM/DD/YYYY").isBetween("01/01/1900", currentDate()))
      ) {
        emp.validation = "invalid DOB";
        newEmpIds.push(emp);
        continue;
      }

      const isRecordExists = await api.getEmployeeByID({
        ...emp,
        dob: moment(emp.dob, "MM/DD/YYYY").format("YYYY-MM-DD"),
      });

      if (isRecordExists) {
        emp.payerId = isRecordExists.insuranceCompany;
        emp.medicareNo = isRecordExists.medicalNo;
        emp.testAvailable = isRecordExists.testAvailable;
        emp.testOrdered = isRecordExists.testOrdered;
        const isInsurance = INSURANCE_PROVIDER.find((f) => f.payerid === isRecordExists.insuranceCompany);
        emp.medicareAgent = isInsurance.label;
        emp.validation = "Pass";
        newEmpIds.push({ ...emp, ...isRecordExists });
        continue;
      }

      if (!emp.medicareNo) {
        emp.validation = "Medicare ID is Required";
        newEmpIds.push(emp);
        continue;
      }

      if (!isValidIDNumber(emp.medicareNo)) {
        emp.validation = "medicareNo invalid";
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.medicareAgent.trim()) {
        emp.insuranceCompany = "01192";
        emp.payerId = "01192";
        emp.medicareAgent = "CA Medicare South";
      }
      const findVal = INSURANCE_PROVIDER.find(
        (f) => f.label.toLowerCase().replace(/\s/g, "") === emp.medicareAgent.toLowerCase().replace(/\s/g, "")
      );
      if (!findVal) {
        if (emp.medicareAgent.toLowerCase().includes("medicare")) {
          emp.insuranceCompany = "01192";
          emp.payerId = "01192";
          emp.medicareAgent = "CA Medicare South";
        } else {
          emp.validation = "Medicare Agent inValid";
          newEmpIds.push(emp);

          continue;
        }
      } else {
        emp.insuranceCompany = findVal.payerid;
        emp.payerId = findVal.payerid;
        emp.medicareAgent = findVal.label;
      }

      emp.validation = "Pass";
      newEmpIds.push(emp);

      // const orderObj = await api.createPreviousOrder(emp, appContext.user);
      // newEmpIds.push(orderObj);
    }
    const filterFileList = newEmpIds.filter((f) => f.validation === "Pass");
    const percentage = Math.round((filterFileList.length / newEmpIds.length) * 100);

    if (percentage >= 80) {
      const fileName = `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_order_test.csv`;
      try {
        await api.addEligibilityCsv(newEmpIds, fileName, "ltcs-update-record");
        // const res = await api.newEligibiliyFiles(obj, appContext.user);
        const text = `Thank You for Submitting ${newEmpIds.length} for Order Placed we will be process your request in couple of minutes and will be notified.`;
        dispatch(setMessage(text, MESSAGE_MODES.success));
      } catch (err) {
        dispatch(setMessage(err.message, MESSAGE_MODES.error));
      }
    } else {
      dispatch(setMessage("Your Records not Correct more than 20% please recheck", MESSAGE_MODES.error));
      const content = jsonToCSV(newEmpIds);
      const fileContent = new Blob([content], { type: "csv" });
      await FileSaver.saveAs(fileContent, "ltc_order_Status.csv");
    }
    setLoading(false);
  };

  const handleOpenEraFile = async (chequeNo) => {
    const fileName = `era/era_${chequeNo}.pdf`;
    try {
      setLoading(true);
      const res = await Storage.get(fileName, {
        bucket: CONFIG.eligibilityBucket,
      });
      console.log("res", res);
      window.open(`${res}`, "_blank");
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  const tdFormat = (item, user) => {
    if (item === "claimStatus") {
      if (user.claimStatus === "Accepted") {
        return (
          <div
            className="star-icon-wrapper cursor-pointer"
            onClick={() =>
              !appContext.isReadOnly() &&
              setOpenClearClaimModal({
                id: user.id,
                title: "Claim Status Rejected",
                claim: true,
              })
            }
          >
            <Status type="circle" size="md" color="green" crossIcon />
          </div>
        );
      }
      if (user.claimStatus === "Rejected") {
        return (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="maroon" crossIcon />
          </div>
        );
      }
      return "";
    }
    if (item === "InsuranceStatus") {
      if (user.InsuranceStatus?.toLowerCase() === "paid") {
        return (
          <div className="star-icon-wrapper" onClick={() => !appContext.isReadOnly() && setOpenAmountDetail(user.id)}>
            <Status type="circle" size="md" color="green" crossIcon />
          </div>
        );
      }
      if (user.InsuranceStatus === "Approved") {
        return (
          <div className="star-icon-wrapper" onClick={() => !appContext.isReadOnly() && setOpenAmountDetail(user.id)}>
            <Status type="circle" size="md" color="lightgreen" crossIcon />
          </div>
        );
      }
      if (user.InsuranceStatus === "Refund") {
        return (
          <div className="star-icon-wrapper" onClick={() => !appContext.isReadOnly() && setOpenAmountDetail(user.id)}>
            <Status type="circle" size="md" color="orange" crossIcon />
          </div>
        );
      }
      if (user.InsuranceStatus === "Denied") {
        return (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="maroon" crossIcon />
          </div>
        );
      }
      if (user.InsuranceStatus === "Pending") {
        return (
          <div
            className="star-icon-wrapper cursor-pointer"
            onClick={() =>
              !appContext.isReadOnly() &&
              setOpenClearClaimModal({
                id: user.id,
                title: "Update Insurance Status",
                insurance: true,
              })
            }
          >
            <Status type="circle" size="md" crossIcon />
          </div>
        );
      }
      return "";
    }
    return user[item];
  };
  const tdFormatTitle = (item, user) => {
    if (item === "InsuranceStatus") return user.InsuranceMessage || user[item];
    if (item === "claimStatus") return user.claimMessage || user[item];
    return user[item];
  };
  const renderTd = (item, user) => {
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const handelViewModal = (order) => {
    const Emps = formatEmployeesData(
      appContext.employees,
      appContext.subAgents,
      appContext.locations,
      appContext.companies
    );
    const emp = Emps.find((f) => f.id === order.employeeID);
    if (emp) {
      setEmployee(emp);
      setOpenViewModal(true);
    } else {
      dispatch(setMessage("Member Deleted", MESSAGE_MODES.error));
    }
  };

  const getTotalClaimSubmit = (list, val) => {
    switch (val) {
      case "totalClaimsAccepted":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.claimStatus?.toLowerCase() === "accepted" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsRejected":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.claimStatus?.toLowerCase() === "rejected" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsDeniedIns":
        return formatNumber(
          list.reduce((val, obj) => {
            return (
              (obj.claimStatus?.toLowerCase() === "accepted" && obj.InsuranceStatus?.toLowerCase() === "denied"
                ? 1
                : 0) + val
            );
          }, 0)
        );
      case "totalClaimsApprovedIns":
        return formatNumber(
          list.reduce((val, obj) => {
            return (
              (obj.InsuranceStatus?.toLowerCase() === "approved" || obj.InsuranceStatus?.toLowerCase() === "paid"
                ? 1
                : 0) + val
            );
          }, 0)
        );
      case "totalClaimsPaidIns":
        return formatNumber(list.filter((t) => t.InsuranceStatus?.toLowerCase() === "paid").length);
      case "totalClaimsPendingIns":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.claimStatus?.toLowerCase() === "accepted" ? 1 : 0) + val;
          }, 0)
        );
      case "totalClaimsAmount":
        return list.reduce((val, obj) => {
          return obj.claimAmount + val;
        }, 0);
      case "totalClaimsAmountOpen":
        return list.reduce((val, obj) => {
          return (
            (obj.claimStatus?.toLowerCase() === "accepted" && obj.InsuranceStatus?.toLowerCase() === "pending"
              ? obj.claimAmount
              : 0) + val
          );
        }, 0);
      case "totalClaimsAmountRejected":
        return list.reduce((val, obj) => {
          return (
            (obj.claimStatus?.toLowerCase() === "rejected" || obj.InsuranceStatus?.toLowerCase() === "denied"
              ? obj.claimAmount
              : 0) + val
          );
        }, 0);

      case "totalReceivedAmount":
        return list.reduce((val, obj) => {
          return obj.amountRecieved + val;
        }, 0);
      case "totalExpectedAmount":
        return list
          .filter((f) => f.InsuranceStatus?.toLowerCase() === "approved")
          .reduce((val, obj) => {
            return obj.expectedAmount + val;
          }, 0);
      case "totalDifferenceAmount":
        return list
          .filter((f) => f.InsuranceStatus?.toLowerCase() === "approved" || f.InsuranceStatus?.toLowerCase() === "paid")
          .reduce((val, obj) => {
            const amt = obj.InsuranceStatus?.toLowerCase() === "paid" ? obj.amountRecieved : obj.expectedAmount;
            return obj.claimAmount - amt + val;
          }, 0);
      default:
        return 0;
    }
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setNoteOrder(row);
        break;

      case "testQty":
        setOpenOrderDetail(true);
        setSelectedOrder(row);
        break;

      case "chequeNo":
        handleOpenEraFile(row.chequeNo);
        break;

      default:
        break;
    }
  };

  const selectedItem = [
    { itemKey: "orderDate" },
    { itemKey: "claimSubmitDate" },
    { itemKey: "firstName" },
    { itemKey: "lastName" },
    { itemKey: "testQty" },
    { itemKey: "street" },
    { itemKey: "city" },
    { itemKey: "state" },
    { itemKey: "zip" },
    { itemKey: "clientName" },
    { itemKey: "siteName" },
    { itemKey: "payerId" },
    { itemKey: "medicalNo" },
    { itemKey: "InsuranceStatus" },
    { itemKey: "claimStatus" },
    { itemKey: "orderStatus" },
    { itemKey: "claimAmount" },
    { itemKey: "amountRecieved" },
    { itemKey: "amountRecievedDate" },
    { itemKey: "requestedByName" },
    { itemKey: "orderId" },
    { itemKey: "pcn" },
  ];

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("billing")} ({filteredOrders ? getTotalOrders(filteredOrders) : getTotalOrders(users)})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (filteredOrders && filteredOrders.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />
                  {!appContext.isReadOnly() && (
                    <div className="header-btns" title={"Claim Cancel"}>
                      <img className="header-btn-img" src={closeIcon} alt="close icon" />
                      <span className="header-btn-labels mx-auto ellipsis">Claim Cancel</span>
                    </div>
                  )}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <ExportToExcel
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={filteredOrders}
                    appContext={appContext}
                    filter={filter}
                    title="Billing Data"
                    sortBy={sortBy}
                    orderTests={true}
                  />

                  {!appContext.user?.isUser() && !appContext.isReadOnly() && (
                    <>
                      <Icon
                        title={"Re-Submit Claim"}
                        label={"Re-Submit"}
                        disabled={checkboxes.length === 0}
                        handleClick={
                          (e) => onHandleSubmitClaim()
                          // handleDownload(
                          //   filteredOrders.filter(
                          //     (f) => f.orderDate !== "20220901"
                          //   )
                          // )
                          // handleDownload(
                          //   filteredOrders.filter(
                          //     (f) =>
                          //       checkboxes.includes(f.id) &&
                          //       f.InsuranceStatus === "Denied"
                          //   )
                          // )
                        }
                        iconType={"acknowledgedPositiveIcon"}
                      />
                      {!appContext.isReadOnly() && appContext?.user?.phone_number === "+18888888888" && (
                        <Icon
                          title="Approved"
                          label="Approved"
                          disabled={checkboxes.length === 0 || checkboxes.length > 1}
                          handleClick={(e) => {
                            const record = filteredOrders.find((e) => e.id === checkboxes[0]);
                            // remove check temporary to add claims
                            // if (record && record.InsuranceStatus === "Pending") {
                            if (record) {
                              setOpenAmountDetail(checkboxes[0]);
                            }
                          }}
                          iconType={"acknowledgedPositiveIcon"}
                        />
                      )}
                      <Icon
                        handleClick={() => {
                          setOpenImportModal(true);
                        }}
                        title={"Import ERA"}
                        label={"Import ERA"}
                        iconType={"importIcon"}
                      />
                    </>
                  )}
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                {!appContext.isReadOnly() && (
                  <div className="dashboard-wrapper">
                    <div className="d-flex w-100">
                      <div className="stats-item">
                        <div>
                          <Stats
                            title={"Total Amount of Claims Submitted"}
                            totalCounts={currencyformatter.format(getTotalClaimSubmit(orders, "totalClaimsAmount"))}
                            color="black"
                          />
                          <Stats
                            title={"Total Amount of Claims Open"}
                            totalCounts={currencyformatter.format(getTotalClaimSubmit(orders, "totalClaimsAmountOpen"))}
                            color="red"
                          />
                          <Stats
                            title={"Total Amount of Claims Paid"}
                            totalCounts={currencyformatter.format(getTotalClaimSubmit(orders, "totalReceivedAmount"))}
                            color="green"
                            handelClick={(obj) =>
                              setFilter({
                                paidClaim: "notnull",
                              })
                            }
                          />
                          {/* <Stats
                            title={"Total Amount of Claims Expected"}
                            totalCounts={currencyformatter.format(
                              getTotalClaimSubmit(orders, "totalExpectedAmount")
                            )}
                            color="green"
                            handelClick={(obj) =>
                              setFilter({
                                InsuranceStatus: "Approved",
                              })
                            }
                          />
                          <Stats
                            title={"Total Amount of Claims Rejected"}
                            totalCounts={currencyformatter.format(
                              getTotalClaimSubmit(
                                orders,
                                "totalClaimsAmountRejected"
                              )
                            )}
                            color="maroon"
                            filterKey="claimStatus"
                            value="Accepted"
                            handelClick={(obj) =>
                              setFilter({
                                totalRejectedAmount: "rejected",
                              })
                            }
                          /> */}
                          {/* <Stats
                          title={
                            "Total Amount of Difference B/W Claims and Received"
                          }
                          totalCounts={currencyformatter.format(
                            getTotalClaimSubmit(orders, "totalDifferenceAmount")
                          )}
                          color="maroon"
                        /> */}
                        </div>
                      </div>
                      {/* <SingleStatsCard
                        label={"Clearing House"}
                        totalCounts={getTotalOrders(orders)}
                        amount={"$224.00"}
                        handelClick={() => setFilter({})}
                        color="red"
                      /> */}
                      <div className="stats-item">
                        <div>
                          <Stats
                            title={"Total Claims Submitted to Clearing House"}
                            totalCounts={getTotalOrders(orders)}
                            handelClick={() => setFilter({})}
                            color="black"
                          />
                          <Stats
                            title={"Total Claims Accepted by Clearing House"}
                            totalCounts={getTotalClaimSubmit(orders, "totalClaimsAccepted")}
                            filterKey="claimStatus"
                            value="Accepted"
                            handelClick={(obj) =>
                              setFilter({
                                ...obj,
                              })
                            }
                            color="black"
                          />
                          <Stats
                            title={"Total Claims Rejected by Clearing House"}
                            totalCounts={getTotalClaimSubmit(orders, "totalClaimsRejected")}
                            filterKey="claimStatus"
                            value="Rejected"
                            handelClick={(obj) =>
                              setFilter({
                                ...obj,
                              })
                            }
                            color="black"
                          />
                        </div>
                      </div>
                      <div className="stats-item">
                        <Stats
                          title={"Total Claims Submitted to Insurance"}
                          totalCounts={getTotalClaimSubmit(orders, "totalClaimsPendingIns")}
                          filterKey="claimStatus"
                          value="Accepted"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />

                        <Stats
                          title={"Total Claims Approved by Insurance"}
                          totalCounts={getTotalClaimSubmit(orders, "totalClaimsApprovedIns")}
                          filterKey="paidClaim"
                          value="Approved"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                        <Stats
                          title={"Total Claims Paid by Insurance"}
                          totalCounts={getTotalClaimSubmit(orders, "totalClaimsPaidIns")}
                          filterKey="paidClaim"
                          value="notnull"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                        <Stats
                          title={"Total Claims Denied by Insurance"}
                          totalCounts={getTotalClaimSubmit(orders, "totalClaimsDeniedIns")}
                          filterKey="InsuranceStatus"
                          value="Denied"
                          handelClick={(obj) => setFilter({ ...obj, claimStatus: "Accepted" })}
                          color="black"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={filteredOrders.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={itemsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={170}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  dropDownOptions={!appContext.isReadOnly() && BILLING_ORDERS_DROPDOWN_OPTIONS}
                  handleDropDownClick={(type, row) => {
                    if (type === "view") {
                      handelViewModal(row);
                    }
                  }}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Order"
          message="Are you sure, you want to delete Order?"
          handleConfirm={handleConfirmDelete}
          closeBtn
        />
      )}
      {orderModal && (
        <OrderModal
          handleClose={(isProcess, limitExccedEmployees, order) => {
            setOrderModal(false);
            if (isProcess) {
              if (limitExccedEmployees && limitExccedEmployees.length > 0) {
                const text = `We could not process your order at this time. You have reached your limit for this program. Please try again next month and check your eligibility`;
                dispatch(setMessage("Member Deleted", MESSAGE_MODES.error));
              } else {
                dispatch(
                  setMessage(
                    `You have successfully ordered ${order.quantity} COVID-19 Antigen tests.`,
                    MESSAGE_MODES.success
                  )
                );
              }
              getOrders();
            }
          }}
          employeeIds={appContext.employeeRecord ? [appContext.employeeRecord.id] : []}
          appContext={appContext}
          testAvailable={appContext.employeeRecord ? appContext.employeeRecord.testAvailable : 2}
        />
      )}

      {openOrderDetail && (
        <OrderLogsModal
          order={selectedOrder}
          show={openOrderDetail}
          appContext={appContext}
          handleClose={() => {
            setOpenOrderDetail(false);
            setSelectedOrder("");
          }}
        />
      )}
      {openViewModal && (
        <EmployeeModal
          user={employee}
          handleClose={() => setOpenViewModal(false)}
          handleSave={createMember}
          resetCall={setOpenPasswordModal}
          users={appContext.employees}
        />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {openClearClaimModal && (
        <ClearClaimModal
          handleClose={(val) => {
            if (val) {
              dispatch(setMessage(val, MESSAGE_MODES.success));
              getOrders();
            }
            setOpenClearClaimModal(null);
          }}
          orderID={openClearClaimModal.id}
          title={openClearClaimModal.title}
          claim={openClearClaimModal.claim}
          insurance={openClearClaimModal.insurance}
          user={appContext.user}
        />
      )}

      {openAmountDetail && (
        <ReceivedAmountDetailsModal
          orderID={openAmountDetail}
          handleClose={(action) => {
            if (action) {
              if (action === claimActions.receive) {
                dispatch(setMessage("Received Amount Details Successfully Added", MESSAGE_MODES.success));
              } else {
                dispatch(setMessage("Refund Amount Details Successfully Added", MESSAGE_MODES.success));
              }
              getOrders();
            }
            setCheckboxes([]);
            setOpenAmountDetail("");
          }}
          user={appContext.user}
        />
      )}

      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImportEra}
          appContext={appContext}
          title="Import ERA Data"
          eligibility={true}
          claim
        />
      )}
      {claimReSubmissionModal && (
        <NewClaimSubmissionModal
          orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1 && f.InsuranceStatus === "Denied")}
          employees={appContext.employees}
          handleClose={(val) => {
            if (val) {
              setCheckboxes([]);
              getOrders();
            }
            setClaimReSubmissionModal(false);
          }}
          fileDir="reclaim"
        />
      )}
      {noteOrder && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              obj.note?.message
                ? dispatch(setMessage("Add Notes Successfully", MESSAGE_MODES.success))
                : dispatch(setMessage("Remove Notes Successfully", MESSAGE_MODES.success));
              const findVal = orders.find((f) => f.id === obj.id);
              if (findVal) {
                Object.assign(findVal, { note: obj.note });
                const index = orders.findIndex((f) => f.id === findVal.id);
                if (index !== -1) {
                  const data = [...orders];
                  data.splice(index, 1, findVal);
                  setOrders(data);
                  setFilteredOrders(sortData(nestedFilter(data, filter), sortBy));
                }
              }
            }
            setNoteOrder(null);
          }}
          data={noteOrder}
          order={true}
          user={appContext.user}
        />
      )}
    </div>
  );
};

export default Billing;
