import { claimSubmitStatusColorOpts } from "constant";
import { CLAIMS_STATS_DETAILS, CLAIM_SUBMIT_STATUS, PAID_CLAIM_SPANS_MODAL_FORMAT, currencyformatter } from "constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { monthlyClaimsSelector } from "store/features/dashboard/dashboardSelectors";
import { filterMonthlyClaimsAsync } from "store/features/dashboard/dashboardSlice";
import { formatNumber, getPercentage } from "utils";
import PaidClaimsFilterModal from "views/LabDashboard/Modals/PaidClaimsFilterModal/PaidClaimsFilterModal";
import SimilarStatusClaimsModal from "views/LabDashboard/Modals/SimilarStatusClaimsModal/SimilarStatusClaimsModal";
import { getLastestDeniedRejectedClaimsAsync } from "store/features/dashboard/dashboardSlice";
import filterIcon from "../../../../assets/img/Filter-icon.svg";
import SingleMonthlyStats from "./SingleMonthlyStats";

const MonthlyClaimStats = () => {
  const [reset, setReset] = useState(true);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filteredStats, setFilteredStats] = useState(CLAIMS_STATS_DETAILS || null);
  const [showClaimsList, setShowClaimsList] = useState("");
  const stats = useSelector(monthlyClaimsSelector);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);
  const OTHER_STATUS_ARR = [CLAIM_SUBMIT_STATUS.draft, CLAIM_SUBMIT_STATUS.cancel, CLAIM_SUBMIT_STATUS.partial];

  useEffect(() => {
    if (reset) {
      getStats({ date: CLAIMS_STATS_DETAILS.date, selectedSpan: CLAIMS_STATS_DETAILS.selectedSpan });
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (filter) getStats(filter);
  }, [filter]);

  useEffect(() => {
    const statsTotalObj = (stats?.rows || []).reduce((val, obj, i) => {
      val.totalClaims = parseInt(obj.count) + (val.totalClaims || 0);
      val.totalPayableAmount = parseInt(obj.ttlcharge) + (val.totalPayableAmount || 0);
      if (!OTHER_STATUS_ARR.includes(obj.status)) {
        val[obj.status] = {
          count: parseInt(obj.count) + (val[obj.status]?.count || 0),
          payableAmount: parseInt(obj.ttlcharge) + (val[obj.status]?.payableAmount || 0),
        };
      } else {
        val.others = {
          count: parseInt(obj.count) + (val.others?.count || 0),
          payableAmount: parseInt(obj.ttlcharge) + (val.others?.payableAmount || 0),
        };
      }
      return val;
    }, {});

    const { date, payerId, selectedSpan } = filteredStats;
    let detailsObj = { date, payerId, selectedSpan, ...stats, ...statsTotalObj };
    setFilteredStats(detailsObj);
  }, [stats]);

  const getTotalClaimSubmit = (list, val) => {
    switch (val) {
      case "totalClaimsSubmitted":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.submitted ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalClaimsRejected":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.rejected ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalClaimsDraft":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.draft ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalClaimsDenied":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.denied ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalClaimsApproved":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.approved ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalClaimsPaid":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.paid ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalClaimsPartail":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.partial ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalClaimsCancel":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.status === CLAIM_SUBMIT_STATUS.cancel ? parseInt(obj.count) : 0) + val;
          }, 0)
        );
      case "totalDraftAmount":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.draft ? parseFloat(obj.ttlcharge) || 0 : 0) + val;
        }, 0);
      case "totalChargeAmount":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.submitted ? parseFloat(obj.ttlcharge) || 0 : 0) + val;
        }, 0);
      case "totalAmountRejected":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.rejected ? parseFloat(obj.ttlcharge) || 0 : 0) + val;
        }, 0);
      case "totalReceivedAmount":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.approved ? parseFloat(obj.ttlcharge) || 0 : 0) + val;
        }, 0);
      case "totalPaidAmount":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.paid ? parseFloat(obj.paidAmount) || 0 : 0) + val;
        }, 0);
      case "totalPartialAmount":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.partial ? parseFloat(obj.paidAmount) || 0 : 0) + val;
        }, 0);
      case "totalDeniedAmount":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.denied ? parseFloat(obj.ttlcharge) || 0 : 0) + val;
        }, 0);
      case "totalCancelAmount":
        return list.reduce((val, obj) => {
          return (obj.status === CLAIM_SUBMIT_STATUS.cancel ? parseFloat(obj.ttlcharge) || 0 : 0) + val;
        }, 0);

      default:
        return 0;
    }
  };

  const getStats = async (dateObj) => {
    if (!dateObj || !dateObj.date) return;
    setOpenFilterModal(false);

    const obj = {
      date: dateObj.date,
      pid: dateObj.payerid || "",
      selectedSpan: dateObj.selectedSpan,
    };
    setFilteredStats({ ...filteredStats, ...obj });
    dispatch(filterMonthlyClaimsAsync(obj));
  };

  const handleLabelClick = (e, itemKey) => {
    const clickedStats = statsData.find((stat) => stat.itemKey === itemKey);
    if (clickedStats.totalAmount) {
      dispatch(getLastestDeniedRejectedClaimsAsync({ type: clickedStats.itemKey }));
    }
  };

  const statsData = [
    {
      color: claimSubmitStatusColorOpts[CLAIM_SUBMIT_STATUS.submitted],
      percentage: getPercentage(
        getTotalClaimSubmit(stats.rows, "totalClaimsSubmitted") || 0,
        filteredStats.totalClaims
      ),
      payableAmount: getTotalClaimSubmit(stats.rows, "totalChargeAmount") || 0,
      totalAmount: getTotalClaimSubmit(stats.rows, "totalClaimsSubmitted"),
      label: "Submitted",
      itemKey: CLAIM_SUBMIT_STATUS.submitted,
    },
    {
      color: claimSubmitStatusColorOpts[CLAIM_SUBMIT_STATUS.denied],
      percentage: getPercentage(getTotalClaimSubmit(stats.rows, "totalClaimsDenied") || 0, filteredStats.totalClaims),
      payableAmount: getTotalClaimSubmit(stats.rows, "totalDeniedAmount") || 0,
      totalAmount: getTotalClaimSubmit(stats.rows, "totalClaimsDenied") || 0,
      label: "Denied",
      itemKey: CLAIM_SUBMIT_STATUS.denied,
    },
    {
      color: claimSubmitStatusColorOpts[CLAIM_SUBMIT_STATUS.rejected],
      percentage: getPercentage(getTotalClaimSubmit(stats.rows, "totalClaimsRejected") || 0, filteredStats.totalClaims),
      payableAmount: getTotalClaimSubmit(stats.rows, "totalAmountRejected") || 0,
      totalAmount: getTotalClaimSubmit(stats.rows, "totalClaimsRejected") || 0,
      label: "Rejected",
      itemKey: CLAIM_SUBMIT_STATUS.rejected,
    },
    {
      color: claimSubmitStatusColorOpts[CLAIM_SUBMIT_STATUS.paid],
      percentage: getPercentage(getTotalClaimSubmit(stats.rows, "totalClaimsPaid") || 0, filteredStats.totalClaims),
      payableAmount: getTotalClaimSubmit(stats.rows, "totalPaidAmount") || 0,
      totalAmount: getTotalClaimSubmit(stats.rows, "totalClaimsPaid") || 0,
      label: "Paid",
      itemKey: CLAIM_SUBMIT_STATUS.paid,
    },
    {
      color: claimSubmitStatusColorOpts[CLAIM_SUBMIT_STATUS.approved],
      percentage: getPercentage(getTotalClaimSubmit(stats.rows, "totalClaimsApproved") || 0, filteredStats.totalClaims),
      payableAmount: getTotalClaimSubmit(stats.rows, "totalReceivedAmount") || 0,
      totalAmount: getTotalClaimSubmit(stats.rows, "totalClaimsApproved") || 0,
      label: "Approved",
      itemKey: CLAIM_SUBMIT_STATUS.approved,
    },
  ];

  return (
    <>
      <div className="monthlyClaimStats">
        <div className="monthlyClaimStats-totalClaims">
          <div className="totalMonthlyStatsContainer">
            <span className="fs-15">Total Claims:</span>
            <span
              className="monthly-claim-filter-span whiteSpace-noWrap d-block"
              title={`${moment(filteredStats.date).format(
                PAID_CLAIM_SPANS_MODAL_FORMAT[filteredStats.selectedSpan].momentFormat
              )} - ${filteredStats.selectedSpan}`}
            >
              By:{" "}
              {moment(filteredStats.date).format(
                PAID_CLAIM_SPANS_MODAL_FORMAT[filteredStats.selectedSpan].momentFormat
              )}{" "}
              <b>{filteredStats.selectedSpan}</b>
            </span>
            <div className="d-flex align-items-center w-100 flex-column">
              <h3 className="totalMonthlyStatsText" title={formatNumber(filteredStats.totalClaims)}>
                {formatNumber(filteredStats.totalClaims)}
              </h3>
              <span
                className="totalMonthlyPayableText"
                title={currencyformatter.format(filteredStats.totalPayableAmount || 0)}
              >
                {currencyformatter.format(filteredStats.totalPayableAmount || 0)}
              </span>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <i className="fas fa-undo cursor-pointer" onClick={() => setReset(true)} />
              <img src={filterIcon} className="statsFilterIcon" onClick={() => setOpenFilterModal(true)} />
            </div>
          </div>
        </div>
        {statsData.map((stats) => (
          <SingleMonthlyStats
            key={stats.itemKey}
            color={stats.color}
            percentage={stats.percentage}
            totalAmount={stats.totalAmount}
            payableAmount={stats.payableAmount}
            label={stats.label}
            itemKey={stats.itemKey}
            handleLabelClick={handleLabelClick}
          />
        ))}
      </div>
      {openFilterModal && (
        <PaidClaimsFilterModal handleClose={() => setOpenFilterModal(false)} handleChange={(obj) => setFilter(obj)} />
      )}
      {showClaimsList && (
        <SimilarStatusClaimsModal
          statsData={filteredStats.rows.filter((row) =>
            showClaimsList !== "others" ? row.status === showClaimsList : OTHER_STATUS_ARR.includes(row.status)
          )}
          status={showClaimsList}
          handleClose={() => setShowClaimsList("")}
        />
      )}
    </>
  );
};

export default MonthlyClaimStats;
