import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "components/InputField/InputStyle.module.css";
import { debounce } from "debounce";
import { ViewportList } from "react-viewport-list";
import api from "../../api";

const InputCPTCodeDropDown = (props) => {
  const {
    label,
    type,
    placeholder,
    handleChange,
    labelStyle,
    inputStyle,
    value,
    maxlength,
    groupWrapper,
    optionList,
    readOnly,
  } = props;

  const [input, setInput] = useState(value || "");
  const [description, setDescription] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const handleTypeDelay = useCallback(
    debounce((data) => {
      handleChangeInputText(data);
    }, 200),
    []
  );

  useEffect(() => {
    if (value) {
      setInput(value);
    } else {
      setDescription("");
    }
  }, [value]);

  const handleChangeInputText = async (data) => {
    if (!data || !data.trim()) {
      setFilteredList([]);
      return;
    }
    const response = await api.searchCptCode({ code: data });
    setFilteredList(response);
  };

  const optionRow = (option, index) => {
    return (
      <li
        key={index}
        value={option.code}
        className="listOption"
        onClick={() => {
          setInput(option.code);
          handleChange({ ...option, value: option.code, label: option.description });
          setFilteredList([]);
        }}
        title={option.description}
      >
        <span className="fw-bold optListContent">{option.code}</span>
        <span className="fw-light optListContent">{option.description}</span>
      </li>
    );
  };

  return (
    <Form.Group className={`form-group-wrapper ${groupWrapper}`}>
      {label ? <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{label}</Form.Label> : ""}
      <div className="position-relative w-100">
        <Form.Control
          type={type}
          maxLength={maxlength}
          title={description}
          value={input}
          readOnly={readOnly}
          placeholder={placeholder}
          className={`${styles.inputStyling} ${inputStyle}`}
          onChange={(e) => {
            const val = e.target.value;
            setInput(val);
            setDescription("");
            handleTypeDelay(val);
          }}
        />
        {value && (
          <i
            className="fas fa-times cancelSearchInput"
            onClick={() => {
              handleTypeDelay("");
              handleChange("");
              setInput("");
            }}
          />
        )}
        {filteredList && !readOnly && filteredList.length > 0 && (
          <ul
            className="optionListWrapper"
            onBlur={() => {
              setInput("");
              setFilteredList([]);
            }}
          >
            <ViewportList items={filteredList} overflowAnchor="auto" withCache={true}>
              {(item, index) => optionRow(item, index)}
            </ViewportList>
          </ul>
        )}
      </div>
    </Form.Group>
  );
};

export default InputCPTCodeDropDown;
