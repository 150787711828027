import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { CLAIM_CHANGE_OPTS, MESSAGE_MODES } from "constant";
import { AppContext } from "context/app-context";
import graphqlApi from "graphqlApi";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { updateStatusEmployeeClaimAsync } from "store/features/employeeClaims/employeeClaimsSlice";

const ChangeClaimStatusModal = (props) => {
  const { selectedClaimIds, cssClass, handleClose } = props;
  const appContext = useContext(AppContext);
  const [claims, setClaims] = useState([]);
  const [newStatus, setNewStatus] = useState(null);
  const [reason, setReason] = useState("");
  const [newDate, setNewDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const loginUser = useSelector(selectLoginUser);

  useEffect(() => {
    getClaims();
  }, []);

  const getClaims = async () => {
    const claimsData = await api.getEmployeeClaimsAPI();
    const selectedClaims = claimsData.filter((claim) => selectedClaimIds.includes(claim.id));
    setClaims(selectedClaims);
  };

  const handleSubmit = async () => {
    if (!newDate) return setError("Please select a date before proceeding!");
    if (!newStatus) return setError("Please select a new status!");
    if (!reason) return setError("Please fill out the reason before proceeding!");

    try {
      setLoading(true);
      const claimsList = [...claims] || [];
      dispatch(updateStatusEmployeeClaimAsync({ claimsList, newDate, newStatus, reason }));
      setLoading(false);
      handleClose(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      className={`${cssClass}`}
      centered
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Change Claims Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div>
          <label className="modalLineHeaders">Log Date:</label>
          <input
            className="modalInput"
            type="datetime-local"
            value={newDate}
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => setNewDate(e.target.value)}
          />
          <Form.Group>
            <Form.Label className="mt-0 text-capitalize">Select Claim Status</Form.Label>
            <Select
              options={CLAIM_CHANGE_OPTS}
              blurInputOnSelect={true}
              menuPlacement="auto"
              placeholder="Select Claim Status"
              value={newStatus}
              onChange={(e) => setNewStatus(e)}
            />
          </Form.Group>
          <h4 className="profile-title">Reason</h4>
          <div className="mb-3">
            <textarea
              className="form-control reason-textArea"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      {loading && <Loader />}

      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeClaimStatusModal;
