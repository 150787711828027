import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import {
  COMPANY_SETTING,
  COMPANY_SETTING_FETCH,
  COMPANY_SETTING_UPDATE,
  COMPANY_SETTING_UPDATE_CLI_LIST,
} from "./companySettingConstants";

export const fetchCompanySetting = createAsyncThunk(COMPANY_SETTING_FETCH, async () => {
  const response = await api.getCompanySetting(); // Your API call logic here
  return response;
});

export const updateCompanySetting = createAsyncThunk(COMPANY_SETTING_UPDATE, async (defaultSetting) => {
  const response = await api.updateCompnayDefaultSetting(defaultSetting); // Your API call logic here
  return response;
});
export const updateCompanySettingCLIList = createAsyncThunk(COMPANY_SETTING_UPDATE_CLI_LIST, async (defaultSetting) => {
  const response = await api.updateCliItem(defaultSetting); // Your API call logic here
  return response;
});

const companyUserSlice = createSlice({
  name: COMPANY_SETTING,
  initialState: { setting: null },
  reducers: {
    setCliList: (state, action) => {
      state.setting["cliaNumber"] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        [updateCompanySetting, fetchCompanySetting, updateCompanySettingCLIList].some((thunk) =>
          action.type.startsWith(thunk.fulfilled.type)
        ),
      (state, action) => {
        state.setting = action.payload;
      }
    );
  },
});

export const { setCliList } = companyUserSlice.actions;

export default companyUserSlice.reducer;
