import api from "api";
import Loader from "components/Loader/Loader";
import { UNPAID_CLAIMS_STATS_DAYS_GAP } from "constant";
import { AppContext } from "context/app-context";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import LabStatsContainer from "./Containers/LabStatsContainer/LabStatsContainer";
import DashboardClaimTable from "./Tables/DashboardClaimTable/DashboardClaimTable";

const dummyData = {
  claims: {
    unpaidAmount: 0,
  },
  patients: {
    total: 0,
    newPatients: 0,
  },
  appeals: {
    approved: 0,
    pending: 0,
  },
};

const LabDashboard = () => {
  const appContext = useContext(AppContext);
  const [data, setData] = useState(dummyData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMonthlyCompanyStats();
  }, [appContext.employees]);

  const getMonthlyCompanyStats = async () => {
    setLoading(true);

    setData({
      ...data,
      claims: {
        ...data.claims,
      },
      patients: {
        total: appContext.employees.length,
        newPatients: appContext.employees.filter(
          (patient) => moment(patient.createdAt).format("MM-YYYY") == moment().format("MM-YYYY")
        ).length,
      },
    });
    setLoading(false);
  };

  return (
    <div className="labDashboard">
      <>
        <LabStatsContainer statsObj={data} />
        <DashboardClaimTable />
        {loading && <Loader />}
      </>
    </div>
  );
};

export default LabDashboard;
