import SweetAlert from "react-bootstrap-sweetalert";

const ShowAlertMessage = (props) => {
  const { message, handleClose, info, error, success } = props;
  return (
    <SweetAlert
      show={true}
      info={Boolean(info)}
      error={Boolean(error)}
      success={Boolean(success)}
      onConfirm={handleClose}
      customClass={`tcMatrix-custom-notification ${info ? "infoAlert" : ""}`}
    >
      {message}
    </SweetAlert>
  );
};

export default ShowAlertMessage;
