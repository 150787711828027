import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "utils";

const NewPatientsStats = (props) => {
  const { totalPatients, newPatients, color } = props;
  const colorContrast = color || "red";
  const startDate = moment().clone().startOf("month").toISOString();
  const endDate = moment().toISOString();

  return (
    <div className="newPatientsStats">
      <div className="d-flex justify-content-between">
        <span>Total Patient Vs New</span>
        <Link
          className="linkedText"
          to={{
            pathname: "/admin/members",
            state: {
              term: "createdAt",
              name: {
                startDate,
                endDate,
              },
            },
          }}
        >
          See Details
        </Link>
      </div>
      <div>
        <span className="fs-55">{formatNumber(totalPatients)}</span>
        <span className="fs-40" style={{ color: colorContrast }}>
          /{formatNumber(newPatients)}
        </span>
      </div>
      <div className="newPatientsStats-bottom">
        <span style={{ backgroundColor: colorContrast }} className="color-identifier" />
        <span style={{ color: colorContrast }} className="fs-30">
          {formatNumber(newPatients)}
        </span>
        <span>New Patient of this Month</span>
      </div>
    </div>
  );
};

export default NewPatientsStats;
