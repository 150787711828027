import AutoCompleteAddress from "components/AutoCompleteAddress";
import styles from "components/InputField/InputStyle.module.css";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import moment from "moment";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-date-picker";
import Select from "react-select";

const InputField = (props) => {
  const {
    label,
    type,
    placeholder,
    handleChange,
    labelStyle,
    inputStyle,
    value,
    maxlength,
    groupWrapper,
    disabled,
    index,
    errorList,
    options,
    isClearable,
    getOptionValue,
    getOptionLabel,
    componentOptions,
    onInputChange,
  } = props;
  const [error, setError] = useState(false);
  useEffect(() => {
    if (errorList && errorList.length > 0) {
      const res = errorList?.includes(index);
      setError(res);
    }
  }, [errorList]);

  const getInPutFields = (inputType) => {
    switch (inputType) {
      case "phone":
        return (
          <PhoneNoInput
            handleChange={handleChange}
            value={value || ""}
            disabled={disabled}
            cssClass={error && "errorField"}
          />
        );
      case "address":
        return (
          <AutoCompleteAddress
            cssClass={`${inputStyle} ${disabled ? "disabledAddressBar" : ""} ${error && "errorField"}`}
            value={value || ""}
            disabled={disabled}
            handleChange={(e, flag) => handleChange(e, flag)}
          />
        );
      case "datePicker":
        return (
          <DatePicker
            className={`${inputStyle} ${error && "errorField"}`}
            openCalendarOnFocus={false}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            format="MM/dd/y"
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            value={value && new Date(value) != "Invalid Date" ? moment(value, "YYYY-MM-DD").toDate() : null}
            maxDate={new Date()}
            minDate={new Date("1900-01-01")}
          />
        );
      case "dropDown":
        return (
          <Select
            options={options}
            className={`${inputStyle || ""} ${error && "errorField"}`}
            blurInputOnSelect={true}
            isClearable={isClearable}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            menuPlacement="auto"
            placeholder={placeholder}
            isDisabled={disabled}
            value={value}
            onChange={(e) => handleChange(e)}
            components={componentOptions}
            onInputChange={onInputChange}
          />
        );
      default:
        return (
          <Form.Control
            type={inputType}
            maxLength={maxlength}
            value={value || ""}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => handleChange(e)}
            className={`${styles.inputStyling} ${inputStyle} ${error ? "errorField" : ""}`}
          />
        );
    }
  };
  return (
    <Form.Group className={`${groupWrapper ? groupWrapper : "form-group-wrapper"}`}>
      {label ? <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{label}</Form.Label> : ""}
      {getInPutFields(type)}
    </Form.Group>
  );
};

export default InputField;
