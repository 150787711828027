import { createEmployeeAsync, updateEmployeeAsync, deleteEmployeeAsync } from "./employeesSlice";

export const createEmployee = (employee) => async (dispatch) => {
  await dispatch(createEmployeeAsync(employee));
};

export const updateEmployee = (employee) => async (dispatch) => {
  await dispatch(updateEmployeeAsync(employee));
};

export const deleteEmployee = (employeeId) => async (dispatch) => {
  await dispatch(deleteEmployeeAsync(employeeId));
};
