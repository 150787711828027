import React from "react";

const ProgressBar = ({ bgcolor, progress, height, showText }) => {
  const Parentdiv = {
    height,
  };

  const Childdiv = {
    width: `${progress}%`,
    backgroundColor: bgcolor,
  };

  return (
    <div className="progress-bar" style={Parentdiv}>
      <div className="progress-bar-inner" style={Childdiv}>
        {showText && <span className="progresstext">{`${progress}%`}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
