import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { CONFIG } from "../../../constant";
import { userCompanyID } from "../../../utils";
import { CHECK_ELIGIBILITY, CHECK_ELIGIBILITY_FETCH } from "./checkEligibilityConstant";

// Async thunk to fetch all eligibilities

export const fetchAllEligibilitiesAsync = createAsyncThunk(
  CHECK_ELIGIBILITY_FETCH,
  async ({ page, filter, isNewCall }, { getState }) => {
    const user = getState().auth.user;
    const params = { ...filter, page };

    if (CONFIG.isLabType) {
      Object.assign(params, { clientID: userCompanyID.get() });
    } else if (user?.isClient()) {
      Object.assign(params, { clientID: userCompanyID.get() });
    } else if (user?.isSite()) {
      Object.assign(params, { locationID: userCompanyID.get() });
    } else if (user?.isSubAgent()) {
      Object.assign(params, { subAgentID: userCompanyID.get() });
    }
    if (isNewCall) {
      Object.assign(params, { page: 1 });
    }

    let res = await api.getEligibilityList(params);

    return res;
  }
);

// Async thunk to create a eligibility
export const createeligibilityAsync = createAsyncThunk("eligibility/careateeligibility", async (eligibility) => {
  const response = await api.geteligibilities(eligibility);
  return response;
});

// Async thunk to update a eligibility
export const updateeligibilityAsync = createAsyncThunk(
  "eligibility/updateeligibility",
  async ({ eligibilityId, updatedeligibility }) => {
    const response = await api.updateeligibility(eligibilityId, updatedeligibility);
    return response;
  }
);

// Async thunk to delete a eligibility
export const deleteeligibilityAsync = createAsyncThunk("eligibility/deleteeligibility", async (eligibilityId) => {
  const response = await api.deleteeligibility(eligibilityId);
  return response;
});

const eligibilitieslice = createSlice({
  name: CHECK_ELIGIBILITY,
  initialState: {
    eligibilities: [],
    filteredEligibilities: [],
    totalRecord: 0,
    currentPage: 1,
    pageNo: 1,
    openCheckEligibilityModal: false,
  },
  reducers: {
    setFilteredEligibilities: (state, action) => {
      state.filteredEligibilities = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    createEligibility: (state, action) => {
      state.eligibilities.unshift(action.payload);
      state.filteredEligibilities.unshift(action.payload);
    },
    setOpenCheckEligibilityModal: (state, action) => {
      state.openCheckEligibilityModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEligibilitiesAsync.fulfilled, (state, action) => {
        const res = action.payload;
        const eligibilityLists = res.rows.map((r) => ({ ...r, view: "view" }));
        const models = [...state.eligibilities, ...eligibilityLists];
        if (state.pageNo === 1) {
          state.totalRecord = res.total;
          state.eligibilities = eligibilityLists;
          state.filteredEligibilities = eligibilityLists;
        } else {
          state.eligibilities = models;
          state.filteredEligibilities = models;
        }

        // setFilteredEligibilities(sortUsers(filterDates(nestedFilter(models, filter), timeFilter), sortBy));
      })
      .addCase(createeligibilityAsync.fulfilled, (state, action) => {
        state.eligibilities.push(action.payload);
      })
      .addCase(updateeligibilityAsync.fulfilled, (state, action) => {
        const updatedeligibility = action.payload;
        const existingeligibilityIndex = state.eligibilities.findIndex(
          (eligibility) => eligibility.id === updatedeligibility.id
        );
        if (existingeligibilityIndex !== -1) {
          state.eligibilities[existingeligibilityIndex] = updatedeligibility;
        }
      })
      .addCase(deleteeligibilityAsync.fulfilled, (state, action) => {
        const deletedeligibilityId = action.payload;
        state.eligibilities = state.eligibilities.filter((eligibility) => eligibility.id !== deletedeligibilityId);
      });
  },
});

export const { setPageNo, setFilteredEligibilities, createEligibility, setOpenCheckEligibilityModal } =
  eligibilitieslice.actions;

export default eligibilitieslice.reducer;
