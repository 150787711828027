import { ArcElement, Chart } from "chart.js";
import DashboardGaugeChart from "components/Charts/GaugeCharts/DashboardGaugeChart";
import { formatNumber } from "utils";

const AppealStats = (props) => {
  Chart.register(ArcElement);
  const { approvedAppeals, pendingAppeals } = props;

  const totalAppeals = approvedAppeals + pendingAppeals;
  const differencePercentange = (approvedAppeals / totalAppeals) * 100;

  const pendingAppealsColor = "#E6E8EF";
  const approvedAppealsColor = "#7736F3";

  const style = getComputedStyle(document.body);
  const cardBgColor = style.getPropertyValue("--card-bg-color");

  const gaugeChartText = {
    id: "gaugeChartText",
    afterDatasetsDraw: (chart) => {
      const { ctx } = chart;

      ctx.save();

      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yCoor = chart.getDatasetMeta(0).data[0].y;

      const textToShow = `${approvedAppeals > 0 ? "+" : ""}${Math.ceil(differencePercentange) || 0}%`;

      ctx.beginPath();

      // Draw the purple circle
      const circleRadius = 45;
      ctx.arc(xCoor, yCoor, circleRadius, 0, 2 * Math.PI);
      ctx.fillStyle = approvedAppealsColor;
      ctx.fill();

      // Draw the label text
      ctx.textAlign = "center";
      ctx.fillStyle = "white";
      ctx.font = '22px "Helvetica Neue", Helvetica, Arial, sans-serif';
      ctx.fillText(textToShow, xCoor, yCoor);

      ctx.restore();
    },
  };

  const data = {
    datasets: [
      {
        data: [approvedAppeals || 0, pendingAppeals || 1],
        backgroundColor: [approvedAppealsColor, pendingAppealsColor],
        hoverBorderWidth: 0,
        borderColor: cardBgColor,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "85%",
    rotation: -115,
    circumference: 225,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const plugins = [gaugeChartText];

  return (
    <div className="appealStats">
      <div className="d-flex justify-content-between">
        <span className="custom-ellipsis d-block mxw-60">Total Number of Appeals</span>
        <span className="linkedText custom-ellipsis d-block">See Details</span>
      </div>
      <span className="d-block fs-50">{formatNumber(totalAppeals)}</span>

      <div className="appealStats-chartContainer">
        <DashboardGaugeChart
          dataKey={JSON.stringify(approvedAppeals + pendingAppeals)}
          data={data}
          options={options}
          plugins={plugins}
          numberOfSplits={8}
          cssClass={"appealStats-chart"}
        />
        <div className="d-flex justify-content-between px-4">
          <div className="appealStats-chartLabels">
            <span style={{ backgroundColor: approvedAppealsColor }} className="color-identifier" />
            <span>Approved</span>
          </div>
          <div className="appealStats-chartLabels">
            <span style={{ backgroundColor: pendingAppealsColor }} className="color-identifier" />
            <span>Pending</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppealStats;
