import { INSURANCE_PROVIDER, POS_OPT, PROC_CODE_OPT } from "constant";

export const formatClaimObject = (user, setting) => {
  let modifiedObj = {};
  const claimObj = user[0];

  // When Action Click From Claim Tab
  if (claimObj.employeeID) {
    const proc_array = claimObj.proc_array.map((m) => ({
      ...m,
      proc_desc: PROC_CODE_OPT.find((f) => f.value === m.proc_code)?.label,
      posDesc: POS_OPT.find((f) => f.value === m.place_of_service)?.label,
      id: "prefix_" + Math.random().toString(36).substr(2, 9),
    }));

    return {
      claimObj,
      proc_array,
    };
  }

  // When Create Action Click from Patient tab
  for (const key in claimObj) {
    if (claimObj.hasOwnProperty(key)) {
      if (key === "state") {
        modifiedObj["pat_state"] = claimObj[key];
      }
      if (key === "id") {
        modifiedObj["employeeID"] = claimObj[key];
      }
      if (key === "insuranceCompany") {
        modifiedObj["payerid"] = claimObj[key];
        modifiedObj["payer_name"] = INSURANCE_PROVIDER.find((f) => f.value === claimObj[key])?.label || "";
      }
      if (key === "medicalNo") {
        modifiedObj["ins_number"] = claimObj[key];
      }
      if (key === "firstName") {
        modifiedObj["pat_name_f"] = claimObj[key];
        modifiedObj["ins_name_f"] = claimObj[key];
      }
      if (key === "lastName") {
        modifiedObj["pat_name_l"] = claimObj[key];
        modifiedObj["ins_name_l"] = claimObj[key];
      }
      if (key === "middleName") {
        modifiedObj["pat_name_m"] = claimObj[key];
        modifiedObj["ins_name_m"] = claimObj[key];
      }
      if (key === "dob") {
        modifiedObj["pat_dob"] = claimObj[key];
        modifiedObj["ins_dob"] = claimObj[key];
      }

      if (key === "city") {
        modifiedObj["pat_city"] = claimObj[key];
        modifiedObj["ins_city"] = claimObj[key];
      }
      if (key === "state") {
        modifiedObj["pat_state"] = claimObj[key];
        modifiedObj["ins_state"] = claimObj[key];
      }
      if (key === "zip") {
        modifiedObj["pat_zip"] = claimObj[key];
        modifiedObj["ins_zip"] = claimObj[key];
      }
      if (key === "street") {
        modifiedObj["pat_addr_1"] = claimObj[key];
        modifiedObj["ins_addr_1"] = claimObj[key];
      }
      if (key === "street2") {
        modifiedObj["pat_addr_2"] = claimObj[key];
        modifiedObj["ins_addr_2"] = claimObj[key];
      }
      if (key === "sex") {
        modifiedObj["pat_sex"] = claimObj[key];
        modifiedObj["ins_sex"] = claimObj[key];
      }
      if (key === "phoneNumber") {
        modifiedObj["pat_phone"] = claimObj[key];
      }
      if (key === "email") {
        modifiedObj["pat_email"] = claimObj[key];
      }
      if (key === "providerID" || key === "refProviderID" || key === "ordProviderID") {
        modifiedObj["provider"] = {
          prov_id: claimObj.providerID,
          prov_name: claimObj.providerName,
          prov_name_f: claimObj.providerFirstName,
          prov_name_l: claimObj.providerLastName,
          prov_name_m: claimObj.providerMidName,
          prov_npi: claimObj.providerNpi,
          prov_taxid: claimObj.providerTaxId,
          ref_id: claimObj.refProviderID,
          ref_npi: claimObj.refProviderNpi,
          ref_name: claimObj.refProviderName,
          ref_name_f: claimObj.refProviderFirstName,
          ref_name_l: claimObj.refProviderLastName,
          ref_name_m: claimObj.refProviderMiddleName,
          ord_prov_id: claimObj.ordProviderID,
          ord_prov_npi: claimObj.ordProviderNpi,
          ord_prov_name: claimObj.ordProviderName,
          ord_prov_name_f: claimObj.ordProviderFirstName,
          ord_prov_name_l: claimObj.ordProviderLastName,
          ord_prov_name_m: claimObj.ordProviderMiddleName,
        };
      }
      if (key === "schrID") {
        modifiedObj["pcn"] = claimObj[key];
      }
    }
  }
  if (setting) {
    modifiedObj["bill_name"] = setting.name;
    modifiedObj["bill_npi"] = setting.npi;
    modifiedObj["bill_phone"] = setting.phoneNumber;
    modifiedObj["bill_state"] = setting.state;
    modifiedObj["bill_addr_1"] = setting.street;
    modifiedObj["bill_addr_2"] = setting.street2;
    modifiedObj["bill_city"] = setting.city;
    modifiedObj["bill_taxid"] = setting.taxid;
    modifiedObj["bill_taxid_type"] = setting.taxonomyType;
    modifiedObj["bill_zip"] = setting.zip;
    modifiedObj["bill_id"] = setting.bill_id;
  }
  if (claimObj) {
    modifiedObj["insuranceDetails"] = claimObj.insuranceDetails;
  }

  return {
    claimObj: modifiedObj,
    proc_array: [],
  };
};
