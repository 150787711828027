import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { formatLocations } from "../../../utils";
import { LOCATION, LOCATION_FECTH, USER_LOCATION_FECTH } from "./locationsConstants";
// Async thunk to fetch all locations
export const fetchAllLocations = createAsyncThunk(LOCATION_FECTH, async (param, { getState }) => {
  const clients = getState().clients.clients;

  const models = await api.getLocations(param);
  return formatLocations(models, clients);
});

//Async thunk to Fetch user location
export const fetchUserLocation = createAsyncThunk(USER_LOCATION_FECTH, async (param, { getState }) => {
  const location = await api.getUserSite();
  return location;
});

// Async thunk to create a location
export const createLocationAsync = createAsyncThunk("location/createLocation", async (location) => {
  const response = await api.newlocation(location);
  return response;
});

// Async thunk to update a location
export const updateLocationAsync = createAsyncThunk(
  "location/updateLocation",
  async ({ locationId, updatedlocation }) => {
    const response = await api.updateLocation(locationId, updatedlocation);
    return response;
  }
);

// Async thunk to delete a location
export const deleteLocationAsync = createAsyncThunk("location/deleteLocation", async (locationId) => {
  const response = await api.deleteLocation(locationId);
  return response;
});

const locationSlice = createSlice({
  name: LOCATION,
  initialState: {
    locations: [],
    userLocation: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
      })
      .addCase(createLocationAsync.fulfilled, (state, action) => {
        state.locations.push(action.payload);
      })
      .addCase(updateLocationAsync.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
        if (existinglocationIndex !== -1) {
          state.locations[existinglocationIndex] = updatedlocation;
        }
      })
      .addCase(deleteLocationAsync.fulfilled, (state, action) => {
        const deletedlocationId = action.payload;
        state.locations = state.locations.filter((location) => location.id !== deletedlocationId);
      })
      .addCase(fetchUserLocation.fulfilled, (state, action) => {
        state.locations = [action.payload];
        state.userLocation = action.payload;
      });
  },
});

export default locationSlice.reducer;
