import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { formatNumber,formatNumberWithDecimal } from "utils";
import api from "../../../../api";
import { currencyformatter} from "../../../../constant";

const UnpaidClaimStats = () => {
  const [unPiadData, setUnPaidData] = useState([]);
  const [data, setData] = useState({ datasets: [] });
  const [totalClaims, setTotalClaims] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const UNPAID_CHART_COLOR = {
    "Current": "#A52A2A",
    "30 days": "#7736F3",
    "60 days": "#A378F3",
    "90 days": "#9CF9D5",
  };

  const loadData = async () => {
    const items = await api.getUnpaidClaimsInBetween();
   
    setUnPaidData(items);
    if (items.length > 0) {
      setTotalClaims(items?.reduce((val, obj) => val + parseFloat(obj.count), 0));
      setTotalAmount(items?.reduce((val, obj) => val + parseFloat(obj.total_charge), 0));
      setData({
        datasets: [
          {
            data: items.map((claim) => claim.count) || 1,
            backgroundColor: items.map((claim) => claim.color || UNPAID_CHART_COLOR[claim.interval]) || "gray",
            hoverBackgroundColor: items.map((claim) => claim.color || UNPAID_CHART_COLOR[claim.interval]) || "gray",
            borderWidth: 0,
            weight: 0.1,
          },
        ],
      });
    }
  };
  useEffect(async () => {
    await loadData();
  }, []);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 70, // inner hole size
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  return (
    <div className="unpaidClaimStats">
      <div className="d-flex justify-content-between">
        <span>Claims Yet Not Paid</span>
        <Link
          className="linkedText"
          to={{
            pathname: "/admin/employeeclaim",
            state: {
              term: "status",
              name: "unpaid",
            },
          }}
        >
          See Details
        </Link>
      </div>
      <span className="fs-40">{formatNumber(totalClaims)}</span>

      <div className="d-flex justify-content-between">
        <div className="w-50 unpaidClaimPieGraph">
          <Doughnut data={data} options={chartOptions} />
        </div>
        <div className="unpaidClaimGraph-centerRight">
          {unPiadData.length > 0 &&
            unPiadData.map((claim, i) => (
              <div className="unpaidClaimStat" key={i}>
                <span className="color-identifier" style={{ backgroundColor: `${claim.color}` }} />
                <div className="unpaidClaimGraphAmmounts">
                  <span className="unpaidAmount">{claim.count}</span>
                  <span className="unpaidPay">({currencyformatter.format(claim.total_charge || 0)})</span>
                </div>
                <span>{claim.interval}</span>
              </div>
            ))}
        </div>
      </div>

      <div className="unpaidClaimStats-bottom">
        <span>Amount</span>
        <span className="fs-45">{currencyformatter.format(totalAmount)}</span>
      </div>
    </div>
  );
};

export default UnpaidClaimStats;
