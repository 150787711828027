import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api";
import { CLAIMS_STATS_DETAILS, PAID_CLAIM_SPANS_FORMAT, ORDER_BY_DIRECTION, CONFIG } from "constant";
import moment from "moment";
import { userCompanyID } from "utils";
import CONSTANT from "./dashboardConstant";

export const filterPaidClaimsAsync = createAsyncThunk(CONSTANT.FILTER_PAID_CLAIMS, async (obj) => {
  const objToSend = {
    pid: obj.pid || "",
    date: moment(obj.date).format(PAID_CLAIM_SPANS_FORMAT[obj.selectedSpan]),
  };
  const res = await api.filterPaidClaims(objToSend);
  return res;
});

export const filterMonthlyClaimsAsync = createAsyncThunk(CONSTANT.FILTER_MONTHLY_CLAIMS, async (obj) => {
  const objToSend = {
    type: "stats",
    pid: obj.pid || "",
    date: moment(obj.date).format(PAID_CLAIM_SPANS_FORMAT[obj.selectedSpan]),
  };
  const res = await api.filterPaidClaims(objToSend);
  return res;
});

export const getLastestDeniedRejectedClaimsAsync = createAsyncThunk(
  CONSTANT.LATEST_UNAPPROVED_CLAIMS,
  async (filterParams) => {
    let params = {
      status: filterParams.type,
      orderBy: "updatedAt",
      orderByDirection: ORDER_BY_DIRECTION.DESCEDNING,
      limit: 10,
    };

    if (CONFIG.isLabType) {
      Object.assign(params, { clientID: userCompanyID.get() });
    }
    const deniedClaimsRes = await api.getClaimList(params);
    
    return deniedClaimsRes.rows;
  }
);

const employeeSlice = createSlice({
  name: CONSTANT.STATS_REDUCER,
  initialState: {
    paidClaims: CLAIMS_STATS_DETAILS,
    monthlyClaims: CLAIMS_STATS_DETAILS,
    latestUnapprovedClaims: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(filterPaidClaimsAsync.fulfilled, (state, action) => {
        const { selectedSpan, date, pid } = action.meta.arg;
        state.paidClaims = {
          selectedSpan,
          date,
          payerId: pid,
          ...action.payload,
        };
      })
      .addCase(filterMonthlyClaimsAsync.fulfilled, (state, action) => {
        const { selectedSpan, date, pid } = action.meta.arg;
        state.monthlyClaims = {
          selectedSpan,
          date,
          payerId: pid,
          ...action.payload,
        };
      })
      .addCase(getLastestDeniedRejectedClaimsAsync.fulfilled, (state, action) => {
        state.latestUnapprovedClaims = [...action.payload];
      });
  },
});

export default employeeSlice.reducer;
