import RadioButtonOptions from "components/RadioButton/RadioButtonOptions";
import MainTable from "components/Table/MainTable";
import { CLAIM_STATUS_DETAILS_VIEW_AS, CLAIM_STATUS_DETAILS_VIEW_AS_OPTS, INSURANCE_PROVIDER } from "constant";
import { CLAIM_STATUS_DETAILS_VIEW_TABLE } from "constants/personalization";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import Select from "react-select";
import { getDifferentColors } from "utils";

const SimilarStatusClaimsModal = (props) => {
  const { handleClose, status, statsData = [] } = props;
  const [stats, setStats] = useState([]);
  const [filteredStats, setFilteredStats] = useState([]);
  const [viewAs, setViewAs] = useState(CLAIM_STATUS_DETAILS_VIEW_AS_OPTS.chart);
  const [filter, setFilter] = useState({ payeridsArr: [] });
  const [chartsData, setChartsData] = useState(null);

  useEffect(() => {
    const statsToDisplay = [];
    const availablePayerIds = {};
    let indexCounter = 0;

    [...statsData].forEach((obj) => {
      const stat = { ...obj };
      const payerid = stat.payerid.toString();
      Object.assign(stat, {
        payerName: INSURANCE_PROVIDER.find((ins) => ins.value === payerid).label,
      });

      if (isNaN(availablePayerIds[payerid])) {
        Object.assign(availablePayerIds, {
          [payerid]: indexCounter,
        });
        statsToDisplay.push(stat);
        indexCounter++;
      } else {
        const updatedStat = statsToDisplay[availablePayerIds[payerid]];
        updatedStat.ttlcharge += stat.ttlcharge;
        updatedStat.paidAmount += stat.paidAmount;
        updatedStat.count = parseInt(updatedStat.count) + parseInt(stat.count);

        statsToDisplay.splice(availablePayerIds[payerid], 1, updatedStat);
      }
    });

    setStats(statsToDisplay || []);
    setFilteredStats(statsToDisplay || []);
  }, [statsData]);

  useEffect(() => {
    formatChartsData();
  }, [stats]);

  const formatChartsData = () => {
    const tempChartsData = {};
    stats.map((stat, i) => {
      return Object.assign(tempChartsData, {
        [stat.payerid]: {
          label: stat.payerName,
          data: parseInt(stat.count),
          backgroundColor: getDifferentColors(i + 1, stats.length, 0.7),
          borderColor: getDifferentColors(i + 1, stats.length, 1),
        },
      });
    });
    setChartsData(tempChartsData);
  };

  useEffect(() => {
    if (!stats.length > 0) return;
    let filteredData = [];
    if (filter.payeridsArr.length > 0) {
      filteredData = [...stats].filter((stat) => filter.payeridsArr.includes(stat.payerid));
    } else {
      filteredData = [...stats];
    }
    setFilteredStats(filteredData);
  }, [filter]);

  const data = {
    labels: filteredStats.map((stat) => chartsData[stat.payerid]?.label) || [],
    datasets: [
      {
        data: filteredStats.map((stat) => chartsData[stat.payerid]?.data) || [],
        backgroundColor: filteredStats.map((stat) => chartsData[stat.payerid]?.backgroundColor) || [],
        borderWidth: 1,
        borderColor: filteredStats.map((stat) => chartsData[stat.payerid]?.borderColor) || [],
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false, // Allow the chart to adjust its size
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={handleClose} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          {status} Claim Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="allowVerticalScroll" style={{ paddingTop: 0 }}>
        <RadioButtonOptions
          cssClass={"viewAs_claimStatusDetailsModal"}
          label={"View as: "}
          options={CLAIM_STATUS_DETAILS_VIEW_AS}
          name={`CLAIM_STATUS_DETAILS_VIEW_AS`}
          checkedVal={viewAs}
          handleChange={(e, obj) => e.target.checked && setViewAs(obj.value)}
        />
        <div>
          <label className="modalLineHeaders mt-0">Filter By Payer Name:</label>
          <Select
            options={stats.map((e) => {
              return { value: e.payerid, label: e.payerName };
            })}
            blurInputOnSelect={true}
            menuPlacement="auto"
            placeholder={"Select"}
            className="w-100"
            isMulti
            onChange={(e) =>
              setFilter(e ? { ...filter, payeridsArr: e.map((t) => t.value) } : { ...filter, payeridsArr: [] })
            }
          />
        </div>
        {viewAs === CLAIM_STATUS_DETAILS_VIEW_AS_OPTS.chart ? (
          <div className="paidClaimPieGraph">
            <Pie data={data} options={chartOptions} />
          </div>
        ) : (
          <MainTable columns={CLAIM_STATUS_DETAILS_VIEW_TABLE} rows={filteredStats} cssClass={"non-scrollable-table"} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SimilarStatusClaimsModal;
