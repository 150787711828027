import { createSlice } from "@reduxjs/toolkit";
import CONSTANTS from "./generalConstant";

const generalSlice = createSlice({
  name: CONSTANTS.GENERAL,
  initialState: {
    loader: false,
    error: null,
    message: {
      text: "",
      mode: "",
    },
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = Boolean(action.payload);
    },
    showMessage: (state, action) => {
      state.message = {
        text: action.payload?.text || "",
        mode: action.payload?.mode || "",
      };
    },
    setError: (state, action) => {
      state.error = action.payload || null;
    }
  },
});

export const { setLoader, showMessage, setError } = generalSlice.actions;

export default generalSlice.reducer;
