import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import api from "../../../api";
import { setMessage } from "../general/generalAction";
import { setLoader, showMessage } from "../general/generalSlice";
import { CPT_CODE, CPT_CODE_FETCH, CPT_CODE_CREATE, CPT_CODE_UPDATE, CPT_CODE_DELETE } from "./cptCodeConstant";

export const fetchCPTCodeAsync = createAsyncThunk(CPT_CODE_FETCH, async (param, { dispatch }) => {
  dispatch(setLoader(true));
  const response = await api.searchCptCode(param); // Your API call logic here
  dispatch(setLoader(false));
  return response;
});

export const updateCPTCodeAsync = createAsyncThunk(CPT_CODE_UPDATE, async (param, { dispatch }) => {
  dispatch(setLoader(true));
  const response = await api.updateCptCode(param); // Your API call logic here
  dispatch(setLoader(false));
  dispatch(setMessage("CPT Code Update successfully", MESSAGE_MODES.success));
  if (response.status) return param;
  return null;
});
export const createCPTCodeAsync = createAsyncThunk(CPT_CODE_CREATE, async (param, { dispatch }) => {
  dispatch(setLoader(true));
  const response = await api.addCptCode(param); // Your API call logic here
  dispatch(setLoader(false));
  dispatch(setMessage("CPT Code Add Successfully", MESSAGE_MODES.success));

  if (response.rows && response.rows.length > 0) return response.rows[0];
  return null;
});
export const deleteCPTCodeAsync = createAsyncThunk(CPT_CODE_DELETE, async (defaultSetting) => {
  const response = await api.updateCliItem(defaultSetting); // Your API call logic here
  return response;
});

const cptCodeSlice = createSlice({
  name: CPT_CODE,
  initialState: { procedureList: [], openCreateCPTModal: false, selectedCPTItem: null },
  reducers: {
    setProcedureList: (state, action) => {
      state.procedureList = action.payload;
    },
    setOpenCreateCPTModal: (state, action) => {
      state.openCreateCPTModal = action.payload;
    },
    setSelectedCPTItem: (state, action) => {
      state.selectedCPTItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCPTCodeAsync.fulfilled, (state, action) => {
        state.procedureList = action.payload;
      })
      .addCase(createCPTCodeAsync.fulfilled, (state, action) => {
        if (action.payload) state.procedureList.unshift(action.payload);
      })
      .addCase(updateCPTCodeAsync.fulfilled, (state, action) => {
        const updatedCPT = action.payload;
        if (updatedCPT) {
          const existingclientIndex = state.procedureList.findIndex((code) => code.id === updatedCPT.id);
          if (existingclientIndex !== -1) {
            state.procedureList[existingclientIndex] = updatedCPT;
          }
        }
      })
      .addCase(deleteCPTCodeAsync.fulfilled, (state, action) => {
        const deletedId = action.payload;
        state.procedureList = state.procedureList.filter((code) => code.id !== deletedId);
      });
  },
});

export const { setProcedureList, setSelectedCPTItem, setOpenCreateCPTModal } = cptCodeSlice.actions;

export default cptCodeSlice.reducer;
