import React from "react";
import SortIcon from "../../components/SortIcon";

const HeaderItem = (props) => {
  const {
    ItemKey,
    title,
    flipSort,
    aligned,
    sortBy,
    sortDescending,
    cssClass,
    customHeadForm,
    minWidth,
    width,
    draggable,
    innerDivWidth,
    handleDrop,
    handleDragStart,
  } = props;
  return (
    <th
      className={`border-0 ${aligned ?? ""} ${cssClass ?? ""} ${customHeadForm ?? ""}`}
      style={{
        color: sortBy === ItemKey ? "#A72632" : "#231f20",
        fontWeight: "bold",
        maxWidth: width,
        minWidth: minWidth,
        width: width,
      }}
      draggable={draggable}
      onDragStart={(e) => draggable && handleDragStart(e)}
      onDragOver={(e) => draggable && e.preventDefault()}
      onDrop={(e) => draggable && handleDrop(e)}
    >
      <div
        className={`${aligned}`}
        style={{
          width: innerDivWidth,
        }}
      >
        <span title={title}>{title}</span>
        {flipSort && (
          <SortIcon
            sortName={ItemKey}
            sortBy={sortBy}
            sortDescending={sortDescending}
            handleClick={() => flipSort(ItemKey)}
          />
        )}
      </div>
    </th>
  );
};

export default HeaderItem;
