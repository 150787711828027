import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import authReducer from "./features/authentication/authenticationSlice";
import checkEligibilityReducer from "./features/checkEligibility/checkEligibilitySlice";
import clientsReducer from "./features/clients/clientsSlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import eligibilityFileReducer from "./features/eligibilityFile/eligibilityFileSlice";
import employeeClaimSliceReducer from "./features/employeeClaims/employeeClaimsSlice";
import employeesReducer from "./features/employees/employeesSlice";
import generalReducer from "./features/general/generalSlice";
import locationsReducer from "./features/locations/locationsSlice";
import providersReducer from "./features/providers/providersSlice";
import subAgentsReducer from "./features/subAgents/subAgentsSlice";
import usersReducer from "./features/users/userSlice";
import errorMiddleware from "./middlewares/errorMiddleware";
import loaderMiddleware from "./middlewares/loaderMiddleware";
import companySettingReducer from "./features/companySetting/companySettingSlice";
import employeeLogsReducer from "./features/employeeLogs/employeeLogsSlice";
import cptCodeReducer from "./features/cptCodes/cptCodeSlice";

const rootReducer = combineReducers({
  companySetting: companySettingReducer,
  employeeLogs: employeeLogsReducer,
  employees: employeesReducer,
  clients: clientsReducer,
  subAgents: subAgentsReducer,
  locations: locationsReducer,
  eligibilityFiles: eligibilityFileReducer,
  providers: providersReducer,
  employeeClaims: employeeClaimSliceReducer,
  auth: authReducer,
  eligibilities: checkEligibilityReducer,
  dashboard: dashboardReducer,
  general: generalReducer,
  users: usersReducer,
  cptCode: cptCodeReducer,
  // Add other reducers here if needed
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    loaderMiddleware,
    errorMiddleware,
  ],
});

export default store;
