import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import api from "../../../api";
import { CLAIMS_KEYS, CONFIG, EMP_CLAIM_BLANK_OPTS, MESSAGE_MODES, STATS_OBJECT, STATUS_KEY } from "../../../constant";
import graphqlApi from "../../../graphqlApi";
import {
  formatClaims,
  formatEmployeeClaim,
  getFloatVal,
  getIntVal,
  removeKeysFromObject,
  userCompanyID,
} from "../../../utils";
import { setMessage } from "../general/generalAction";
import {
  ASSIGN_CLAIM,
  EMPLOYEE_CLAIM,
  EMPLOYEE_CLAIM_ACKNOWLEGDE,
  EMPLOYEE_CLAIM_CREATE,
  EMPLOYEE_CLAIM_DELETE,
  EMPLOYEE_CLAIM_FETCH,
  EMPLOYEE_CLAIM_UPDATE,
  EMPLOYEE_CLAIM_UPDATE_STATUS,
  GET_CLAIM_STATS,
} from "./employeeClaimsConstant";

// Async thunk to fetch all employeeClaims

export const fetchAllEmployeeClaimsAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_FETCH,
  async ({ dateFilter, page, filter, isNewCall, users }, { getState }) => {
    const params = { ...filter, page };
    const loginUser = getState().auth.user;
    if (CONFIG.isLabType) {
      Object.assign(params, { clientID: userCompanyID.get() });
    }

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start) Object.assign(params, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(params, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }

    if (filter.Blank || filter.Blank?.length > 0) {
      delete params.Blank;
      Object.assign(params, { blanks: filter.Blank.map((element) => EMP_CLAIM_BLANK_OPTS[element]) });
    }

    if (Object.keys(filter).includes("reSubmissionDate")) {
      Object.assign(params, { reSubmissionDate: filter.reSubmissionDate ? "y" : "n" });
    }
    if (Object.keys(filter).includes("assignToMe")) {
      const user = users.find((f) => f.phone_number === loginUser.phone_number);
      Object.assign(params, { assignTo: filter.assignToMe ? user.id : "" });
      if (!filter.assignToMe) delete filter.assignToMe;
    }

    if (isNewCall) {
      Object.assign(params, { page: 1 });
    }

    const res = await api.getClaimList(params);
    return res;
  }
);

//  get Stats Summmary Data From API
export const getStatsSummaryClaimAsync = createAsyncThunk(GET_CLAIM_STATS, async () => {
  let res = await api.getStatsData();
  const ttlSubmitted = res.submitted + res.approved + res.paid + res.partial + res.rejected + res.denied;
  const ttlSubmittedAmt =
    res.submittedAmount +
    res.approvedAmount +
    res.paidAmount +
    res.partialAmount +
    res.rejectedAmount +
    res.deniedAmount;
  const stats = {
    ...res,
    rejectted: res.rejected,
    rejecttedAmount: res.rejectedAmount,
    partialProcessed: res.partial,
    partialProcessedAmount: res.partialAmount,
  };
  return stats;
});

// Async thunk to create a employeeClaim
export const createEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_CREATE,
  async ({ claimSubmission, seqNumber, claimStatus }, { dispatch }) => {
    let res = await graphqlApi.createClaimGQL(claimSubmission, seqNumber, claimStatus);
    if (res) {
      if (claimStatus === "submitted") {
        // downloadDataAsCSV([{ ...claimSubmission, id: res.id }], "employee_Claim");
        const fileName = `claim/claim_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
        const claims = removeKeysFromObject(claimSubmission, CLAIMS_KEYS);
        await api.saveFileOnBucket([{ ...claims, id: res.id, prov_id: "", ref_id: "", ord_prov_id: "" }], fileName);

        dispatch(setMessage("Claim Submitted Successfully", MESSAGE_MODES.success));
      } else {
        dispatch(setMessage("Claim Created Successfully", MESSAGE_MODES.success));
      }
    }
    return res;
  }
);

// Async thunk to update a employeeClaim
export const updateEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_UPDATE,
  async ({ claimSubmission, claimStatus, claimtype }, { getState, dispatch }) => {
    const stats = getState().employeeClaims.statsObj;
    const res = await graphqlApi.updateEmployeeClaimsGQL(claimSubmission);
    if (res.newImage && claimStatus === "submitted") {
      // downloadDataAsCSV([claimSubmission], "employee_Claim");
      const fileName = `${claimtype}/${claimtype}_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      const claims = removeKeysFromObject(claimSubmission, CLAIMS_KEYS);
      await api.saveFileOnBucket([{ ...claims, prov_id: "", ref_id: "", ord_prov_id: "" }], fileName);

      dispatch(setMessage("Claim Submitted Successfully", MESSAGE_MODES.success));
    } else {
      dispatch(setMessage("Claim Updated Successfully", MESSAGE_MODES.success));
    }

    dispatch(employeeClaimSlice.actions.setStatsObj(updateStats(stats, res.newImage, res.oldImage, "MODIFY", false)));

    return [res.newImage];
  }
);

// Async thunk to Bulk Submit  employeeClaims
export const updateBulkSubmitEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_UPDATE,
  async ({ claimSubmissions, claimStatus, claimtype }, { getState, dispatch }) => {
    let stats = getState().employeeClaims.statsObj;
    let user = getState().auth.user;
    let providers = getState().providers.providers;
    const setting = await api.getCompanySetting();

    if (claimStatus === "submitted") {
      let claimSubmitted = [];

      for (const claim of claimSubmissions) {
        if (claim.prov_id && (!claim.provider || !claim.provider?.prov_id)) {
          const findProvider = providers.find((f) => f.id === claim.prov_id);
          if (findProvider) {
            claim.provider = {
              ...(claim.provider && {}),
              prov_npi: findProvider.npi,
              prov_name_f: findProvider.firstName,
              prov_name_l: findProvider.lastName,
              prov_name_m: findProvider.middleName,
              prov_taxid: findProvider.taxid,
            };
          }
        }
        const formatedClaim = formatEmployeeClaim(claim, setting, true);
        const newClaim = {
          ...formatedClaim,
          prov_id: "",
          ref_id: "",
          ord_prov_id: "",
          status: claimStatus,
          isUpdate: true,
          submittedBy: user.sub,
          submittedByName: user.name,
        };
        const claimObj = removeKeysFromObject(newClaim, CLAIMS_KEYS);
        claimSubmitted.push(claimObj);
        stats = updateStats(stats, newClaim, claim, "MODIFY", false);
      }

      // downloadDataAsCSV([claimSubmission], "employee_Claim");
      const fileName = `${claimtype}/${claimtype}_multi_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;

      await api.saveFileOnBucket(claimSubmitted, fileName);
      dispatch(setMessage(`Claim Submitted Successfully`, MESSAGE_MODES.success));
      dispatch(employeeClaimSlice.actions.setStatsObj(stats));
      return claimSubmitted;
    }
  }
);

// Async Thunk to Acknowledge Claim
export const acknowledgeClaimAsync = createAsyncThunk(EMPLOYEE_CLAIM_ACKNOWLEGDE, async ({ data, loginUser }) => {
  const res = await graphqlApi.acknowledgeClaimGQL(data, loginUser);
  return [res];
});

// Async thunk to update a employee Claim Status
export const updateStatusEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_UPDATE_STATUS,
  async ({ claimsList, newDate, newStatus, reason }, { getState, dispatch }) => {
    if (claimsList.length === 0) return;
    const loginUser = getState().auth.user;
    let stats = getState().employeeClaims.statsObj;
    const updatedClaims = [];
    for (let i = 0; i < claimsList.length; i++) {
      const claimToBeUpdated = { ...claimsList[i] };
      claimToBeUpdated.status = newStatus.value;
      claimToBeUpdated.message = reason.replace(/'/g, "") || "";

      const res = await graphqlApi.updateEmployeeClaimStatusWithLogsGQL(claimToBeUpdated, newDate, loginUser);
      stats = updateStats(stats, res.newImage, res.oldImage, "MODIFY", false);
      updatedClaims.push(res.newImage);
    }
    dispatch(employeeClaimSlice.actions.setStatsObj(stats));
    dispatch(setMessage("Claim Status Updated Successfully!", MESSAGE_MODES.success));

    return updatedClaims;
  }
);

// Async thunk to update a employeeClaim
export const assignEmployeeClaimAsync = createAsyncThunk(ASSIGN_CLAIM, async (claimsArr, { dispatch, getState }) => {
  if (claimsArr.length === 0) return;

  const updatedClaims = [];
  for (let i = 0; i < claimsArr.length; i++) {
    const claimObj = claimsArr[i];
    const res = await graphqlApi.updateEmployeeClaimsGQL(claimObj);

    updatedClaims.push(res.newImage);

    const obj = { ...res.newImage };
    const logObj = {
      employeeID: obj.employeeID,
      empID: obj.employeeID,
      orderId: obj.remote_claimid.slice(7),
      medicalNo: obj.ins_number?.toUpperCase(),
      payerid: obj.payerid,
      payerName: obj.payer_name || "",
      locationID: obj.locationID || "",
      clientID: obj.clientID || "",
      subAgentID: obj.subAgentID || "",
      firstName: obj.pat_name_f,
      lastName: obj.pat_name_l,
      dob: obj.pat_dob,
      message: obj.message || "",
      clientName: obj.clientName || "",
      locationName: obj.locationName || "",
      subAgentName: obj.subAgentName || "",
      claimID: obj.id,
      eventType: "isAssigned",
      eventData: "isAssigned",
      message: obj.assign_details?.message || "",
      userName: getState().auth.user.name || "System",
      userID: getState().auth.user.sub || null,
      amount: obj.total_charge,
    };

    await api.saveLogs(logObj);
  }

  const msg =
    "Hello, claims have been assigned to you. Please log into the MedFlow Revenue Management portal to find out more information";
  await api.sendSMSNotification(claimsArr[0]?.assignUserNumber, msg);

  dispatch(
    setMessage(
      `${claimsArr.length} Claim${claimsArr.length === 1 ? "" : "s"} Assigned Successfully!`,
      MESSAGE_MODES.success
    )
  );
  return updatedClaims;
});

// Async thunk to delete a employeeClaim
export const deleteEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_DELETE,
  async ({ userToDelete, empsFilter }, { dispatch }) => {
    const deletedClaimIDs = [];
    const ttl = empsFilter.length;
    if (userToDelete || ttl > 0) {
      if (userToDelete) {
        const res = await graphqlApi.deleteEmployeeClaimGQL(userToDelete.id);
        deletedClaimIDs.push(res);
      } else if (ttl > 0) {
        for (let i = 0; i < ttl; i++) {
          const res = await graphqlApi.deleteEmployeeClaimGQL(empsFilter[i].id);
          deletedClaimIDs.push(res);
        }
      }
    }
    dispatch(setMessage("Claim Deleted Successfully", MESSAGE_MODES.success));
    return deletedClaimIDs;
  }
);

const updateStats = (stats, newImage, oldImage, eventName, isDeleted) => {
  let companyStats = { ...stats };
  try {
    const keyName = STATUS_KEY[newImage.status];
    const amountKey = `${keyName}Amount`;

    const value = getIntVal(companyStats[keyName]);
    const amount = getFloatVal(companyStats[amountKey]);

    // new record inserted then increment the number and amount
    if (eventName === "INSERT") {
      companyStats[keyName] = value + 1;
      companyStats[amountKey] = amount + getFloatVal(newImage.total_charge);
    }
    // when record is deleted then decrement the number and subtract the amount
    else if (isDeleted && value > 0) {
      companyStats[keyName] = value - 1;
      if (amount >= getFloatVal(newImage?.total_charge)) {
        companyStats[amountKey] = amount - getFloatVal(newImage?.total_charge);
      }
    }

    if (eventName === "MODIFY" && !isDeleted && oldImage) {
      const oldKey = STATUS_KEY[oldImage.status];
      const oldAmountKey = `${oldKey}Amount`;

      const oldValue = getIntVal(companyStats[oldKey]);
      const oldAmount = getFloatVal(companyStats[oldAmountKey]);
      const oldClaimAmount = getFloatVal(oldImage.total_charge);
      if (newImage.status === "paid" || newImage.status === "partial") {
        oldClaimAmount = getFloatVal(newImage.paidAmount);
      }
      if (oldValue > 0) {
        companyStats[oldKey] = oldValue - 1;
      }
      if (oldAmount >= oldClaimAmount) {
        companyStats[oldAmountKey] = oldAmount - oldClaimAmount;
      }
    }

    if (eventName === "MODIFY" && !isDeleted && newImage) {
      const oldKey = STATUS_KEY[newImage.status];
      const oldAmountKey = `${oldKey}Amount`;

      const oldValue = getIntVal(companyStats[oldKey]);
      const oldAmount = getFloatVal(companyStats[oldAmountKey]);
      let newClaimAmount = getFloatVal(newImage.total_charge);

      if (newImage.status === "paid" || newImage.status === "partial") {
        newClaimAmount = getFloatVal(newImage.paidAmount);
      }

      companyStats[oldKey] = oldValue + 1;
      companyStats[oldAmountKey] = oldAmount + newClaimAmount;
    }
  } catch (e) {
    console.log("[updateStats]", e);
  }
  return companyStats;
};

const employeeClaimSlice = createSlice({
  name: EMPLOYEE_CLAIM,
  initialState: {
    employeeClaims: [],
    filteredEmployeeClaims: [],
    statsObj: STATS_OBJECT,
    totalRecord: 0,
    currentPage: 1,
    pageNo: 1,
    claimReSubmissionModal: null,
  },
  reducers: {
    setFilteredEmployeeClaims: (state, action) => {
      state.filteredEmployeeClaims = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setStatsObj: (state, action) => {
      state.statsObj = action.payload;
    },
    setClaimReSubmissionModal: (state, action) => {
      state.claimReSubmissionModal = action.payload;
    },
    updateNotes: (state, action) => {
      const data = action.payload;
      const emp = state.employeeClaims.find((f) => f.id === data.id);
      emp["note"] = data.note;

      const existingemployeeIndex = state.employeeClaims.findIndex((employee) => employee.id === emp.id);
      const existingFilteremployeeIndex = state.filteredEmployeeClaims.findIndex((employee) => employee.id === emp.id);
      if (existingemployeeIndex !== -1) {
        state.employeeClaims[existingemployeeIndex] = emp;
      }
      if (existingFilteremployeeIndex !== -1) {
        state.filteredEmployeeClaims[existingFilteremployeeIndex] = emp;
      }
    },
    updateClaimSubs: (state, action) => {
      const emp = formatClaims([action.payload])[0];

      const existingemployeeIndex = state.employeeClaims.findIndex((employee) => employee.id === emp.id);
      const existingFilteremployeeIndex = state.filteredEmployeeClaims.findIndex((employee) => employee.id === emp.id);
      if (existingemployeeIndex !== -1) {
        state.employeeClaims[existingemployeeIndex] = emp;
      }
      if (existingFilteremployeeIndex !== -1) {
        state.filteredEmployeeClaims[existingFilteremployeeIndex] = emp;
      }
    },
    createClaimSubs: (state, action) => {
      if (state.employeeClaims.findIndex((f) => f.id === action.payload.id) === -1) {
        const claim = formatClaims([action.payload]);
        state.employeeClaims.unshift(claim[0]);
        state.filteredEmployeeClaims.unshift(claim[0]);
        state.totalRecord = state.totalRecord + 1;
      }
    },
    deleteClaimSubs: (state, action) => {
      if (state.employeeClaims.findIndex((f) => f.id === action.payload.id) !== -1) {
        const deletedClaim = action.payload;
        state.employeeClaims = state.employeeClaims.filter((employeeClaim) => employeeClaim.id !== deletedClaim.id);
        state.filteredEmployeeClaims = state.filteredEmployeeClaims.filter(
          (employeeClaim) => employeeClaim.id !== deletedClaim.id
        );
        state.totalRecord = state.totalRecord - 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployeeClaimsAsync.fulfilled, (state, action) => {
        const res = action.payload;
        const claims = formatClaims(res.rows);
        const models = [...state.employeeClaims, ...claims];
        if (state.pageNo === 1) {
          state.totalRecord = res.total;
          state.employeeClaims = claims;
          state.filteredEmployeeClaims = claims;
        } else {
          state.employeeClaims = models;
          state.filteredEmployeeClaims = models;
        }

        // setFilteredEmployeeClaims(sortUsers(filterDates(nestedFilter(models, filter), timeFilter), sortBy));
      })
      .addCase(getStatsSummaryClaimAsync.fulfilled, (state, action) => {
        state.statsObj = action.payload;
      })
      .addCase(createEmployeeClaimAsync.fulfilled, (state, action) => {
        if (state.employeeClaims.findIndex((f) => f.id === action.payload.id) === -1) {
          const claim = formatClaims([action.payload]);
          state.employeeClaims.unshift(claim[0]);
          state.filteredEmployeeClaims.unshift(claim[0]);
          state.totalRecord = state.totalRecord + 1;
          state.statsObj = updateStats({ ...state.statsObj }, claim[0], null, "INSERT", false);
        }
      })

      .addCase(deleteEmployeeClaimAsync.fulfilled, (state, action) => {
        const deletedClaims = action.payload;
        const deletedemployeeClaimIds = deletedClaims.map((m) => m.id);
        state.employeeClaims = state.employeeClaims.filter(
          (employeeClaim) => !deletedemployeeClaimIds.includes(employeeClaim.id)
        );
        state.filteredEmployeeClaims = state.filteredEmployeeClaims.filter(
          (employeeClaim) => !deletedemployeeClaimIds.includes(employeeClaim.id)
        );
        state.totalRecord = state.totalRecord - 1;
        for (let i = 0; i < deletedClaims.length; i++) {
          const claim = deletedClaims[i];
          state.statsObj = updateStats({ ...state.statsObj }, claim, null, "", true);
        }
      })
      .addMatcher(
        (action) =>
          [
            updateEmployeeClaimAsync,
            acknowledgeClaimAsync,
            assignEmployeeClaimAsync,
            updateStatusEmployeeClaimAsync,
            updateBulkSubmitEmployeeClaimAsync,
          ].some((thunk) => action.type.startsWith(thunk.fulfilled.type)),
        (state, action) => {
          const claims = formatClaims(action.payload);
          for (let i = 0; i < claims.length; i++) {
            const updatedemployeeClaim = { ...claims[i] };
            const existingemployeeClaimIndex = state.employeeClaims.findIndex(
              (employeeClaim) => employeeClaim.id === updatedemployeeClaim.id
            );
            if (existingemployeeClaimIndex !== -1) {
              state.employeeClaims[existingemployeeClaimIndex] = updatedemployeeClaim;
            }
            const existingfilteredEmployeeClaimIndex = state.filteredEmployeeClaims.findIndex(
              (employeeClaim) => employeeClaim.id === updatedemployeeClaim.id
            );
            if (existingfilteredEmployeeClaimIndex !== -1) {
              state.filteredEmployeeClaims[existingfilteredEmployeeClaimIndex] = updatedemployeeClaim;
            }
          }
        }
      );
  },
});

export const {
  setPageNo,
  setFilteredEmployeeClaims,
  updateNotes,
  setStatsObj,
  updateClaimSubs,
  createClaimSubs,
  deleteClaimSubs,
  setClaimReSubmissionModal,
} = employeeClaimSlice.actions;

export default employeeClaimSlice.reducer;
