import { CONFIG, MESSAGE_MODES } from "constant";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { userCompanyID } from "utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import { selectedLocation } from "store/features/locations/locationsSelectors";
import { downloadDataAsCSV } from "utils";

const EmployeeImportModal = (props) => {
  const { handleClose, handleImport, title, subAgent } = props;
  const dispatch = useDispatch();
  const loginUser = useSelector(selectLoginUser);
  const { locations } = useSelector(selectedLocation);
  const { subAgents } = useSelector(selectedSubAgent);

  const getSelectedCompany = () => {
    if (loginUser?.isSubAgent() && !subAgents) {
      return userCompanyID.get();
    } else if (loginUser?.isSite() && subAgents) {
      return userCompanyID.get();
    }
    if ((subAgent ? locations : subAgents).length === 1) {
      return (subAgent ? locations : subAgents)[0].id;
    }

    return "";
  };

  const handleDownloadSample = async () => {
    await downloadDataAsCSV(
      [
        {
          name: "Clark, Paul",
          gender: "M",
          dob: "09/06/1975",
          address: "1249 Abc Town",
          address2: "",
          city: "Clifornia",
          state: "CF",
          zip: "20657",
          country: "United State",
          phoneNumber: "+18189750320",
          email: "test@yopmail.com",
          insuranceCompany: "01192",
          medicalNo: "6230df8dj26",
          groupId: "",
          providerCode: "32",
          accountNo: "9289293",
        },
      ],
      "Patient Import Sample"
    );
  };
  const [selectedCompany, setSelectedCompany] = useState(getSelectedCompany());

  const handleOnDrop = (data) => {
    if (!selectedCompany) {
      dispatch(setMessage("Please select the facility/agent first then import", MESSAGE_MODES.error));
      // return;
    } else {
      handleImport(data, selectedCompany);
    }
  };
  const handleOnError = (err) => {
    console.log({ err });
  };

  console.log("selectedCompany", selectedCompany);

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal show animation={true} backdrop="static" onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          {CONFIG.isLabType && (
            <div
              style={{
                textAlign: "right",
                color: "#42cef5",
                textAlign: "center",
                cursor: "Pointer",
              }}
              onClick={() => handleDownloadSample()}
            >
              Download Upload Template
            </div>
          )}
          {(loginUser?.isAdmin() || loginUser?.isClient() || loginUser?.isSite()) && !CONFIG.isLabType && (
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">{subAgent ? "Facility/Agent" : "Sub Agents"}</label>
              <Select
                options={subAgent ? locations : subAgents}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                value={
                  selectedCompany ? (subAgent ? locations : subAgents).find((f) => f.id === selectedCompany) : null
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                blurInputOnSelect={true}
                menuPlacement="auto"
                placeholder={subAgent ? "Facility/Agent" : "Select Sub Agent"}
                className="w-100"
                onChange={(e) => setSelectedCompany(e.id)}
              />
            </div>
          )}
          {selectedCompany && (
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              config={{
                header: true,
                skipEmptyLines: true,
              }}
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeImportModal;
