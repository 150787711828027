import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONFIG } from "constant";
import api from "../../../api";
import { emailMsgToCreatedMember, formatEmployeesData, patientDataInLocalStorage } from "../../../utils";
import {
  EMPLOYEE,
  EMPLOYEE_CREATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_FETCH,
  EMPLOYEE_FETCH_RECORD,
  EMPLPLOYEE_UPDATE,
} from "./employeesConstant";
import { setMessage } from "../general/generalAction";
import { t } from "stringConstants";
import { MESSAGE_MODES } from "constant";
import { EmptyUserObj } from "constant";
// Async thunk to fetch all employees
export const fetchAllEmployees = createAsyncThunk(EMPLOYEE_FETCH, async (_, { getState }) => {
  const response = await api.getEmployees();

  const [subAgents, locations, clients, providers] = [
    getState().subAgents.subAgents,
    getState().locations.locations,
    getState().clients.clients,
    getState().providers.providers,
  ];

  const emps = formatEmployeesData(response, subAgents || [], locations || [], clients || [], providers || []);
  return emps;
});
// External email Send Function

const sendEmailToMember = async (newUser) => {
  try {
    const message = emailMsgToCreatedMember(newUser.firstName, newUser.medicalNo, newUser.password);

    await api.sendEmail([{ email: newUser.email, subject: "SafeCamp LTC Team", msg: message }]);
  } catch (error) {
    console.log("ERROR createUser: ", error);
  }
};

// Async thunk to create a employee
export const createEmployeeAsync = createAsyncThunk(EMPLOYEE_CREATE, async (employee, { getState, dispatch }) => {
  const user = getState().auth.user;
  const result = await api.newEmployee(employee, null, user);
  if (result && employee.password) {
    await api.createSystemUser(employee, result);
    await sendEmailToMember(employee);
  }

  if (result.res) {
    await api.checkManualBulkEligibility([result.res], user);
  }

  const [subAgents, locations, clients, providers] = [
    getState().subAgents.subAgents,
    getState().locations.locations,
    getState().clients.clients,
    getState().providers.providers,
  ];

  const emps = formatEmployeesData([result.res], subAgents || [], locations || [], clients || [], providers || []);
  dispatch(setMessage(t("clientCreatedSuccessfully"), MESSAGE_MODES.success));
  return emps[0];
});

//Async thunk to Fetch user employee
export const fetchEmployeeRecord = createAsyncThunk(EMPLOYEE_FETCH_RECORD, async (user) => {
  const item = await api.getLoggedInUserEmployeeID(null, user.preferred_username);

  return item;
});

// Async thunk to update a employee
export const updateEmployeeAsync = createAsyncThunk(EMPLPLOYEE_UPDATE, async (employee, { getState, dispatch }) => {
  const user = getState().auth.user;
  const response = await api.updateEmployee(employee, user);

  const [subAgents, locations, clients, providers] = [
    getState().subAgents.subAgents,
    getState().locations.locations,
    getState().clients.clients,
    getState().providers.providers,
  ];
  const emps = formatEmployeesData([response.res], subAgents || [], locations || [], clients || [], providers || []);

  if (response && employee.password) {
    await api.createSystemUser(employee, result);
    await sendEmailToMember(employee);
  }

  if (!employee.onAlert) dispatch(setMessage(t("clientUpdatedSuccessfully"), MESSAGE_MODES.success));

  return emps[0];
});

// Async thunk to delete a employee
export const deleteEmployeeAsync = createAsyncThunk(EMPLOYEE_DELETE, async (employeeId, { getState }) => {
  const user = getState().auth.user;
  const employees = getState().employees.employees;
  const emp = employees.find((f) => f.id === employeeId);
  const response = await api.deleteEmployee(employeeId, user);
  if (emp.loginID && !CONFIG.isLabType) await api.deleteUser(emp.loginID);
  return employeeId;
});

const employeeSlice = createSlice({
  name: EMPLOYEE,
  initialState: {
    employees: [],
    filteredEmployees: [],
    employeeRecord: null,
    openCreator: false,
    newUser: EmptyUserObj,
  },
  reducers: {
    setFilteredEmployees: (state, action) => {
      state.filteredEmployees = action.payload;
    },
    setOpenCreator: (state, action) => {
      state.openCreator = action.payload;
    },
    setNewUser: (state, action) => {
      state.newUser = action.payload;
    },
    updateTestAvailableQty: (state, action) => {
      const updateEmps = state.employees.map((m) => {
        if (action.payload.Ids.includes(m.id)) {
          return { ...m, testAvailable: action.payload.testQty };
        }
        return { ...m };
      });
      const filterUpdateEmps = state.filteredEmployees.map((m) => {
        if (action.payload.Ids.includes(m.id)) {
          return { ...m, testAvailable: action.payload.testQty };
        }
        return { ...m };
      });
      state.employees = updateEmps;
      state.filteredEmployees = filterUpdateEmps;
    },
    updateNotes: (state, action) => {
      const data = action.payload;
      const emp = state.employees.find((f) => f.id === data.id);
      emp["note"] = data.note;
      emp.status = data.status;
      if (data.status === "inactive") {
        emp.testAvailable = 0;
      }
      emp.noteAddedBy = data.userName;
      emp.updatedBy = data.userID;
      emp.updatedByName = data.userName;

      const existingemployeeIndex = state.employees.findIndex((employee) => employee.id === emp.id);
      const existingFilteremployeeIndex = state.filteredEmployees.findIndex((employee) => employee.id === emp.id);
      if (existingemployeeIndex !== -1) {
        state.employees[existingemployeeIndex] = emp;
      }
      if (existingFilteremployeeIndex !== -1) {
        state.filteredEmployees[existingFilteremployeeIndex] = emp;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        state.employees = action.payload;
        state.filteredEmployees = action.payload;
      })
      .addCase(createEmployeeAsync.fulfilled, (state, action) => {
        state.employees.unshift(action.payload);
        state.filteredEmployees.unshift(action.payload);
      })
      .addCase(updateEmployeeAsync.fulfilled, (state, action) => {
        const updatedemployee = action.payload;
        const existingemployeeIndex = state.employees.findIndex((employee) => employee.id === updatedemployee.id);
        const existingFilteremployeeIndex = state.filteredEmployees.findIndex(
          (employee) => employee.id === updatedemployee.id
        );
        if (existingemployeeIndex !== -1) {
          state.employees[existingemployeeIndex] = updatedemployee;
        }
        if (existingFilteremployeeIndex !== -1) {
          state.filteredEmployees[existingFilteremployeeIndex] = updatedemployee;
        }
      })
      .addCase(deleteEmployeeAsync.fulfilled, (state, action) => {
        const deletedemployeeId = action.payload;
        state.employees = state.employees.filter((employee) => employee.id !== deletedemployeeId);
        state.filteredEmployees = state.filteredEmployees.filter((emp) => emp.id !== deletedemployeeId);
      })
      .addCase(fetchEmployeeRecord.fulfilled, (state, action) => {
        patientDataInLocalStorage.save(action.payload);
        state.employeeRecord = action.payload;
      });
  },
});

export const { setFilteredEmployees, updateNotes, updateTestAvailableQty, setOpenCreator, setNewUser } =
  employeeSlice.actions;

export default employeeSlice.reducer;
