import api from "api";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorMessage from "components/Message/ErrorMessage";
import PreviousNotes from "components/Note/PreviousNotes";
import RadioButton from "components/RadioButton/RadioButton";
import Loader from "components/Loader/Loader";
import { NOTES_MAX_CHARACTER_LENGTH } from "constant";
import graphqlApi from "graphqlApi";
import moment from "moment";
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";

const NotesModal = (props) => {
  const { data, handleClose, user, emp, order, claim } = props;

  const [notes, setNotes] = useState((emp || claim ? data.note : data.note?.message) || "");
  const [error, setError] = useState("");
  const [status, setStatus] = useState(data.status);
  const [level, setLevel] = useState(emp ? "patient" : "claim");
  const [loading, setLoading] = useState(false);

  const onHandleSave = async () => {
    setLoading(true);
    const obj = {
      id: data.id,
      ...(order && {
        note: {
          message: notes.trim(),
          userID: user.sub,
          userName: user.name,
          createdAt: moment().utc().toISOString(),
        },
      }),
      ...((emp || claim) && { note: notes.trim(), status }),
      userID: user.sub,
      userName: user.name,
      level,
    };
    try {
      if (order) await api.addOrderNote(obj);
      if (emp) await api.addMemberNote(obj, user);
      // if (claim) await api.addClaimNote(obj);
      if (claim) await graphqlApi.addClaimNoteGQL(obj, level);

      setLoading(false);
      handleClose(obj, level);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show backdrop="static" onHide={handleClose} animation={true} size={"lg"} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">
            Notes {data && `for ${data[claim ? "pat_name_f" : "firstName"]} ${data[claim ? "pat_name_l" : "lastName"]}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {emp && (
            <Card>
              <div className="form-group-wrapper mb-0 p-2">
                <label className="modalLineHeaders mt-0 text-capitalize">Status:</label>
                <div className="d-flex justify-content-between w-50 flex-wrap">
                  <RadioButton
                    name="status"
                    id="active"
                    label="Active"
                    checked={status === "active" ? true : false}
                    handleChange={(e) => setStatus(e.target.checked ? "active" : "")}
                  />
                  <RadioButton
                    name="status"
                    id="inactive"
                    checked={status === "inactive" ? true : false}
                    label="InActive"
                    handleChange={(e) => {
                      setStatus(e.target.checked ? "inactive" : "");
                    }}
                  />
                </div>
              </div>
            </Card>
          )}
          {claim && (
            <Card>
              <div className="form-group-wrapper mb-0 p-2">
                <label className="modalLineHeaders mt-0 text-capitalize">Add Notes:</label>
                <div className="d-flex justify-content-between w-50 flex-wrap">
                  <RadioButton
                    name="level"
                    id="patient"
                    label="Patient Level"
                    checked={level === "patient" ? true : false}
                    handleChange={(e) => setLevel(e.target.checked ? "patient" : "")}
                  />
                  <RadioButton
                    name="level"
                    id="claim"
                    checked={level === "claim" ? true : false}
                    label="Claim Level"
                    handleChange={(e) => {
                      setLevel(e.target.checked ? "claim" : "");
                    }}
                  />
                </div>
              </div>
            </Card>
          )}
          <div className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <textarea
                placeholder={"Note!"}
                className="MessageInput"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                maxLength={NOTES_MAX_CHARACTER_LENGTH}
              />
            </div>
          </div>
          <PreviousNotes id={order || claim ? data.employeeID : data.id} user={user} />
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <div className="d-flex">
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={onHandleSave}
            >
              Save
            </Button>
          </div>
          {loading && <Loader />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotesModal;
