import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";

const ErrorMessageModal = (props) => {
  const { error, handleClose } = props;

  return (
    <>
      <Modal show backdrop="static" onHide={handleClose} animation={true} size={"md"} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Error Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{ color: "red" }}>A problem with the code that is used to create the modal dialog </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ErrorMessageModal;
