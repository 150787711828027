import React, { useContext, useEffect, useState } from "react";
import api from "api";
import { Storage } from "aws-amplify";
import EmployeeModal from "components/Employee/EmployeeModal";
import InputField from "components/InputField/InputField";
import InputSelectDropDown from "components/InputField/InputSelectDropDown";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButton from "components/RadioButton/RadioButton";
import { formatClaimObject } from "views/EmployeeClaims/claimFuntions/formatClaim";
import {
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  CONFIRMATION_TYPE,
  DIAGNOSIS_ITEMS,
  DIAGNOSIS_OPTIONS,
  HOSPICE_MODFIRE,
  INSURANCE_PROVIDER,
  INSURANCE_TYPE,
  MESSAGE_MODES,
  PARSE_ALPHA_INTO_NUM,
  PARSE_CLAIM_ERROR_KEY,
  POS_OPT,
  PREV_CLAIM_ACTIONS,
  PROCEDURE_CODE_HEADER,
  PROC_CODE_OPT,
  currencyformatter,
  usersNo,
} from "constant";
import moment from "moment";
import { Button, Card, Col, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select, { components as selectComponents } from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { t } from "stringConstants";
import {
  calcHeight,
  convertToUpper,
  createErrorMessageString,
  emailMsgToCreatedMember,
  formatEmployeeClaim,
  formatZip,
  getValidDep,
  getValidSpaces,
  isValidZipCode,
  parseErrorMessageString,
} from "utils";
import ClaimResolveReasonModal from "views/EmployeeClaims/ClaimResolveReasonModal";
import EmployeePrevClaimsModal from "views/EmployeeClaims/EmployeePrevClaimsModal";
import ViewClaimList from "views/EmployeeClaims/ViewClaimList";
import CheckBoxBar from "components/CheckBox/CheckBoxBar";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import LabInfoModal from "components/Modal/LabInfoModal/LabInfoModal";
import ProcedureModefierModal from "components/Modal/ProcedureModefierModal";
import ProviderModal from "components/Modal/ProviderModal";
import HospiceModifierModal from "components/Modal/Order/HospiceModifierModal";
import ProcedureListView from "components/Modal/Order/components/ProcedureListView";
import { employeesSelector } from "store/features/employees/employeesSelectors";
import { selectedLocation } from "store/features/locations/locationsSelectors";
import { selectedProvider } from "store/features/providers/providersSelectors";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import {
  selectedCompanySetting,
  selectedCompanySettingClia,
} from "store/features/companySetting/companySettingSelectors";
import { selectedClients } from "store/features/clients/clientsSelectors";
import { fetchAllEmployees } from "store/features/employees/employeesSlice";
import { createEmployee, updateEmployee } from "store/features/employees/employeesAction";
import { createEmployeeClaim, updateEmployeeClaim } from "store/features/employeeClaims/employeeClaimsActions";
import { fetchAllEmployeeClaims } from "store/features/employeeClaims/employeeClaimsActions";
import { CapsFirstLetter } from "utils";

const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
  // Custom comparison logic for props
  // Return true if the props are equal, otherwise return false
};

const CreateClaimResubmissionModal = (props) => {
  const { user, handleClose, showPrevClaims } = props;
  const [procedure, setProcedure] = useState(false);
  const [newClaim, setNewClaim] = useState({});
  const [show, setShow] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [editObj, setEditObj] = useState({});
  const [providersOptions, setProvidersOptions] = useState([]);
  const [employeesOpt, setEmployeesOpt] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [prevClaims, setPrevClaims] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState(INSURANCE_TYPE.primary);
  const [employeeData, setEmployeeData] = useState(null);
  const [isHospice, setIsHospice] = useState(null);
  const [hospiceModifer, setHospiceModifier] = useState(false);
  const [openPrevClaimModal, setOpenPrevClaimModal] = useState(false);
  const [eraList, setEraList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [reasonModal, setReasonModal] = useState(false);
  const [showLabInfoModal, setShowLabInfoModal] = useState(false);
  const [providerObj, setProviderObj] = useState(null);
  const [isNotification, setIsNotification] = useState(false);
  const [changePatientData, setChangePatientData] = useState(false);
  const [employeeObjToUpdate, setEmployeeObjToUpdate] = useState(null);
  const [claimList, setClaimList] = useState([]);
  const [updatePatient, setUpdatePatient] = useState(null);

  const dispatch = useDispatch();
  const setting = useSelector(selectedCompanySetting);
  const { clients } = useSelector(selectedClients);
  const { providers } = useSelector(selectedProvider);
  const { locations, userLocation } = useSelector(selectedLocation);
  const { subAgents, userSubAgent } = useSelector(selectedSubAgent);
  const loginUser = useSelector(selectLoginUser);
  const { employees } = useSelector(employeesSelector);
  const { permission } = useSelector(authSelector);
  const cliList = useSelector(selectedCompanySettingClia);

  const handleAddData = (newData) => {
    let newArray = [...dataList];
    newArray = [...newArray].map((obj) => {
      return { ...obj, from_date: newData.from_date };
    });
    newArray.push(newData);
    setDataList(newArray);
    setProcedure(false);
    setEditObj({});
  };

  useEffect(async () => {
    if (newClaim.status && newClaim.status === CLAIM_SUBMIT_STATUS.denied) {
      const era = await api.getClaimERA(newClaim);
      if (era && era.length > 0) {
        setEraList(era);
      }
    }
  }, [newClaim.status]);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    getCliData();
  }, [cliList]);

  useEffect(() => {
    if (
      newClaim.message &&
      (newClaim.status === CLAIM_SUBMIT_STATUS.denied || newClaim.status === CLAIM_SUBMIT_STATUS.rejected)
    ) {
      const res = parseErrorMessageString(newClaim.message);
      if (res && res.length > 0) {
        const errList = [];
        res.forEach((val) => {
          if (Object.keys(newClaim).includes(val.field) && !val.resolve) {
            const findKey = Object.keys(newClaim).find((f) => f === val.field);
            errList.push(findKey);
          } else if ((val.code?.includes("procedure code") || val.key?.includes("procedure code")) && !val.resolve) {
            errList.push("proc_code");
          } else if (
            Object.entries({ ...newClaim }).find(
              ([key, value]) =>
                val.code?.includes(value) ||
                val.code?.includes(key) ||
                val.field?.includes(value) ||
                val.field?.includes(key)
            ) &&
            !val.resolve
          ) {
            const findKey = Object.entries({ ...newClaim }).find(
              ([key, value]) =>
                val.code?.includes(value) ||
                val.code?.includes(key) ||
                val.field?.includes(value) ||
                val.field?.includes(key)
            );

            errList.push(findKey[0]);
          } else if (
            Object.entries({ ...newClaim, ...newClaim.provider }).find(([key, value]) => {
              const keyVlaue = PARSE_CLAIM_ERROR_KEY[key] || key;
              return (
                val.code?.includes(value) ||
                val.code?.includes(keyVlaue) ||
                val.field?.includes(value) ||
                val.field?.includes(keyVlaue)
              );
            }) &&
            !val.resolve
          ) {
            const findKey = Object.entries({ ...newClaim, ...newClaim.provider }).find(([key, value]) => {
              const keyVlaue = PARSE_CLAIM_ERROR_KEY[key] || key;
              return (
                val.code?.includes(value) ||
                val.code?.includes(keyVlaue) ||
                val.field?.includes(value) ||
                val.field?.includes(keyVlaue)
              );
            });

            errList.push(findKey[0]);
          }
        });
        setErrorList([...errList]);
      }
    }
  }, [newClaim.message]);

  useEffect(() => {
    if (newClaim.status) {
      const patientData = Object.keys(newClaim).filter((f) => f.includes("pat_") || f.includes("payer"));
      const dataKey = patientData.find((s) => user[0][s] != newClaim[s]);
      if (dataKey) {
        setIsNotification(true);
      } else {
        setIsNotification(false);
        setChangePatientData(false);
      }
    }
  }, [newClaim]);

  // in case create claim is called from Employee Listing
  useEffect(async () => {
    if (user && user.length > 0) {
      const obj = user[0];
      let empID = obj.id;

      // in user object only employee id is come when called from Claim Listing tab on select any claim
      // check if only employee id is coming get the employee record from employees and set else set the user obj
      if (Object.keys(obj).length === 1) {
        //set employee as selected employee
        const employeeObj = employees.find((f) => f.id === obj.employeeId);
        if (employeeObj) setSelectedEmployee(employeeObj);
        empID = obj.employeeId;
      } else {
        setSelectedEmployee(obj);
      }
      // fetch the previous employee claims
      await getEmployeeClaims(empID);
    }
  }, []);

  const getEmployeeClaims = async (employeeID) => {
    try {
      setLoading(true);
      const res = await api.getEmployeeClaimsAPI(employeeID);
      // show claim selection in case of any previous claims

      if (res && res.length > 0) {
        const listToSave = res.map((claim) => {
          return {
            ...claim,
            proc_array: claim.proc_array.map((proc) => {
              return { ...proc, proc_name: PROC_CODE_OPT.find((obj) => obj.value === proc.proc_code)?.label || "" };
            }),
          };
        });
        setPrevClaims(listToSave);
        setOpenPrevClaimModal(true);
      } else {
        setPrevClaims([]);
      }
    } catch (er) {
      console.log("Error", er);
    } finally {
      setLoading(false);
    }
  };

  const getCliData = async () => {
    if (cliList && cliList.length > 0) {
      const defaultCliaNumber = cliList.find((s) => s.isDefault === "Yes");
      if (defaultCliaNumber.cliCode) {
        setNewClaim((prev) => ({ ...prev, clia_number: defaultCliaNumber?.cliCode }));
      }
    }
  };

  useEffect(() => {
    if (providers.length > 0) {
      let arr = providers.map((m) => ({ ...m, value: m.id, label: m.name }));
      setProvidersOptions(arr);
    }
  }, [providers]);

  const handleConfirm = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) return;
    switch (type) {
      case CONFIRMATION_TYPE.SUBMIT_CLAIM:
        onHandleSave(false, "submitted", null, "claim");
        break;
      case CONFIRMATION_TYPE.RESUBMIT_CLAIM:
        onHandleSave(false, "submitted", null, "reclaim");
        break;
    }
  };

  const formatClaimData = (user) => {
    const { claimObj, proc_array } = formatClaimObject(user, setting);
    setDataList(proc_array);
    setNewClaim((prev) => ({
      ...prev,
      ...claimObj,
      proc_array,
    }));
    if (!claimObj.employeeID) {
      setEmployeeData(claimObj);
    }
  };

  useEffect(() => {
    if (user.length > 0 && !user[0]?.employeeId) {
      formatClaimData(user);
    }

    if (employees.length > 0 && user[0]?.employeeId) {
      const emp = employees.find((f) => f.id === user[0].employeeId);
      if (emp) formatClaimData([emp]);
    }
  }, [user]);

  useEffect(() => {
    if (user.length === 0) setEmployeesOpt(employees);
  }, [employees]);

  const handleAddHospiceModifire = (obj, claimtype, claimStatus) => {
    setNewClaim({ ...obj });
    onHandleSave(false, claimStatus, obj, claimtype);
  };

  useEffect(() => {
    if (insuranceInfo && employeeData) {
      if (insuranceInfo === INSURANCE_TYPE.primary) {
        setNewClaim((prev) => ({
          ...prev,
          payerid: employeeData?.insuranceCompany || "",
          payer_name: employeeData?.insuranceCompanyCode || "",
          ins_number: employeeData?.medicalNo || "",
        }));
      } else {
        setNewClaim((prev) => ({
          ...prev,
          ...newClaim,
          payerid: employeeData.insuranceDetails?.insuranceCompany || "",
          payer_name: employeeData.insuranceDetails?.insuranceCompanyCode || "",
          ins_number: employeeData.insuranceDetails?.medicalNo || "",
        }));
      }
    }
  }, [insuranceInfo, employeeData]);

  const handleCloseReasonModal = (reason) => {
    setReasonModal(false);
    setOpenConfirmation({
      isShow: true,
      message: `Are you sure, you want to Re-Submit Patient Claim?`,
      actionType: CONFIRMATION_TYPE.RESUBMIT_CLAIM,
      title: "Claim Re-Submitted",
    });
    if (reason) {
      setNewClaim({ ...newClaim, message: reason });
    }
  };

  const onHandleSave = async (isNew, claimStatus, claimData, claimtype) => {
    const claim = claimData ? { ...claimData } : { ...newClaim };
    const modifiedObj = claim;
    Object.assign(modifiedObj, {
      ins_name_f: claim.pat_name_f,
      ins_name_l: claim.pat_name_l,
      ins_name_m: claim.pat_name_m,
      ins_sex: claim.pat_sex,
      ins_addr_1: claim.pat_addr_1,
      ins_addr_2: claim.pat_addr_2,
      ins_city: claim.pat_city,
      ins_state: claim.pat_state,
      ins_dob: claim.pat_dob,
      ins_zip: claim.pat_zip,
    });

    const emp = employees.find((f) => f.schrID === claim.pcn);

    if (employeesOpt.length > 0 && !selectedEmployee) {
      setError("Please Select an Employee");
      return;
    }

    if (!newClaim.pat_name_f || !newClaim.pat_name_f.trim()) {
      setError("First Name is required");
      return;
    }
    if (!newClaim.pat_name_l || !newClaim.pat_name_l.trim()) {
      setError("Last Name is required");
      return;
    }
    if (!newClaim.pat_addr_1 || !newClaim.pat_addr_1.trim()) {
      setError("Address is required");
      return;
    }

    if (!newClaim.pat_city || !newClaim.pat_city.trim()) {
      setError("city is required");
      return;
    }
    if (!newClaim.pat_state || !newClaim.pat_state.trim()) {
      setError("state is required");
      return;
    }

    if (!newClaim.payer_name || !newClaim.payer_name.trim()) {
      setError("insurance is required");
      return;
    }

    if (!newClaim.ins_number || !newClaim.ins_number.trim()) {
      setError("Insurance Number is required");
      return;
    }

    if (!newClaim.pat_zip) {
      setError("Zip Code is required");
      return;
    }
    if (!isValidZipCode(newClaim.isoCode || "US", newClaim.pat_zip)) {
      setError("Zip Code is Invalid");
      return;
    }

    if ((claimData && claim?.proc_array?.length === 0) || (!claimData && (dataList == "" || dataList.length === 0))) {
      setError("Please add Procedure");
      return;
    }

    // Need to set a referring provider. Please make this mandatory on all claims going to NON-Medicare
    if (!newClaim.payer_name.toLowerCase().includes("Medicare") && newClaim.provider && !newClaim.provider?.ref_npi) {
      setError("Referring Provider is required");
      return;
    }

    if (!newClaim.clia_number || !newClaim.clia_number.trim()) {
      setError("CLIA Number is required");
      return;
    }
    if (Object.keys(dataList).length > 0 || claim.proc_array.length > 0) {
      const mergedObj = Object.assign(modifiedObj, {
        proc_array: claimData ? claim.proc_array : dataList,
        status: claimStatus || "draft",
        updatedBy: loginUser.sub,
        updatedByName: loginUser.name,
      });

      if (prevClaims && prevClaims.length > 0) {
        if (prevClaims.some((f) => f.from_date_1 === claim?.proc_array[0].from_date)) {
          setError("Date of Service is already assigned to some other claim!");
          return;
        }
      }

      if (claimStatus === CLAIM_SUBMIT_STATUS.denied || claimStatus === CLAIM_SUBMIT_STATUS.rejected) {
        const res = parseErrorMessageString(newClaim.message);
        if (res && res.length > 0 && newClaim.message) {
          const resolveMessage = res.map((val) => {
            if (Object.keys(newClaim).includes(val.field) && !val.resolve) {
              const findKey = Object.keys(newClaim).find((f) => f === val.field);
              if (user[0][findKey] !== mergedObj[findKey]) {
                return { ...val, resolve: "(resolve)" };
              }
            } else if ((val.code?.includes("procedure code") || val.key?.includes("procedure code")) && !val.resolve) {
              if (dataList.some((s) => user[0].proc_array.find((f) => s.proc_code !== f.proc_code))) {
                return { ...val, resolve: "(resolve)" };
              }
            } else if (
              Object.entries({ ...newClaim, ...newClaim.provider }).find(
                ([key, value]) =>
                  val.code?.includes(value) ||
                  val.code?.includes(key) ||
                  val.field?.includes(value) ||
                  val.field?.includes(key)
              ) &&
              !val.resolve
            ) {
              const findKey = Object.entries(newClaim).find(
                ([key, value]) =>
                  val.code?.includes(value) ||
                  val.code?.includes(key) ||
                  val.field?.includes(value) ||
                  val.field?.includes(key)
              );

              if (user[0][findKey] !== mergedObj[findKey]) {
                return { ...val, resolve: "(resolve)" };
              }
            } else if (
              Object.entries({ ...newClaim, ...newClaim.provider }).find(
                ([key, value]) =>
                  val.code?.includes(value) ||
                  val.code?.includes(key) ||
                  val.field?.includes(value) ||
                  val.field?.includes(key)
              ) &&
              !val.resolve
            ) {
              const findKey = Object.entries(newClaim).find(
                ([key, value]) =>
                  val.code?.includes(value) ||
                  val.code?.includes(key) ||
                  val.field?.includes(value) ||
                  val.field?.includes(key)
              );

              if (user[0][findKey] !== mergedObj[findKey]) {
                return { ...val, resolve: "(resolve)" };
              }
            }
          });
          const msg = createErrorMessageString(resolveMessage.filter((f) => f));
          if (msg) mergedObj["message"] = msg;
        }
      }
      setLoading(true);

      let claimRes = null;
      try {
        if (isNew) {
          const setting = await api.getCompanySetting();
          const seqNumber = await api.getEmployeeNo("c", setting.id);

          Object.assign(mergedObj, { remote_claimid: `CL-${setting.code}-${seqNumber.toString().padStart(6, 0)}` });
          const claimSubmission = formatEmployeeClaim(mergedObj, setting);

          await dispatch(createEmployeeClaim(claimSubmission, seqNumber, claimStatus));

          claimRes = true;
        } else {
          if (
            emp &&
            !hospiceModifer &&
            mergedObj.proc_array.every(
              (f) =>
                !HOSPICE_MODFIRE[f.mod1] &&
                !HOSPICE_MODFIRE[f.mod2] &&
                !HOSPICE_MODFIRE[f.mod3] &&
                !HOSPICE_MODFIRE[f.mod4]
            )
          ) {
            // const res = await handleCheckEligibility(emp);
            if (!emp.isHospice) {
              const res = await handleCheckEligibility(emp);
              if (res && res.benefit && res.benefit.length > 0) {
                const IsClaimHospice = res.benefit.find((f) => f.benefit_description.toLowerCase() === "hospice");
                if (IsClaimHospice) {
                  setIsHospice({ newClaim: { ...mergedObj }, claimStatus, claimtype });
                  setLoading(false);
                  return;
                }
              }
            } else {
              setIsHospice({ newClaim: { ...mergedObj }, claimStatus, claimtype });
              setLoading(false);
              return;
            }
          }
          claimRes = await handleUpdateClaim(mergedObj, claimStatus, claimtype);
        }
        setLoading(false);
        handleClose(true);
      } catch (err) {
        console.log("Error:-", err);
      }
    }
  };

  const handleUpdateClaim = async (mergedObj, claimStatus, claimtype) => {
    const claimSubmission = formatEmployeeClaim(mergedObj, setting);
    await dispatch(updateEmployeeClaim(claimSubmission, claimStatus, claimtype));
    if (changePatientData) {
      const res = await api.updateEmployeewithClaims(claimSubmission);
      dispatch(fetchAllEmployees());
    }
    return true;
  };

  const handleCheckEligibility = async (emp) => {
    let response = "";
    if (emp.eligibilityStatus && emp.eligibilityStatus.createdAt.slice(0, 10) === moment().format("YYYY-MM-DD")) {
      const item = emp.eligibilityStatus;
      const data = JSON.parse(item.apiData);
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          const string = await res.Body.text();
          const apidata = JSON.parse(string);
          if (apidata?.elig) {
            response = apidata?.elig || "";
          } else {
            response = await api.checkManualEligibility(emp, loginUser);
          }
        } catch (err) {
          console.log("Error:-", err.message);
          dispatch(setMessage(err.message, MESSAGE_MODES.error));
        }
      }
    } else {
      response = await api.checkManualEligibility(emp, loginUser);
    }

    return response;
  };

  const handleEdit = (data) => {
    setEditObj(data);
    setProcedure(true);
  };

  const handleUpdate = (item) => {
    let newArray = dataList?.map((obj) => {
      if (obj.id === item.id) {
        return item;
      } else {
        return obj;
      }
    });
    newArray = newArray.map((obj) => {
      return { ...obj, from_date: item.from_date };
    });
    setDataList(newArray);
    setEditObj({});
    setProcedure(false);
  };

  const handleDelete = (item) => {
    const newArray = dataList?.filter((obj) => {
      if (obj.id !== item.id) {
        return item;
      }
    });
    setDataList(newArray);
  };

  const handleUpdatePatientAddress = (val, flag) => {
    if (!flag) {
      setNewClaim({ ...newClaim, pat_addr_1: val });
    } else {
      const addressObj = {
        pat_addr_1: val.street,
        pat_country: val.country,
        pat_city: val.city,
        pat_zip: val.zipcode,
        pat_state: val.state,
        isoCode: val.isoCode,
      };
      setNewClaim({ ...newClaim, ...addressObj });
    }
  };
  const handleUpdateInsAddress = (val, flag) => {
    if (!flag) {
      setNewClaim({ ...newClaim, ins_addr_1: val });
    } else {
      const addressObj = {
        ins_addr_1: val.street,
        ins_country: val.country,
        ins_city: val.city,
        ins_zip: val.zipcode,
        ins_state: val.state,
        isoCode: val.isoCode,
      };
      setNewClaim({ ...newClaim, ...addressObj });
    }
  };

  const CustomControlRenderingProv = ({ children, ...props }) => (
    <selectComponents.ValueContainer {...props}>
      <div
        className="customProviderText"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setProviderObj(providers.find((prov) => prov.id === newClaim.provider?.prov_id));
        }}
      >
        {children}
      </div>
    </selectComponents.ValueContainer>
  );

  const componentOptionsRenderingProv = {
    ValueContainer: CustomControlRenderingProv,
  };

  const createMember = async (user) => {
    if (user.isNew) {
      dispatch(createEmployee(user));
    } else {
      let refProvider = null;
      let ordProvider = null;
      let provider = null;
      if (user.referringProvider) {
        refProvider = providers.find((f) => f.id === user.referringProvider);
      }
      if (user.orderingProvider) {
        ordProvider = providers.find((f) => f.id === user.orderingProvider);
      }
      if (user.renderingProvider) {
        provider = providers.find((f) => f.id === user.renderingProvider);
      }
      dispatch(updateEmployee(user));

      if (loginUser?.phone_number === usersNo.ibrahim || CONFIG.isLabType) {
        const claims = await api.getEmployeeClaimsAPI(user.id);
        setUpdatePatient({ user, provider, refProvider, ordProvider });
        setClaimList(
          claims.filter(
            (f) =>
              f.status === CLAIM_SUBMIT_STATUS.denied ||
              f.status === CLAIM_SUBMIT_STATUS.rejected ||
              f.status === CLAIM_SUBMIT_STATUS.draft
          )
        );
        // await api.updateClaims(user, loginUser, provider, refProvider, ordProvider);
      }
    }
    // await appContext.resetEmployees();
  };

  const sendEmailToMember = async (newClaim) => {
    try {
      const message = emailMsgToCreatedMember(newClaim.firstName, newClaim.medicalNo, newClaim.password);

      await api.sendEmail([{ email: newClaim.email, subject: "SafeCamp LTC Team", msg: message }]);
    } catch (error) {
      console.log("ERROR createUser: ", error);
    }
  };
  const formatEmp = (emp) => {
    return {
      ...emp,
      // phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
      gender: emp.sex,
      ...(userSubAgent && {
        subAgentID: userSubAgent.id,
        companyID: userSubAgent.locationID,
        clientID: userSubAgent.clientID,
      }),
    };
  };

  const handleEmployeeSelectionChange = async (e) => {
    await getEmployeeClaims(e.id);

    setSelectedEmployee(e);
    formatClaimData([e]);
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"2x2"} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          {`Claim Info ${newClaim.status ? `(${newClaim.status})` : ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex  justify-content-between">
              <Col md="12">
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <div className="patient-info-title">
                        <h4 className="text-decoration-underline text-black mt-0 section-title">Patient Info</h4>
                        <i
                          className="fas fa-edit cursor-pointer"
                          onClick={() =>
                            setEmployeeObjToUpdate(employees.find((emp) => emp.id === newClaim.employeeID))
                          }
                        />
                      </div>
                      <div>
                        <span
                          className="d-flex flex-column align-items-center text-secondary px-3 linkedText"
                          onClick={() => setShowLabInfoModal(true)}
                        >
                          Lab Info
                        </span>
                        <span className="text-secondary px-3">Patient Account #: {newClaim.pcn}</span>
                      </div>
                      {employeeData?.insuranceDetails?.insuranceCompany && (
                        <div>
                          <Form.Group className="d-flex align-items-end">
                            <Form.Label className="my-0 modalLineHeaders w-100">Choose Insurance Info:</Form.Label>
                            <div className="d-flex justify-content-between">
                              <RadioButton
                                name="showInsurance"
                                id={INSURANCE_TYPE.primary}
                                checked={insuranceInfo === INSURANCE_TYPE.primary ? true : false}
                                label="Primary"
                                handleChange={(e) => setInsuranceInfo(INSURANCE_TYPE.primary)}
                                cssClass={"mx-1"}
                              />
                              <RadioButton
                                id={INSURANCE_TYPE.secondary}
                                checked={insuranceInfo === INSURANCE_TYPE.secondary ? true : false}
                                name="showInsurance"
                                label="Secondary"
                                handleChange={(e) => setInsuranceInfo(INSURANCE_TYPE.secondary)}
                                cssClass={"mx-1"}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      )}
                    </div>
                    <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                      <div className={"crew-profile-info crew-profile-row crew-profile-again"}>
                        {employeesOpt.length > 0 && (
                          <Form.Group className="form-group-wrapper">
                            <Form.Label className="mt-0 text-capitalize">Select Patient</Form.Label>
                            <Select
                              options={employeesOpt}
                              blurInputOnSelect={true}
                              getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                              getOptionValue={(option) => option.id}
                              menuPlacement="auto"
                              autoComplete="none"
                              placeholder="Select Patient"
                              onChange={handleEmployeeSelectionChange}
                              isDisabled={isDisabled}
                            />
                          </Form.Group>
                        )}
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="First Name"
                          value={newClaim.pat_name_f}
                          index="pat_name_f"
                          placeholder="First Name"
                          disabled={isDisabled}
                          errorList={errorList}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_name_f: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Middle Name"
                          value={newClaim.pat_name_m}
                          index="pat_name_m"
                          errorList={errorList}
                          placeholder="Middle Name"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_name_m: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Last Name"
                          value={newClaim.pat_name_l}
                          index="pat_name_l"
                          errorList={errorList}
                          placeholder="Last Name"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_name_l: getValidDep(e.target.value),
                            })
                          }
                        />
                        <InputField
                          type="phone"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Phone"
                          value={newClaim.pat_phone}
                          index="pat_phone"
                          errorList={errorList}
                          maxLength={65}
                          placeholder="Phone"
                          disabled={isDisabled}
                          handleChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              pat_phone: e,
                            })
                          }
                        />
                        <div className="d-flex personal-info-cols-divide column-gap-10">
                          <div className="updated-middle-box w-100 first-col">
                            <InputField
                              type="datePicker"
                              inputStyle="w-100 orderTestDob"
                              labelStyle="text-capitalize"
                              label="DOB"
                              value={newClaim.pat_dob}
                              index="pat_dob"
                              errorList={errorList}
                              placeholder="DOB"
                              disabled={isDisabled}
                              handleChange={(e) =>
                                setNewClaim({
                                  ...newClaim,
                                  pat_dob:
                                    moment(e).format("YYYY-MM-DD") != "Invalid date"
                                      ? moment(e).format("YYYY-MM-DD")
                                      : "",
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="crew-profile-info crew-profile-row crew-profile-again">
                        <div>
                          <Form.Label className="mt-0 text-capitalize">Gender</Form.Label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="gender"
                              id="male"
                              label="Male"
                              checked={newClaim?.pat_sex === "M" ? true : false}
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewClaim({
                                  ...newClaim,
                                  pat_sex: e.target.checked ? "M" : "",
                                })
                              }
                            />
                            <RadioButton
                              name="gender"
                              id="female"
                              checked={newClaim?.pat_sex === "F" ? true : false}
                              label="Female"
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewClaim({
                                  ...newClaim,
                                  pat_sex: e.target.checked ? "F" : "",
                                })
                              }
                            />
                            <RadioButton
                              id="other"
                              checked={newClaim?.pat_sex === "X" ? true : false}
                              name="gender"
                              label="Not Specified"
                              handleChange={(e) =>
                                !isDisabled &&
                                setNewClaim({
                                  ...newClaim,
                                  pat_sex: e.target.checked ? "X" : "",
                                })
                              }
                            />
                          </div>
                        </div>
                        <InputField
                          type="address"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Address"
                          value={newClaim.pat_addr_1}
                          index="pat_addr_1"
                          errorList={errorList}
                          maxLength={65}
                          placeholder="Address"
                          disabled={isDisabled}
                          handleChange={(val, flag) => handleUpdatePatientAddress(val, flag)}
                        />

                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="Apt/Unit#"
                          value={newClaim.pat_addr_2}
                          errorList={errorList}
                          index="pat_addr_2"
                          maxLength={65}
                          placeholder="Apt/Unit#"
                          disabled={isDisabled}
                          handleChange={(e) => setNewClaim({ ...newClaim, pat_addr_2: e.target.value })}
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          label="City"
                          maxLength={65}
                          value={newClaim.pat_city}
                          disabled={isDisabled}
                          placeholder="City"
                          handleChange={(e) => setNewClaim({ ...newClaim, pat_city: e.target.value })}
                        />

                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          value={newClaim.pat_state}
                          errorList={errorList}
                          index="pat_state"
                          placeholder="Enter State"
                          maxLength={10}
                          label="State"
                          disabled={isDisabled}
                          handleChange={(e) => {
                            setNewClaim({
                              ...newClaim,
                              pat_state: e.target.value,
                            });
                          }}
                        />
                        <InputField
                          type="text"
                          inputStyle="modalInput"
                          labelStyle="text-capitalize"
                          value={newClaim.isoCode === "US" ? newClaim.pat_zip : newClaim.pat_zip}
                          placeholder="Enter Zip Code"
                          errorList={errorList}
                          index="pat_zip"
                          maxLength={10}
                          label="Zip"
                          disabled={isDisabled}
                          handleChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setNewClaim({
                              ...newClaim,
                              pat_zip: newClaim.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3 crew-from-column">
                    <div className="personal-info-row-1">
                      {/* <h4 className="text-decoration-underline text-black mt-0">Insurance Info</h4> */}
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex single-block-wrapper">
                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={INSURANCE_PROVIDER}
                            labelStyle="text-capitalize"
                            label="Insurance:"
                            value={
                              newClaim.payerid ? INSURANCE_PROVIDER.find((f) => f.value === newClaim.payerid) : null
                            }
                            index="payer_name"
                            isClearable={true}
                            placeholder="Select Insurance"
                            disabled={isDisabled}
                            errorList={errorList}
                            handleChange={(e) => {
                              if (e) {
                                setNewClaim({ ...newClaim, payer_name: e.label, payerid: e.value });
                              } else {
                                setNewClaim({ ...newClaim, payer_name: "", payerid: "" });
                              }
                            }}
                          />

                          <InputField
                            type="text"
                            groupWrapper="insuredNumberField"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label="Insurance Number:"
                            value={newClaim.ins_number}
                            errorList={errorList}
                            index="ins_number"
                            placeholder="Insurance Number"
                            disabled={isDisabled}
                            handleChange={(e) => {
                              let val = e.target.value;
                              setNewClaim({
                                ...newClaim,
                                ins_number: convertToUpper(val),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="personal-info-row-2">
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex single-block-wrapper">
                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={providersOptions}
                            labelStyle="text-capitalize"
                            label="Rendering Provider"
                            value={
                              newClaim.provider?.prov_id
                                ? providersOptions.find((f) => f.value === newClaim.provider?.prov_id)
                                : null
                            }
                            index="prov_npi"
                            isClearable={true}
                            placeholder="Select Rendering Provider"
                            disabled={isDisabled}
                            errorList={errorList}
                            componentOptions={componentOptionsRenderingProv}
                            handleChange={(e) => {
                              if (e) {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    prov_id: e.id,
                                    prov_npi: e.npi,
                                    prov_name_f: e.firstName,
                                    prov_name_l: e.lastName,
                                    prov_name_m: e.middleName,
                                    prov_taxid: e.taxid,
                                  },
                                });
                              } else {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    prov_id: "",
                                    prov_npi: "",
                                    prov_name_l: "",
                                    prov_name_f: "",
                                    prov_name_m: "",
                                    prov_taxid: "",
                                  },
                                });
                              }
                            }}
                          />

                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={providersOptions}
                            labelStyle="text-capitalize"
                            label="Referring Provider"
                            value={
                              newClaim.provider?.ref_id
                                ? providersOptions.find((f) => f.value === newClaim.provider?.ref_id)
                                : null
                            }
                            index="ref_npi"
                            isClearable={true}
                            placeholder="Select Referring Provider"
                            disabled={isDisabled}
                            errorList={errorList}
                            handleChange={(e) => {
                              if (e) {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ref_id: e.id,
                                    ref_npi: e.npi,
                                    ref_name: e.name,
                                    ref_name_f: e.firstName,
                                    ref_name_l: e.lastName,
                                    ref_name_m: e.middleName,
                                  },
                                });
                              } else {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ref_id: "",
                                    ref_npi: "",
                                    ref_name: "",
                                    ref_name_l: "",
                                    ref_name_f: "",
                                    ref_name_m: "",
                                  },
                                });
                              }
                            }}
                          />

                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={providersOptions}
                            labelStyle="text-capitalize"
                            label="Ordering Provider"
                            value={
                              newClaim.provider?.ord_prov_id
                                ? providersOptions.find((f) => f.value === newClaim.provider?.ord_prov_id)
                                : null
                            }
                            index="ord_prov_npi"
                            isClearable={true}
                            placeholder="Select Ordering Provider"
                            disabled={isDisabled}
                            errorList={errorList}
                            handleChange={(e) => {
                              if (e) {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ord_prov_id: e.id,
                                    ord_prov_npi: e.npi,
                                    ord_prov_name: e.name,
                                    ord_prov_name_f: e.firstName,
                                    ord_prov_name_l: e.lastName,
                                    ord_prov_name_m: e.middleName,
                                  },
                                });
                              } else {
                                setNewClaim({
                                  ...newClaim,
                                  provider: {
                                    ...newClaim.provider,
                                    ord_prov_id: "",
                                    ord_prov_npi: "",
                                    ord_prov_name: "",
                                    ord_prov_name_l: "",
                                    ord_prov_name_f: "",
                                    ord_prov_name_m: "",
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "auto" }} className="personal-info-row-3">
                      {/* <h4 className="text-decoration-underline text-black mt-0">CLIA Info</h4> */}
                      <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                        <div className="form-group-wrapper d-flex">
                          <InputField
                            type="dropDown"
                            groupWrapper="w-100"
                            options={cliList}
                            labelStyle="text-capitalize"
                            label="CLIA Number:"
                            value={
                              newClaim.clia_number
                                ? { cliCode: newClaim.clia_number, cliCode: newClaim.clia_number }
                                : null
                            }
                            index="clia_number"
                            isClearable={true}
                            placeholder="Select CLIA Number"
                            getOptionLabel={(option) => option.cliCode}
                            getOptionValue={(option) => option.cliCode}
                            disabled={isDisabled}
                            errorList={errorList}
                            handleChange={(e) => {
                              setNewClaim({ ...newClaim, clia_number: e?.cliCode || "" });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <h4 className="text-decoration-underline text-black mt-0 section-title">Diagnosis Code</h4>
                    <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                      <div className="crew-profile-complete crew-profile-again diagnosis-section">
                        {DIAGNOSIS_ITEMS.map((item, index) => (
                          <InputSelectDropDown
                            key={index}
                            type="text"
                            inputStyle="modalInput"
                            labelStyle="text-capitalize"
                            label={item}
                            groupWrapper="section-item"
                            placeholder={"Search Code"}
                            errorList={errorList}
                            index={`diag_${PARSE_ALPHA_INTO_NUM[item]}`}
                            readOnly={isDisabled}
                            optionList={DIAGNOSIS_OPTIONS}
                            value={newClaim[`diag_${PARSE_ALPHA_INTO_NUM[item]}`]}
                            handleChange={(opt) =>
                              setNewClaim({
                                ...newClaim,
                                [`diag_${PARSE_ALPHA_INTO_NUM[item]}`]: opt?.value || "",
                              })
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="text-decoration-underline text-black mt-0 section-title">Procedure Info</h4>
                      <div className="plus-icon" style={{ fontSize: "x-large" }}>
                        <i
                          className="fas fa-plus-circle fa-lg cursor-pointer w-auto"
                          onClick={() => {
                            if (!isDisabled) {
                              if (DIAGNOSIS_ITEMS.some((key) => newClaim[`diag_${PARSE_ALPHA_INTO_NUM[key]}`])) {
                                setProcedure(true);
                              } else {
                                setError("Please Select At least one Diagnosis Code");
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
                      <div className="data-listing-form table-wrapper mb-0">
                        <div>
                          <Table>
                            <thead>
                              <tr className="data-listing-row">
                                <th />
                                {PROCEDURE_CODE_HEADER.map((title) => (
                                  <th key={title} className="centered">
                                    {title}
                                  </th>
                                ))}
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {dataList?.map((data, index) => (
                                <ProcedureListView
                                  key={index}
                                  index={index}
                                  data={data}
                                  handleEdit={handleEdit}
                                  isDisabled={isDisabled}
                                  newClaim={newClaim}
                                  handleDelete={handleDelete}
                                  eraList={eraList}
                                  errorList={errorList}
                                />
                              ))}

                              {newClaim.total_charge && (
                                <tr index={dataList.length}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-center">
                                    <span>{currencyformatter.format(newClaim.total_charge || 0)}</span>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {procedure && (
                        <ProcedureModefierModal
                          data={editObj}
                          handleClose={() => {
                            setProcedure(false);
                            setEditObj({});
                          }}
                          isHospice={selectedEmployee?.isHospice}
                          hospiceCode={selectedEmployee?.codes?.hospiceModifer || ""}
                          prevClaims={prevClaims}
                          handleAddData={handleAddData}
                          handleUpdate={handleUpdate}
                          claimData={newClaim}
                          cssClass="seperateModal"
                        />
                      )}
                      {reasonModal && (
                        <ClaimResolveReasonModal
                          handleClose={handleCloseReasonModal}
                          cssClass="seperateModal"
                          handleCancel={() => setReasonModal(false)}
                        />
                      )}
                    </div>
                  </div>
                </Card>
                <div className="d-flex align-items-center justify-content-between column-gap-15">
                  {(newClaim.status === CLAIM_SUBMIT_STATUS.rejected ||
                    newClaim.status === CLAIM_SUBMIT_STATUS.denied) &&
                    newClaim.message && (
                      <div className="w-75">
                        <h4 className="profile-title">{CapsFirstLetter(newClaim.status)} Reason</h4>
                        <div className="mb-3">
                          <textarea
                            className="form-control reason-textarea w-100"
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty(
                                  "height",
                                  `${calcHeight(el.value, el.offsetWidth) + "px"}`,
                                  "important"
                                );
                              }
                            }}
                            value={newClaim?.message || ""}
                          ></textarea>
                        </div>
                      </div>
                    )}
                  {(newClaim.status === CLAIM_SUBMIT_STATUS.rejected ||
                    newClaim.status === CLAIM_SUBMIT_STATUS.denied ||
                    newClaim.status === CLAIM_SUBMIT_STATUS.submitted) && (
                    <div className="w-75">
                      <h4 className="profile-title">Re-Submit Comments</h4>
                      <div className="mb-3">
                        <textarea
                          className="form-control reason-textarea w-100"
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty(
                                "height",
                                `${calcHeight(el.value, el.offsetWidth) + "px"}`,
                                "important"
                              );
                            }
                          }}
                          value={newClaim?.reasonForSubmit || ""}
                          onChange={(e) =>
                            setNewClaim({
                              ...newClaim,
                              reasonForSubmit: e.target.value.replace(/'/g, ""),
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>

                {isNotification && (
                  <CheckBoxBar
                    label="You Changed Patient Info. Do you Want to change Patient Demographic as well?"
                    handleChange={(e) => setChangePatientData(e.target.checked)}
                    checked={changePatientData}
                  />
                )}
              </Col>
            </div>
          </div>
        </div>
      </Modal.Body>
      
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={handleConfirm}
          cssClass="seperateModal"
        />
      )}

      {openPrevClaimModal && (
        <EmployeePrevClaimsModal
          cssClass={"seperateModal"}
          user={newClaim}
          empClaimsList={prevClaims}
          handleClose={() => setOpenPrevClaimModal(false)}
          handleSaveClaim={(action, claim) => {
            setOpenPrevClaimModal(false);
            if (action === PREV_CLAIM_ACTIONS.auto) {
              setDataList(claim.proc_array);
              setNewClaim(claim);
              onHandleSave(true, "submitted", claim, "claim");
            } else if (action === PREV_CLAIM_ACTIONS.update) {
              formatClaimData(claim);
            }
          }}
        />
      )}

      {isHospice && (
        <HospiceModifierModal
          newUser={{ ...isHospice.newClaim }}
          claimtype={isHospice.claimtype}
          claimStatus={isHospice.claimStatus}
          employees={employees}
          handleClose={(val) => {
            if (val) setHospiceModifier(true);
            setIsHospice(null);
          }}
          handleSave={handleAddHospiceModifire}
        />
      )}

      {showLabInfoModal && <LabInfoModal cssClass="seperateModal" handleClose={() => setShowLabInfoModal(false)} />}

      {providerObj && (
        <ProviderModal
          cssClass="seperateModal"
          show={providerObj}
          user={providerObj}
          handleClose={() => setProviderObj(null)}
          setting={setting}
          readOnly
        />
      )}

      {employeeObjToUpdate && (
        <EmployeeModal
          cssClass={"seperateModal"}
          user={formatEmp(employeeObjToUpdate)}
          handleClose={() => setEmployeeObjToUpdate(null)}
          handleSave={createMember}
          // resetCall={setOpenPasswordModal}
          users={employees}
        />
      )}

      {claimList && claimList.length > 0 && (
        <ViewClaimList
          empClaimsList={claimList}
          patient={updatePatient}
          handleClose={(val) => {
            if (val) {
              dispatch(fetchAllEmployeeClaims(null, 1, {}, true));
              dispatch(setMessage("Updated Claims Successfully", MESSAGE_MODES.success));
            }
            setClaimList([]);
          }}
          cssClass={"seperateModal"}
        />
      )}

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>

        {newClaim.status === CLAIM_SUBMIT_STATUS.draft && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill mx-3 ms-auto"
            onClick={() =>
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to  Submit Patient Claim?`,
                actionType: CONFIRMATION_TYPE.SUBMIT_CLAIM,
                title: "Claim Submitted",
              })
            }
          >
            Submit Claim
          </Button>
        )}
        {(newClaim.status === CLAIM_SUBMIT_STATUS.rejected ||
          newClaim.status === CLAIM_SUBMIT_STATUS.denied ||
          newClaim.status === CLAIM_SUBMIT_STATUS.submitted) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill mx-3 ms-auto"
            onClick={() =>
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to Re-Submit Patient Claim?`,
                actionType: CONFIRMATION_TYPE.RESUBMIT_CLAIM,
                title: "Claim Re-Submitted",
              })
            }
          >
            Re-Submit Claim
          </Button>
        )}

        {(!newClaim.status ||
          newClaim.status === CLAIM_SUBMIT_STATUS.draft ||
          newClaim.status === CLAIM_SUBMIT_STATUS.rejected ||
          newClaim.status === CLAIM_SUBMIT_STATUS.denied) && (
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            disabled={isDisabled || loading}
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(!newClaim.status, newClaim.status)}
          >
            {!newClaim.status ? "Create" : "Update"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(CreateClaimResubmissionModal, arePropsEqual);
