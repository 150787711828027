import { Chart, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CLAIMS_STATS_DETAILS, PAID_CLAIM_FILTERING_SPANS, currencyformatter } from "constant";
import { useEffect, useRef, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { paidClaimsSelector } from "store/features/dashboard/dashboardSelectors";
import { filterPaidClaimsAsync } from "store/features/dashboard/dashboardSlice";
import { formatNumberWithDecimal, formatNumber, getDifferentColors } from "utils";
import InsurancePaidClaimsModal from "views/LabDashboard/Modals/InsurancePaidClaimsModal/InsurancePaidClaimsModal";
import PaidClaimDetailsModal from "views/LabDashboard/Modals/PaidClaimDetailsModal/PaidClaimDetailsModal";
import PaidClaimsFilterModal from "views/LabDashboard/Modals/PaidClaimsFilterModal/PaidClaimsFilterModal";
import ChartLabelsPopovers from "views/LabDashboard/Popovers/ChartLabelsPopovers/ChartLabelsPopovers";
import filterIcon from "../../../../assets/img/Filter-icon.svg";

const PaidClaimStats = () => {
  Chart.register(ChartDataLabels, Legend, Tooltip);
  const [paidAmount, setPaidAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showPaidDetails, setShowPaidDetails] = useState(false);
  const [showInsuranceIdDetails, setShowInsuranceIdDetails] = useState("");
  const [reset, setReset] = useState(true);
  const [showLabelsPopover, setShowLabelsPopover] = useState(false);
  const [filter, setFilter] = useState(null);
  const labelsTargetRef = useRef(null);
  const dispatch = useDispatch();
  const detailsObj = useSelector(paidClaimsSelector);

  useEffect(() => {
    if (reset) {
      getStats({ date: CLAIMS_STATS_DETAILS.date, selectedSpan: CLAIMS_STATS_DETAILS.selectedSpan });
      setShowPaidDetails(false);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (filter) getStats(filter);
  }, [filter]);

  const differencePercentange = (paidAmount / totalAmount) * 100 || 0;

  const legendCallback = (chart) => {
    const {
      data: { datasets, labels },
    } = chart;
    const { backgroundColor } = datasets[0];

    const MAX_LEGEND_LABELS = 3; // Maximum number of legend labels to display
    const displayedLabels = labels.slice(0, MAX_LEGEND_LABELS);
    const hasMoreLabels = labels.length > MAX_LEGEND_LABELS;

    const newLegendItems = displayedLabels.map((label, index) => {
      const color = backgroundColor[index];
      return {
        text: label,
        fillStyle: color,
      };
    });

    if (hasMoreLabels) {
      newLegendItems.push({
        text: "...",
        fillStyle: "rgba(0, 0, 0, 0)", // Transparent color for the "..." label
      });
    }

    return newLegendItems;
  };

  const handleLabelHover = (event, legendItem) => {
    // Handle the hover event for legend labels here
    if (legendItem.text === "...") setShowLabelsPopover(true);
  };

  const getChartDetails = (item) => {
    switch (item) {
      case "data":
        if (!showPaidDetails) {
          return [paidAmount, totalAmount];
        } else {
          return detailsObj.rows.map((claim) => claim.paidAmount) || [];
        }
      case "labels":
        if (!showPaidDetails) {
          return ["Paid Amount", "Total Amount"];
        } else {
          return detailsObj.rows.map((claim) => claim.payer_name) || [];
        }
      case "colors":
        if (!showPaidDetails) {
          return ["#7ED2B2", "#658DF7"];
        } else {
          return detailsObj.rows.map((_, i) => getDifferentColors(i + 1, detailsObj.rows.length, 0.7)) || [];
        }
      case "borderColors":
        if (!showPaidDetails) {
          return ["#7ED2B2", "#658DF7"];
        } else {
          return detailsObj.rows.map((_, i) => getDifferentColors(i + 1, detailsObj.rows.length, 0.7)) || [];
        }
      case "meta":
        if (!showPaidDetails) {
          return ["paid", "total"];
        } else {
          return detailsObj.rows.map((claim) => claim.payerid) || [];
        }
    }
  };

  const data = {
    labels: getChartDetails("labels"),
    datasets: [
      {
        data: getChartDetails("data"),
        backgroundColor: getChartDetails("colors"),
        hoverBackgroundColor: getChartDetails("colors"),
        meta: getChartDetails("meta"),
        borderWidth: 1,
        borderColor: getChartDetails("borderColors"),
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false, // Allow the chart to adjust its size
    responsive: true,
    plugins: {
      legend: {
        display: showPaidDetails, // Hide the legend labels
        position: "top",
        labels: {
          generateLabels: legendCallback,
        },
        onHover: handleLabelHover, // Attach the hover event handler
      },
      datalabels: {
        color: "var(--text-black)",
        formatter: (value, obj) => {
          return value === paidAmount
            ? `${paidAmount > 0 ? "+" : ""}${differencePercentange && differencePercentange.toFixed(2)}%`
            : "";
        },
        font: {
          size: 22,
          weight: "bold",
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        // The user clicked on a pie slice
        const clickedIndex = elements[0].index;
        const label = data.labels[clickedIndex];
        const value = data.datasets[0].data[clickedIndex];
        const meta = data.datasets[0].meta[clickedIndex];

        if (showPaidDetails) {
          setShowInsuranceIdDetails(meta);
        } else if (data.labels[clickedIndex] === "Paid Amount") setShowPaidDetails(true);
      }
    },
  };

  useEffect(() => {
    let newTotalAmount = 0;
    let newPaidAmount = 0;
    (detailsObj?.rows || []).forEach((row) => {
      newTotalAmount += row.ttlcharge || 0;
      newPaidAmount += row.paidAmount || 0;
    });
    setTotalAmount(newTotalAmount);
    setPaidAmount(newPaidAmount);
  }, [detailsObj?.rows]);

  const getStats = async (dateObj) => {
    if (!dateObj || !dateObj.date) return;
    setOpenFilterModal(false);

    const obj = {
      date: dateObj.date,
      pid: dateObj.payerid || "",
      selectedSpan: dateObj.selectedSpan,
    };
    dispatch(filterPaidClaimsAsync(obj));
  };

  return (
    <>
      <div className="paidClaimStats">
        <div className="d-flex justify-content-between">
          <span>Claim Paid by Insurance</span>
          <div className="seeDetailsContainer">
            <div className="d-flex align-items-center">
              {(detailsObj.selectedSpan !== PAID_CLAIM_FILTERING_SPANS.monthly || showPaidDetails) && (
                <i className="fas fa-undo cursor-pointer" onClick={() => setReset(true)} />
              )}
              <img src={filterIcon} className="statsFilterIcon" onClick={() => setOpenFilterModal(true)} />
            </div>
            <span className="linkedText" onClick={() => setShowDetailsModal(true)}>
              See Details
            </span>
          </div>
        </div>

        <div className="paidClaimPieGraph" ref={labelsTargetRef}>
          <Pie key={JSON.stringify(paidAmount + totalAmount)} data={data} options={chartOptions} />
        </div>

        <div className="paidClaimStats-bottom">
          <div className="paidClaimStats-bottom-portion">
            <span>Total Claim Amount</span>
            <span className="fs-30">{currencyformatter.format(totalAmount || 0)}</span>
          </div>
          <div className="paidClaimStats-bottom-portion">
            <span>Total Paid Amount</span>
            <span className="fs-30">{currencyformatter.format(paidAmount || 0)}</span>
          </div>
        </div>
      </div>
      {openFilterModal && (
        <PaidClaimsFilterModal handleClose={() => setOpenFilterModal(false)} handleChange={(obj) => setFilter(obj)} />
      )}
      {showDetailsModal && (
        <PaidClaimDetailsModal handleClose={() => setShowDetailsModal(false)} detailsObj={detailsObj} />
      )}
      {showInsuranceIdDetails && (
        <InsurancePaidClaimsModal
          handleClose={() => setShowInsuranceIdDetails("")}
          insuranceId={showInsuranceIdDetails}
        />
      )}
      {showLabelsPopover && (
        <ChartLabelsPopovers
          target={labelsTargetRef}
          showPopover={showLabelsPopover}
          setShowPopover={setShowLabelsPopover}
          colors={getChartDetails("colors")}
          labels={getChartDetails("labels")}
          handleLabelClick={(e, label, index) => {
            const meta = data.datasets[0].meta[index];
            setShowInsuranceIdDetails(meta);
            setShowLabelsPopover(false);
          }}
        />
      )}
    </>
  );
};

export default PaidClaimStats;
