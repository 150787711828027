import React from "react";
import AppealStats from "views/LabDashboard/Stats/AppealStats/AppealStats";
import MonthlyClaimStats from "views/LabDashboard/Stats/MonthlyClaimStats/MonthlyClaimStats";
import NewPatientsStats from "views/LabDashboard/Stats/NewPatientsStats/NewPatientsStats";
import PaidClaimStats from "views/LabDashboard/Stats/PaidClaimStats/PaidClaimStats";
import UnpaidClaimStats from "views/LabDashboard/Stats/UnpaidClaimStats/UnpaidClaimStats";

const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
};

const LabStatsContainer = (props) => {
  const { statsObj: data } = props;

  return (
    <>
      <div className="labDashboard-upper">
        <div className="dashboard-stats-left">
          <MonthlyClaimStats />
          <div className="dashboard-stats-left-bottom">
            <div className="w-50">
              <PaidClaimStats />
            </div>
            <div className="w-50">
              <UnpaidClaimStats />
            </div>
          </div>
        </div>
        <div className="dashboard-stats-right">
          <NewPatientsStats totalPatients={data.patients.total} newPatients={data.patients.newPatients} />
          <AppealStats approvedAppeals={data.appeals.approved} pendingAppeals={data.appeals.pending} />
        </div>
      </div>
    </>
  );
};

export default React.memo(LabStatsContainer, arePropsEqual);
