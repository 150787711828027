import api from "api";
import RadioButton from "components/RadioButton/RadioButton";
import { HOSPICE_MODFIRE, MOD_KEYS } from "constant";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const HospiceModifierModal = (porps) => {
  const { newUser, handleClose, handleSave, claimtype, claimStatus, employees } = porps;
  const [selectModifier, setSelectModifier] = useState("");

  const onHandleSave = async () => {
    let user = { ...newUser };
    let procArr = [...user.proc_array];
    procArr = procArr.map((m) => {
      const existMod = MOD_KEYS.filter((k) => m[k]);

      return { ...m, [`mod${existMod.length + 1}`]: selectModifier };
    });
    user.proc_array = procArr;
    const emp = employees.find((f) => f.schrID === newUser.pcn);
    try {
      if (emp) {
        await api.updatePatientHospice(emp);
      }
      handleSave(user, claimtype, claimStatus);
      handleClose(true);
    } catch (err) {
      console.log("Error:-", err.message);
      handleClose();
    }
  };

  return (
    <Modal
      show={true}
      backdrop="static"
      className="seperateModal"
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Hospice Modifier
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <h4 className="fw-bold">{`The patient ${newUser.pat_name_f} ${newUser.pat_name_l} is in Hospice condition. Please choose the appropriate modifier, otherwise claim will be denied`}</h4>

        <div className="createClientsWrapper w-100 mt-3">
          <Form.Group className="form-group-wrapper w-100">
            <Form.Label className="mt-0 fs-6 text-capitalize">Choose Hospice Modifier:</Form.Label>
            <div className="">
              <RadioButton
                name="showInsurance"
                id={"1"}
                checked={selectModifier === HOSPICE_MODFIRE.GW ? true : false}
                label="GW: the service rendered is unrelated to the patient's terminal condition"
                handleChange={(e) => setSelectModifier(HOSPICE_MODFIRE.GW)}
                cssClass={"mx-1 py-2"}
              />
              <RadioButton
                id={"2"}
                checked={selectModifier === HOSPICE_MODFIRE.GV ? true : false}
                name="showInsurance"
                label="GV: the service rendered is related to the patient's terminal condition"
                handleChange={(e) => setSelectModifier(HOSPICE_MODFIRE.GV)}
                cssClass={"mx-1 py-2"}
              />
            </div>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => onHandleSave()}
        >
          No
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill ms-auto"
          onClick={() => onHandleSave()}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HospiceModifierModal;
