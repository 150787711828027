import React from "react";
import { Doughnut } from "react-chartjs-2";

const DashboardGaugeChart = (props) => {
  const { dataKey, data, options, plugins, numberOfSplits = 1, cssClass, chartCanvaCssClass } = props;
  const slicedData = {
    datasets: [
      {
        data: new Array(numberOfSplits).fill(100 / numberOfSplits),
        backgroundColor: new Array(numberOfSplits).fill("transparent"),
        borderWidth: 4,
        hoverBorderWidth: data.datasets[0].hoverBorderWidth,
        borderColor: data.datasets[0].borderColor,
      },
    ],
  };

  const slicedOptions = {
    responsive: options.responsive,
    maintainAspectRatio: options.maintainAspectRatio,
    cutout: options.cutout,
    rotation: options.rotation,
    circumference: options.circumference,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <>
      <div className={`position-relative ${cssClass ?? ""}`}>
        <Doughnut
          key={dataKey}
          className={`${chartCanvaCssClass ?? ""} position-absolute`}
          data={data}
          options={options}
          plugins={plugins}
        />
        <Doughnut
          className={`${chartCanvaCssClass ?? ""} position-absolute`}
          data={slicedData}
          options={slicedOptions}
        />
      </div>
    </>
  );
};

export default DashboardGaugeChart;
