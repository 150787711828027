import ProgressBar from "components/ProgressBar";
import { currencyformatter } from "constant";
import { formatNumber } from "utils";

const SingleMonthlyStats = (props) => {
  const { label, totalAmount = 0, payableAmount = 0, percentage = 0, color, handleLabelClick, itemKey } = props;
  return (
    <>
      <div className="singleMonthlyStats">
        <div className="left-border" />
        <h6 className="fs-15">{currencyformatter.format(payableAmount)}</h6>
        <ProgressBar bgcolor={color} progress={percentage} height={6} />
        <span className="fs-13 cursor-pointer" onClick={(e) => handleLabelClick(e, itemKey)}>
          {label}
        </span>
        <h6 className="fs-20">{formatNumber(totalAmount)}</h6>
      </div>
    </>
  );
};

export default SingleMonthlyStats;
