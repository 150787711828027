import CreateClaimResubmissionModal from "views/EmployeeClaims/CreateClaimResubmissionModal";
import MainTable from "components/Table/MainTable";
import { DASHBOARD_CLAIMS_DROPDOWN_OPTIONS, TABLE_QUICK_TOOLS, CLAIM_SUBMIT_STATUS } from "constant";
import { DASHBOARD_EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import { fetchCompanySetting } from "store/features/companySetting/companySettingSlice";
import { latestUnapprovedClaimsSelector } from "store/features/dashboard/dashboardSelectors";
import { getLastestDeniedRejectedClaimsAsync } from "store/features/dashboard/dashboardSlice";
import { CapsFirstLetter, draggablePersonalizationLocalStorage, formatDateMDY } from "utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";

const DashboardClaimTable = () => {
  const appContext = useContext(AppContext);
  const quickTools = [];
  const [personalize, setPersonalize] = useState([]);
  const [filteredEmployeeClaims, setFilteredEmployeeClaims] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [claimReSubmissionModal, setClaimReSubmissionModal] = useState([]);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const loginUser = useSelector(selectLoginUser);
  const dispatch = useDispatch();
  const employeeClaims = useSelector(latestUnapprovedClaimsSelector);

  useEffect(() => {
    setPersonalize(
      draggablePersonalizationLocalStorage.get(
        appContext?.user,
        "employeeClaimDashboard",
        DASHBOARD_EMP_CLAIM_PERSONALIZE
      )
    );
  }, [appContext?.company]);

  useEffect(() => {
    if (filteredEmployeeClaims.length > 0) setFilteredEmployeeClaims(sortUsers(filteredEmployeeClaims, sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    getEmployeeClaims();
  }, []);

  const getEmployeeClaims = async () => {
    dispatch(getLastestDeniedRejectedClaimsAsync({ type: CLAIM_SUBMIT_STATUS.rejectedanddenied }));
  };

  useEffect(() => {
    if (!employeeClaims) return;
    setFilteredEmployeeClaims(sortUsers(formatClaims(employeeClaims), sortBy));
  }, [employeeClaims]);

  const formatClaims = (models) => {
    return models.map((m) => {
      return {
        ...m,
        partialAmount: m.paidAmount && m.proc_array.some((s) => !s.isClaimProcess),
        submissionDate: m.reSubmissionDate || m.submissionDate || m.createdAt,
        paid_ins_processed: m.paid_insurance?.payer_name || "",
      };
    });
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const sortUsers = (users, sortBy) => {
    if (!sortBy) {
      return [...users].sort((a, b) =>
        `${b["pat_name_f"]}${b["pat_name_l"]}` < `${a["pat_name_f"]}${a["pat_name_l"]}`
          ? 1
          : `${a["pat_name_f"]}${a["pat_name_l"]}` < `${b["pat_name_f"]}${b["pat_name_l"]}`
          ? -1
          : 0
      );
    }

    if (sortDescending) {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return fieldA < fieldB ? -1 : 1;
        }
      });
    } else {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        } else {
          return fieldB < fieldA ? -1 : 1;
        }
      });
    }
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const tdFormat = (item, row) => {
    if (item === "submissionDate") return formatDateMDY(row.submissionDate);
    if (item === "pat_full_name") return `${row.pat_name_f} ${row.pat_name_l}`;
    return row[item];
  };

  const tdStatus = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
          cursor: "pointer",
        }}
        title={`${row.message || ""}${row.crossover_carrier ? `\n ${row.crossover_carrier}` : ""}`}
        onClick={() => handleCellClick(item.itemKey, row)}
      >
        <div
          className="statusPill custom-ellipsis d-block"
          style={{
            backgroundColor: item.colorObj[row[item.itemKey]],
          }}
        >
          {CapsFirstLetter(row.status)}
        </div>
      </td>
    );
  };

  const renderTd = (item, row) => {
    if (item.itemKey === "status") return tdStatus(item, row);
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <MainTable
        cssClass="dashboardClaimTable"
        columns={personalize}
        rows={filteredEmployeeClaims}
        widthToSkip={190}
        tools={quickTools}
        dropDownOptions={DASHBOARD_CLAIMS_DROPDOWN_OPTIONS}
        handleDropDownClick={(type, row) => {
          if (type === "logs") {
            setOpenOrderDetail(true);
            setSelectedOrder(row);
          } else if (type === "view") {
            setClaimReSubmissionModal([row]);
          }
        }}
        selectedRows={checkboxes}
        handleCellClick={handleCellClick}
        customColumnCellRenderer={renderTd}
      />
      {claimReSubmissionModal.length > 0 && (
        <CreateClaimResubmissionModal
          user={claimReSubmissionModal}
          handleClose={(val) => {
            setClaimReSubmissionModal([]);
            if (val) {
              getEmployeeClaims();
            }
          }}
        />
      )}
      {openOrderDetail && (
        <OrderLogsModal
          order={selectedOrder}
          show={openOrderDetail}
          user={loginUser}
          handleClose={() => {
            setOpenOrderDetail(false);
            setSelectedOrder("");
          }}
          claim
        />
      )}
    </div>
  );
};

export default DashboardClaimTable;
