import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { userCompanyID } from "utils";
import moment from "moment";
import { LAB_CLAIM_STATUS_OPT } from "constant";
import RadioButton from "components/RadioButton/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";

const OrderImportModal = (props) => {
  const { handleClose, handleImport, title, eligibility, claim, trackingImport, handleDownloadSample } = props;

  const loginUser = useSelector(selectLoginUser);
  const { subAgents } = useSelector(selectedSubAgent);

  const getSelectedCompany = () => {
    if (loginUser?.isSubAgent()) {
      return userCompanyID.get();
    }
    if (subAgents.length === 1) {
      return subAgents[0].id;
    }
    return "";
  };
  const [selectedLocation, setSelectedLocation] = useState(getSelectedCompany());
  const [orderMonth, setOrderMonth] = useState();
  const [shipmentDate, setShipmentDate] = useState();
  const [claimStatus, setClaimStatus] = useState("");

  const handleOnDrop = (data) => {
    handleImport(data, selectedLocation, orderMonth, shipmentDate, claimStatus);
  };
  const handleOnError = (err) => {
    console.log({ err });
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal show animation={true} backdrop="static" onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          {handleDownloadSample && (
            <div
              style={{
                textAlign: "right",
                color: "#42cef5",
                textAlign: "center",
                cursor: "Pointer",
              }}
              onClick={() => handleDownloadSample()}
            >
              Download Upload Template
            </div>
          )}
          {!eligibility && (
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Select {claim ? "Claim Date" : "Order Month"}: </label>
              <DatePicker
                className="w-100 orderTestDob"
                openCalendarOnFocus={false}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                format="MM/dd/y"
                onChange={(e) => setOrderMonth(moment(e).format("YYYY-MM-DD"))}
                value={
                  orderMonth && new Date(orderMonth) != "Invalid Date"
                    ? moment(orderMonth, "YYYY-MM-DD").toDate()
                    : null
                }
                maxDate={new Date()}
                minDate={new Date("1900-01-01")}
              />
            </div>
          )}
          {trackingImport && (
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Select Shipment Date: </label>
              <DatePicker
                className="w-100 orderTestDob"
                openCalendarOnFocus={false}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                format="MM/dd/y"
                onChange={(e) => setShipmentDate(moment(e).format("YYYY-MM-DD"))}
                value={
                  shipmentDate && new Date(shipmentDate) != "Invalid Date"
                    ? moment(shipmentDate, "YYYY-MM-DD").toDate()
                    : null
                }
                maxDate={new Date()}
                minDate={new Date("1900-01-01")}
              />
            </div>
          )}
          {(loginUser?.isAdmin() || loginUser?.isClient() || loginUser?.isSite()) &&
            subAgents?.length > 1 &&
            !claim &&
            !trackingImport && (
              <div className="col-lg-12 col-12 mb-3">
                <label className="modalLineHeaders mt-0">Sub Agents:</label>
                <Select
                  options={subAgents.map((s) => {
                    return { ...s, label: s.name, value: s.id };
                  })}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select Sub Agent"
                  className="w-100"
                  onChange={(e) => setSelectedLocation(e.value)}
                />
              </div>
            )}
          {claim && (
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">Claim Status:</label>
              <Select
                options={LAB_CLAIM_STATUS_OPT}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                blurInputOnSelect={true}
                menuPlacement="auto"
                placeholder="Select Claim Status"
                className="w-100"
                onChange={(e) => setClaimStatus(e.value)}
              />
            </div>
          )}
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">Upload File Below</label>
          </div>
          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true,
            }}
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderImportModal;
