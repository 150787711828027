import { createProviderAsync, updateProviderAsync, deleteProviderAsync } from "./providersSlice";

export const createProvider = (provider, defaultSetting) => async (dispatch) => {
  await dispatch(createProviderAsync({ provider, defaultSetting }));
};

export const updateProvider = (provider, defaultSetting) => async (dispatch) => {
  await dispatch(updateProviderAsync({ provider, defaultSetting }));
};

export const deleteProvider = (providerId) => async (dispatch) => {
  await dispatch(deleteProviderAsync(providerId));
};
