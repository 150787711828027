import api from "api";
import { AppContext } from "context/app-context";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";

const LabInfoModal = (props) => {
  const { handleClose, cssClass } = props;
  const appContext = useContext(AppContext);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    getLabInfo();
  }, [appContext.setting]);

  const getLabInfo = async () => {
    setSettings(appContext.setting);
  };

  return (
    <Modal
      className={cssClass ?? ""}
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Lab Info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <label className="modalLineHeaders fw-bold">Lab Info: </label>
            <Card className="px-2">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Name: </label>
                <span>{settings?.name || "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">NPI: </label>
                <span>{settings?.npi || "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Bill Id: </label>
                <span>{settings?.bill_id || "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Tax Id: </label>
                <span>{settings?.taxid || "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Status: </label>
                <span>{settings?.status || "-"}</span>
              </div>
            </Card>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LabInfoModal;
