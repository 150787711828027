/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { API, Auth, Storage } from "aws-amplify";
import { DataStore } from "@aws-amplify/datastore";
import { readString } from "react-papaparse";
import api from "api";
import { medFlowInLocalStorage, userCompanyID } from "utils";
import { downloadDataAsCSV } from "utils";
import { t } from "stringConstants";
import { CONFIG } from "constant";
import EligibilityViewModal from "components/Modal/EligibilityViewModal";
import { loggedInUser } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "store/features/authentication/authenticationSlice";
import ThemeButton from "components/ThemeButton/ThemeButton";

function Header(props) {
  const {
    emailSending,
    user,
    userLocation,
    userCompany,
    eligibilityFiles,
    setSuccessMessageText,
    setErrorMessageText,
  } = props;

  const [isDropDown, setIsDropDown] = useState(false);
  const [eligibilityFile, setEligibilityFile] = useState(null);
  const [eligibilityData, setEligibilityData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setEligibilityFile(eligibilityFiles[0]);
  }, [eligibilityFiles]);
  const location = useLocation();
  let history = useHistory();

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const DownLoadCsv = async (eligibility) => {
    try {
      const FileTest = await Storage.get(eligibility?.finalFile, {
        bucket: CONFIG.eligibilityBucket,
      });
      const link = document.createElement("a");
      link.href = FileTest;
      link.download = t("eligibilityStatusFile");
      link.click();
      const res = await api.updateEligibilityFile(eligibility);
      setEligibilityFile(res);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const openViewModal = async (eligibility) => {
    try {
      const FileTest = await Storage.get(eligibility?.finalFile, {
        bucket: CONFIG.eligibilityBucket,
        download: true,
      });
      const apiData = await FileTest.Body.text();
      readString(apiData, {
        header: true,
        worker: true,
        complete: (results) => {
          if (results.data) {
            setEligibilityData(results.data);
          }
        },
      });
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };
  const roleSwitch = (role) => {
    switch (role) {
      case "Admins":
        return `Admin`;
      case "Labs":
        return "Lab";
      case "Employers":
        // if (userLocation && userLocation.name) {
        //   return `${userLocation.name} Facility/Agent `;
        // }
        if (user["custom:note"]) {
          return `${user["custom:note"]} Admin`;
        }
        return "Facility/Agent";
      case "Employees":
        return "Member";
      case "Clients":
        // if (userCompany && userCompany.name) {
        //   return `${userCompany.name} Client`;
        // }
        if (user["custom:note"]) {
          return `Admin`;
        }
        return "Company Admin";
      case "SubAgents":
        if (user["custom:note"]) {
          return `${user["custom:note"]} Admin`;
        }
        return "Sub Agent";
      default:
        return "Admin";
    }
  };

  const getBrandText = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };
  return (
    <>
      <Navbar expand="lg" className="py-0">
        <Container fluid>
          <div className="d-flex justify-content-center align-items-center ml-0 ml-sm-2 ml-md-2 ml-lg-0 ml-xl-0">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2 mx-0"
              onClick={mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
            <div className="nav-brand-wrapper d-flex align-items-center">
              <i className="fas fa-home" />
              <Navbar.Brand
                href="#home"
                className="my-0 py-0"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/admin");
                }}
              >
                {/* {getBrandText(props.routes)} */}
                {roleSwitch(props.role)} Portal
              </Navbar.Brand>
            </div>

            {/* <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
            style={{ fontSize: '30px'}}
          >{getBrandText(props.routes) === "Completed Tests" && `(${props.completedTests.length})`}</Navbar.Brand>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
            style={{ fontSize: '30px'}}
          >{getBrandText(props.routes) === "Pending Release" && `(${props.pendingTests.length})`}</Navbar.Brand> */}
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="nav ml-auto align-items-center" navbar>
              {eligibilityFile?.requestedBy === user?.sub &&
              (eligibilityFile?.status === "Pending" || eligibilityFile?.status === "InProgress") ? (
                <Button variant="secondary" className="btn btn-outline-secondary modalButtons inProgressMsg mx-3">
                  {`${eligibilityFile.fileType === "Eligibility" ? "Eligibility Checking" : "Order"} is in-progress...`}
                </Button>
              ) : eligibilityFile?.status === "Done" &&
                eligibilityFile?.fileDownloaded === 0 &&
                eligibilityFile?.finalFile ? (
                <>
                  <div className="btn modalButtons EligibilityMsgBar headerButton mx-3">
                    <div className="d-inline align-middle mx-3 fs-6 fw-6">
                      {` ${eligibilityFile.fileType === "Eligibility" ? "Eligibility Checking" : "Order"} completed, `}
                      <u onClick={() => DownLoadCsv(eligibilityFile)}>Click to download the results</u>
                    </div>
                    <Button
                      variant={"danger"}
                      className="btn-sm downloadBtn m-auto me-2 linkedText cursor-pointer"
                      onClick={() => DownLoadCsv(eligibilityFile)}
                    >
                      <i className="fas fa-download cursor-pointer maroon" />
                    </Button>
                    {eligibilityFile.fileType === "Eligibility" && (
                      <Button
                        className="btn btn-primary btn-sm viewBtn m-auto linkedText cursor-pointer"
                        onClick={(e) => openViewModal(eligibilityFile)}
                      >
                        {" "}
                        View
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {eligibilityData.length > 0 && (
                <EligibilityViewModal
                  handleClose={(data) => {
                    if (data) {
                      setSuccessMessageText(
                        `Thank You for Submitting ${data.length} for Order Placed we will be process your request in couple of minutes and will be notified.`
                      );
                    }
                    setEligibilityData([]);
                  }}
                  data={eligibilityData}
                  eligibilityFiles={eligibilityFile}
                  user={user}
                />
              )}
              {/* <Nav.Item>
              <Nav.Link
                data-toggle="dropdown"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                className="m-0"
              >
                <i className="nc-icon nc-palette"></i>
                <span className="d-lg-none ml-1">Dashboard</span>
              </Nav.Link>
            </Nav.Item> */}
              {/* <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                data-toggle="dropdown"
                id="dropdown-67443507"
                variant="default"
                className="m-0"
              >
                <i className="nc-icon nc-planet"></i>
                <span className="notification">5</span>
                <span className="d-lg-none ml-1">Notification</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 1
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 2
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 3
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 4
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Another notification
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
              {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="nc-icon nc-zoom-split"></i>
                <span className="d-lg-block"> Search</span>
              </Nav.Link>
            </Nav.Item> */}
            </Nav>
            <Nav navbar className="align-items-center">
              {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">Account</span>
              </Nav.Link>
            </Nav.Item> */}
              {/* <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0"
              >
                <span className="no-icon">Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Another action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Something
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Something else here
                </Dropdown.Item>
                <div className="divider"></div>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Separated link
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
              {/*<Nav.Item>*/}
              {/*  <div className="searchbar-wrapper position-relative">*/}
              {/*    <input type="text" className="form-control" placeholder="Search..." />*/}
              {/*    <i className="fas fa-search" />*/}
              {/*  </div>*/}
              {/*</Nav.Item>*/}
              <Nav.Item className="user-profile-wrapper top-user-profile position-relative">
                <figure className=" mb-0">
                  {/*<img src={userProfile} alt="user profile" />*/}
                  <figcaption>{loggedInUser.get()?.name ? loggedInUser.get().name : user?.name || ""}</figcaption>
                </figure>
              </Nav.Item>

              {/* <Nav.Item className="user-profile-wrapper top-user-profile position-relative">
                <ThemeButton />
              </Nav.Item> */}

              {user?.isUser() && (
                <Nav.Item className="user-profile-wrapper top-user-profile position-relative">
                  <>
                    <div onClick={() => setIsDropDown(!isDropDown)}>
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </div>
                    {isDropDown && (
                      <ul className="navbarDropDownMenu m-0">
                        <li className="px-3 py-2 border-1 border-gray border-bottom">
                          <Nav.Link
                            className="m-0 p-0 lh-1 w-100 text-black"
                            href="#pablo"
                            onClick={async (e) => {
                              e.preventDefault();
                              setIsDropDown(false);
                              history.push("/admin/profile");
                            }}
                          >
                            Profile
                          </Nav.Link>
                        </li>
                        <li className="px-3 py-2">
                          <Nav.Link
                            className="m-0 p-0 lh-1 w-100 text-black"
                            href="#pablo"
                            onClick={async (e) => {
                              e.preventDefault();
                              await DataStore.clear();
                              await Auth.signOut();
                              medFlowInLocalStorage.clear();
                              dispatch(logOut());
                              userCompanyID.clear();

                              setIsDropDown(false);
                              history.push("/member-login");
                            }}
                          >
                            Logout
                          </Nav.Link>
                        </li>
                      </ul>
                    )}
                  </>
                </Nav.Item>
              )}
              {!user?.isUser() && (
                <Nav.Item>
                  <Nav.Link
                    className="m-0 navbar-logout-btn"
                    href="#pablo"
                    onClick={async (e) => {
                      e.preventDefault();
                      await DataStore.clear();
                      await Auth.signOut();
                      dispatch(logOut());
                      medFlowInLocalStorage.clear();
                      userCompanyID.clear();
                      setIsDropDown(false);
                      history.push("/login");
                    }}
                  >
                    Logout
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isDropDown && <div className="navbarDropDownBackDrop" onClick={() => setIsDropDown(!isDropDown)} />}
    </>
  );
}

export default Header;
