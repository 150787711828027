/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import api from "api";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { Auth, Hub, API, graphqlOperation } from "aws-amplify";
import IdleModal from "components/IdleModal";
import Loader from "components/Loader/Loader";
import AdminNavbar from "components/Navbars/AdminNavbar";
import ShowAlertMessage from "components/ShowAlertMessage";
import Sidebar from "components/Sidebar/Sidebar";
import { debounce } from "debounce";
import React, { useEffect, useState } from "react";
import "react-activity/dist/Bounce.css";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getCurrentUser, getListenerData } from "store/features/authentication/authenticationActions";
import { authSelector } from "store/features/authentication/authenticationSelectors";
import { logOut } from "store/features/authentication/authenticationSlice";
import * as subscriptions from "graphql/subscriptions";
import {
  filterMonthlyClaimsAsync,
  filterPaidClaimsAsync,
  getLastestDeniedRejectedClaimsAsync,
} from "store/features/dashboard/dashboardSlice";
import { fetchAllEmployees } from "store/features/employees/employeesSlice";
import { showMessage } from "store/features/general/generalSlice";
import {
  formatLocations,
  medFlowInLocalStorage,
  patientDataInLocalStorage,
  userCompanyID,
  userCompanyLogo,
} from "utils";
import AdminOrders from "views/AdminOrders";
import logoImage from "../assets/img/ltc_logo.png";
import {
  ADMIN_USERS,
  CLAIMS_STATS_DETAILS,
  CONFIG,
  DEFAULT_USRERS_PERMISSIONS,
  LAB_ROTUES,
  LOGO_IMAGE_PATH,
  filterRoutesPath,
  CLAIM_SUBMIT_STATUS,
} from "../constant";
import { AppContext } from "../context/app-context";
import {
  Client,
  CompanyStats,
  EligibiliyFiles,
  Employee,
  EmployeeClaims,
  ProviderInfo,
  Site,
  SubAgent,
  Users,
} from "../models";
import { fetchAllClients } from "../store/features/clients/clientsSlice";
import { loadingSelector, messageSelector } from "../store/features/general/generalSelectors";
import {
  updateClaimSubs,
  createClaimSubs,
  getStatsSummaryClaimAsync,
  deleteClaimSubs,
} from "store/features/employeeClaims/employeeClaimsSlice";

function Admin() {
  const timeout = 1800000;
  const [showIdleModal, setShowIdleModal] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
  const [leader, setLeader] = useState(true);
  const [timedOut, setTimedOut] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [permission, setPermission] = useState({});
  const { user, error, data, userRoutes, userSessionEnd } = useSelector(authSelector);
  const messageObj = useSelector(messageSelector);

  const loader = useSelector(loadingSelector);

  const dispatch = useDispatch();

  let history = useHistory();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const handleCloseModal = () => {
    setShowIdleModal(false);
  };

  const handleLogOut = async () => {
    setShowIdleModal(false);
    try {
      await DataStore.clear();
      await Auth.signOut();
      dispatch(logOut());
      medFlowInLocalStorage.clear();
      handlePause();
      history.push("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const handleOnIdle = (event) => {
    setShowIdleModal(true);
  };

  const handleOnActive = (event) => {
    setTimedOut(false);
  };

  const handleOnAction = (event) => {
    setTimedOut(false);
  };

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  const handleStart = () => start();

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
    getTotalIdleTime,
    getLastIdleTime,
    isIdle,
    pause,
    resume,
    start,
    isLeader,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: false,
    },
  });

  useEffect(() => {
    if (showIdleModal) {
      const timeout = setTimeout(() => {
        if (showIdleModal) {
          handleLogOut();
        }
      }, 300000); // TODO: - This time is the extra time you want to see how long they're idle for until they get logged out
      return () => clearTimeout(timeout);
    }
  }, [showIdleModal]);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [testFilter, setTestFilter] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subAgents, setSubAgents] = useState([]);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [errorMessageText, setErrorMessageText] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [userSubAgent, setUserSubAgent] = useState(null);
  const [employeeRecord, setEmployeeRecord] = useState(null);
  const [eligibilityFiles, setEligibilityFiles] = useState([]);
  const [providers, setProviders] = useState([]);
  const [eligibilityFileId, setEligibilityFileId] = useState("");
  const [logo, setLogo] = useState(logoImage);
  const [setting, setSetting] = useState({});
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.disabled) return null;
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };
  const getAdminRoutes = (routes) => {
    const route = routes[0];
    return user && !user?.isUser()
      ? window.location.pathname === "/admin" && <route.component />
      : user && window.location.pathname === "/admin" && <AdminOrders />;
  };

  const fetchUserGroups = async (id = null) => {
    let response = [];
    if (CONFIG.isLabType) {
      response = await api.getBRMUsers();
    } else {
      response = await api.getUserFromLocalDB(id, user);
    }
    setUsers(response || []);
    // console.log("In Fetching User Record");
    // const admins = await api.fetchUsersInGroup("Admins");
    // const employers = await api.fetchUsersInGroup("Employers");
    // const clients = await api.fetchUsersInGroup("Clients");
    // const subAgents = await api.fetchUsersInGroup("SubAgents");
    // return Promise.all([admins, employers, clients, subAgents]).then(
    //   (values) => [admins, employers, clients, subAgents]
    // );
  };

  const fetchAdmins = async () => {
    const admins = await api.fetchUsersInGroup("Admins");
    return admins;
  };

  const onSubscriptionTrigger = debounce(() => refreshDataOnSubscribe(), 6000);
  const onCleintSubscriptionTrigger = debounce(() => refreshClientDataOnSubscribe(), 1000);
  const onCompanyStatsSubscriptionTrigger = debounce(() => refreshStatsDataOnSubscribe(), 1000);
  const onClaimSummaryStatsSubscriptionTrigger = debounce(() => refreshClaimSummayStatsDataOnSubscribe(), 3000);
  const onEligibilitySubscriptionTrigger = debounce(() => refreshEligibiltyFileDataOnSubscribe(), 1000);

  const refreshDataOnSubscribe = () => {
    console.log("REFRESH_LIST");
    dispatch(fetchAllEmployees());
  };
  const refreshClaimSummayStatsDataOnSubscribe = () => {
    console.log("REFRESH_LIST_CLAIMS_STATS");
    dispatch(getStatsSummaryClaimAsync());
  };

  const refreshClientDataOnSubscribe = () => {
    console.log("REFRESH_LIST Clients");
    dispatch(fetchAllClients());
  };

  const refreshStatsDataOnSubscribe = () => {
    console.log("REFRESH_LIST Company Stats");
    const obj = { date: CLAIMS_STATS_DETAILS.date, selectedSpan: CLAIMS_STATS_DETAILS.selectedSpan };
    dispatch(filterMonthlyClaimsAsync(obj));
    dispatch(filterPaidClaimsAsync(obj));
    dispatch(getLastestDeniedRejectedClaimsAsync({ type: CLAIM_SUBMIT_STATUS.rejectedanddenied }));
  };

  const refreshEligibiltyFileDataOnSubscribe = () => {
    console.log("REFRESH_LIST");
    fetchEligibityFiles();
  };

  // useEffect(()=>{
  //   DataStore.stop();
  // },[])

  useEffect(async () => {
    if (data) {
      setPermission(data?.response?.userPermission || DEFAULT_USRERS_PERMISSIONS);
      if (data?.response?.clientID) {
        const client = await getClientById(data?.response?.clientID);
        client?.logo && getFileFromStorage(client.logo, setLogo);
      }
    } else if (userCompanyLogo.get()) {
      setLogo(userCompanyLogo.get());
    }
  }, [data]);

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      console.log("file", file);
      try {
        const path = `${LOGO_IMAGE_PATH}${file}`;
        userCompanyLogo.save(path);
        setImage(path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  const isReadOnly = () => !CONFIG.isLabType && user["phone_number"] === "+18185730434";

  useEffect(() => {
    let subscription = null;
    let eligibilityFileSubscrption = null;
    let clientSubscription = null;
    let companyStatsSubscrption = null;
    let apiSubscriptionUpdateClaim = null;
    let apiSubscriptionCreateClaim = null;
    let apiSubscriptionDeleteClaim = null;
    const getUSer = async () => {
      if (user) {
        if (user?.isAdmin() || user?.isSite() || user?.isUser() || user?.isClient() || user?.isSubAgent()) {
          let syncExpressionsByUserType = [
            syncExpression(Employee, () => {
              return (emp) => emp.clientID("eq", userCompanyID.get());
            }),
            syncExpression(EmployeeClaims, () => {
              return (emp) => emp.clientID("eq", "123");
            }),
            syncExpression(ProviderInfo, () => {
              return (emp) => emp.clientID("eq", userCompanyID.get());
            }),
          ];

          syncExpressionsByUserType.push(
            syncExpression(Client, () => {
              return (emp) => emp.id("eq", userCompanyID.get());
            })
          );

          syncExpressionsByUserType.push(
            syncExpression(Site, () => {
              return (emp) => emp.clientID("eq", userCompanyID.get());
            })
          );

          syncExpressionsByUserType.push(
            syncExpression(SubAgent, () => {
              return (emp) => emp.clientID("eq", userCompanyID.get());
            })
          );

          syncExpressionsByUserType.push(
            syncExpression(CompanyStats, () => {
              return (emp) => emp.id("eq", userCompanyID.get());
            })
          );

          if (ADMIN_USERS.includes(user.phone_number)) {
            syncExpressionsByUserType.push(
              syncExpression(Users, () => {
                return (emp) =>
                  emp.or((test) => [
                    test.clientID("eq", userCompanyID.get()),
                    test.phone_number("eq", "+17777777777"),
                    test.phone_number("eq", "+18888888888"),
                  ]);
              })
            );
          } else {
            syncExpressionsByUserType.push(
              syncExpression(Users, () => {
                return (emp) => emp.clientID("eq", userCompanyID.get());
              })
            );
          }

          DataStore.configure({
            maxRecordsToSync: 90000,
            syncPageSize: 2000,
            ...(CONFIG.isLabType ? { syncExpressions: syncExpressionsByUserType } : {}),
          });
          await DataStore.stop();
          await DataStore.start();
          handleStart();
        } else {
          setLoading(false);
          setShowAlert(true);
        }
      } else {
        setLoading(false);
        console.log("push to login");
        history.push("/login");
      }
    };

    if (user) {
      getUSer().then((res) => {
        try {
          subscription = DataStore.observe(Employee).subscribe((msg) => {
            onSubscriptionTrigger();
          });
          companyStatsSubscrption = DataStore.observe(CompanyStats).subscribe((msg) => {
            if (window.location.href.includes("dashboard")) onCompanyStatsSubscriptionTrigger();
          });
          clientSubscription = DataStore.observe(Client).subscribe((msg) => {
            onCleintSubscriptionTrigger();
          });
          eligibilityFileSubscrption = DataStore.observe(EligibiliyFiles).subscribe((msg) => {
            onEligibilitySubscriptionTrigger();
          });
          apiSubscriptionUpdateClaim = API.graphql(graphqlOperation(subscriptions.onUpdateEmployeeClaims)).subscribe({
            next: ({ value: { data }, value }) => {
              let model = data.onUpdateEmployeeClaims;
              if (model.clientID === userCompanyID.get()) {
                dispatch(updateClaimSubs(model));
                if (window.location.href.includes("employeeclaim")) onClaimSummaryStatsSubscriptionTrigger();
              }
            },
            error: (error) => console.warn(error),
          });
          apiSubscriptionCreateClaim = API.graphql(graphqlOperation(subscriptions.onCreateEmployeeClaims)).subscribe({
            next: ({ value: { data }, value }) => {
              let model = data.onCreateEmployeeClaims;
              if (model.clientID === userCompanyID.get()) {
                dispatch(createClaimSubs(model));
                if (window.location.href.includes("employeeclaim")) onClaimSummaryStatsSubscriptionTrigger();
              }
            },
            error: (error) => console.warn(error),
          });
          apiSubscriptionDeleteClaim = API.graphql(graphqlOperation(subscriptions.onDeleteEmployeeClaims)).subscribe({
            next: ({ value: { data }, value }) => {
              let model = data.onDeleteEmployeeClaims;
              if (model.clientID === userCompanyID.get()) {
                dispatch(deleteClaimSubs(model));
                if (window.location.href.includes("employeeclaim")) onClaimSummaryStatsSubscriptionTrigger();
              }
            },
            error: (error) => console.warn(error),
          });
        } catch (err) {
          console.log("Error:-", err);
        }
      });
    }

    return () => {
      if (subscription) subscription.unsubscribe();
      if (companyStatsSubscrption) companyStatsSubscrption.unsubscribe();
      if (apiSubscriptionUpdateClaim) apiSubscriptionUpdateClaim.unsubscribe();
      if (apiSubscriptionCreateClaim) apiSubscriptionCreateClaim.unsubscribe();
      if (clientSubscription) clientSubscription.unsubscribe();
    };
  }, [user]);

  useEffect(() => {
    if (userSessionEnd) {
      setLoading(false);
      if (history) history.push("/login");
    }
  }, [userSessionEnd]);

  const fetchEmployees = async () => {
    const models = await api.getEmployees();
    setEmployees(models.filter((f) => f.email !== "adminmf@yopmail.com"));
  };

  const fetchLocations = async (value) => {
    const models = await api.getLocations(value);
    setLocations(formatLocations(models, companies));
  };
  const fetchSubAgents = async () => {
    const models = await api.getSubAgents();
    setSubAgents(models);
  };

  const fetchCompanies = async () => {
    const models = await api.getCompanies();
    setCompanies(models);
  };

  const fetchCompanySetting = async () => {
    const models = await api.getCompanySetting();
    setSetting(models);
  };
  const fetchEligibityFiles = async () => {
    const models = await api.getEligibilityFiles();
    setEligibilityFiles(models);
  };

  const fetchProviderInfo = async () => {
    const models = await api.getProviderInfo();
    setProviders(models);
  };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const fetchUserLocation = async () => {
    const location = await api.getUserSite();
    setUserLocation(location);
    setLocations([location]);
  };

  const fetchUserSubAgent = async () => {
    const subAgent = await api.getUserSubAgent();
    setUserSubAgent(subAgent);
    setSubAgents([subAgent]);
  };

  const fetchUserCompany = async () => {
    const company = await api.getUserCompany();
    const defaultSubAgent = await api.getUserDefaultSubAgent();

    setUserSubAgent(defaultSubAgent);
    setUserCompany(company);
    setCompanies([company]);
  };

  const fetchEmployeeID = async () => {
    const item = await api.getLoggedInUserEmployeeID(null, user.preferred_username);
    patientDataInLocalStorage.save(item);
    setEmployeeRecord(item);
  };

  const getClientById = (id) => {
    if (!id) return null;
    if (companies) {
      const client = companies.find((c) => c.id === id);
      if (client) return { ...client, label: client.name, value: client.id };
    }
    return null;
  };

  const getLocationById = (id) => {
    if (!id) return null;
    if (locations) {
      const location = locations.find((c) => c.id === id);
      if (location) return { ...location, label: location.name, value: location.id };
    }
    return null;
  };

  const getTotalOrders = () => {
    let ttlOrders = 0;
    if (!userCompanyID.get()) {
      ttlOrders = companies.reduce((num, c) => {
        if (c.totalOrders) {
          num = num + c.totalOrders;
        }

        return num;
      }, 0);
    }

    if (user?.isClient()) {
      ttlOrders = userCompany?.totalOrders || 0;
    } else if (user?.isSite()) {
      ttlOrders = userLocation?.totalOrders || 0;
    } else if (user?.isSubAgent()) {
      ttlOrders = userSubAgent?.totalOrders || 0;
    }
    return ttlOrders;
  };

  const getEligibilityFileObject = (params) => {
    const obj = {
      clientName: null,
      locationName: null,
      subAgentName: null,
      fileType: params.fileType,
      fileName: params.fileName,
      totalRecord: params.ttlRecord,
    };
    if (user?.isClient()) {
      Object.assign(obj, {
        clientID: userCompany.id,
      });
      nameData.clientName = userCompany.name;
    } else if (user?.isSite()) {
      Object.assign(obj, {
        clientID: userLocation.clientID,
        locationID: userLocation.id,
      });
      obj.clientName = companies.find((company) => company.id === userLocation.clientID).name;
      obj.locationName = userLocation.name;
    } else if (user?.isSubAgent()) {
      Object.assign(obj, {
        clientID: userSubAgent.clientID,
        locationID: userSubAgent.locationID,
        subAgentID: userSubAgent.id,
      });
      obj.clientName = companies.find((company) => company.id === userSubAgent.clientID).name;
      obj.locationName = locations.find((loc) => loc.id === userSubAgent.locationID).name;
      obj.subAgentName = userSubAgent.name;
    }
    return obj;
  };

  const updateTestAvailableQty = (Ids, testQty) => {
    const updateEmps = employees.map((m) => {
      if (Ids.includes(m.id)) {
        return { ...m, testAvailable: testQty };
      }
      return { ...m };
    });
    setEmployees(updateEmps);
  };

  const customRoutes = (routes, response) => {
    if (!response) return [];
    if (isReadOnly()) {
      return routes.filter((f) => !filterRoutesPath.includes(f.path));
    }
    if (ADMIN_USERS.includes(response.phone_number)) {
      return routes;
    }

    const permission = response.userPermission || DEFAULT_USRERS_PERMISSIONS;

    if (CONFIG.isLabType) {
      let BRMRoutes = routes.filter((f) => permission[LAB_ROTUES[f.path]]?.access);
      if (user?.isClient()) {
        BRMRoutes = BRMRoutes.filter((f) => f.path !== "/users");
      }
      return BRMRoutes;
    }

    return routes.filter((f) => f.path !== "/billing");
  };

  const contextSwitch = (role) => {
    if (user?.isAdmin()) {
      return {
        user,
        users,
        employees,
        locations,
        companies,
        subAgents,
        users,
        userSubAgent,
        userLocation,
        eligibilityFiles,
        providers,
        setting,
        permission,
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        getEligibilityFileObject: (obj) => getEligibilityFileObject(obj),
        getClientById: (id) => getClientById(id),
        getLocationById: (id) => getLocationById(id),
        resetEmployees: () => fetchEmployees(),
        resetSubAgents: () => fetchSubAgents(),
        resetLocations: () => fetchLocations(),
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        resetUsers: () => fetchUserGroups(),
        resetProviders: () => fetchProviderInfo(),
        admins,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isClient()) {
      return {
        user,
        userCompany,
        users,
        employees,
        locations,
        companies,
        subAgents,
        userSubAgent,
        eligibilityFiles,
        providers,
        setting,
        permission,
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        getClientById: (id) => getClientById(id),
        getLocationById: (id) => getLocationById(id),
        resetEmployees: () => fetchEmployees(),
        resetSubAgents: () => fetchSubAgents(),
        resetLocations: () => fetchLocations(true),
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        resetUsers: () => fetchUserGroups(),
        resetProviders: () => fetchProviderInfo(),
        admins,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isSite()) {
      return {
        userLocation,
        user,
        employees,
        locations,
        users,
        subAgents,
        companies,
        eligibilityFiles,
        setting,
        permission,
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        getLocationById: (id) => getLocationById(id),
        getClientById: (id) => getClientById(id),
        resetEmployees: () => fetchEmployees(),
        resetSubAgents: () => fetchSubAgents(),
        resetUsers: () => fetchUserGroups(),
        testFilter,
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isSubAgent()) {
      return {
        userSubAgent,
        user,
        employees,
        users,
        subAgents,
        companies,
        locations,
        eligibilityFiles,
        setting,
        isReadOnly: () => isReadOnly(),
        updateTestAvailableQty: (Ids, testQty) => updateTestAvailableQty(Ids, testQty),
        getTotalOrders: () => getTotalOrders(),
        getClientById: (id) => getClientById(id),
        resetEmployees: () => fetchEmployees(),
        resetUsers: () => fetchUserGroups(),
        testFilter,
        resetCompanies: () => fetchCompanies(),
        resetCompanySetting: () => fetchCompanySetting(),
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
    if (user?.isUser()) {
      return {
        userLocation,
        employeeRecord,
        user,
        locations,
        userSubAgent,
        userLocation,
        subAgents,
        isReadOnly: () => isReadOnly(),
        getTotalOrders: () => getTotalOrders(),
        resetEmployeeRecord: () => fetchEmployeeID(),
        resetUsers: () => fetchUserGroups(),
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
  };

  const adminListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        dispatch(getListenerData(user));
        fetchCompanies();
        fetchCompanySetting();
        fetchLocations();
        fetchEmployees();
        fetchSubAgents();
        fetchProviderInfo();
        fetchEligibityFiles();
        fetchUserGroups();
        // setUserRoutes(customRoutes(CONFIG.isLabType ? labRoutes : adminRoutes, data?.response));
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const clientListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        dispatch(getListenerData(user));
        fetchUserCompany();
        fetchCompanySetting();
        fetchLocations(true);
        fetchEmployees();
        fetchSubAgents();
        fetchProviderInfo();
        fetchEligibityFiles();
        fetchUserGroups();
        // setUserRoutes(customRoutes(CONFIG.isLabType ? labRoutes : clientRoutes, data?.response));
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const locationListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchCompanies();
        fetchUserLocation();
        fetchCompanySetting();
        fetchSubAgents();
        fetchLocations();
        fetchEmployees();
        fetchEligibityFiles();
        // setUserRoutes(siteRoutes);
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const subAgentListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchCompanies();
        fetchUserSubAgent();
        fetchCompanySetting();
        fetchLocations();
        fetchEmployees();
        fetchEligibityFiles();
        // setUserRoutes(subAgentRoutes);
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };
  const userListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchEmployeeID();
        fetchUserLocation();
        fetchUserSubAgent();
        // setUserRoutes(patientRoutes);
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    if (data || !CONFIG.isLabType) {
      user?.isAdmin() && adminListener();
      user?.isClient() && clientListener();
      user?.isSite() && locationListener();
      user?.isUser() && userListener();
      user?.isSubAgent() && subAgentListener();
    }
  }, [user, data]);

  const alertPropObj = {
    [messageObj.mode]: true,
  };

  return (
    <>
      {!loading ? (
        <div className="wrapper">
          {showAlert && (
            <ShowAlertMessage
              message={"Your account does not have access to the Portal"}
              handleClose={async () => {
                await Auth.signOut();
                dispatch(logOut());
                window.location.reload();
              }}
              error
            />
          )}
          {successMessageText && (
            <ShowAlertMessage message={successMessageText} handleClose={() => setSuccessMessageText("")} success />
          )}
          {errorMessageText && (
            <ShowAlertMessage message={errorMessageText} handleClose={() => setErrorMessageText("")} error />
          )}
          {messageObj.text && (
            <ShowAlertMessage
              message={messageObj.text}
              handleClose={() => dispatch(showMessage())}
              {...{ [messageObj.mode]: true }}
            />
          )}
          <Sidebar
            color={color}
            image={""}
            routes={userRoutes}
            user={user}
            testFilter={testFilter}
            setTestFilter={setTestFilter}
            imageName={logo}
          />

          <div className="main-panel" ref={mainPanel}>
            <AdminNavbar
              routes={userRoutes}
              role={user?.roles[0]}
              user={user}
              userLocation={userLocation}
              userCompany={userCompany}
              eligibilityFiles={eligibilityFiles}
              setErrorMessageText={setErrorMessageText}
              setSuccessMessageText={setSuccessMessageText}
            />
            <div className="content">
              <AppContext.Provider value={contextSwitch(user?.roles[0])}>
                <Switch>{getRoutes(userRoutes)}</Switch>
                {getAdminRoutes(userRoutes)}
              </AppContext.Provider>
            </div>
            {/* <Footer /> */}
            {loader && <Loader />}
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <IdleModal
        showChildModal="showChildModal"
        showIdleModal={showIdleModal}
        animation={true}
        handleLogOut={handleLogOut}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default Admin;
