/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      firstName
      middleName
      lastName
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      clientID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      clientID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      clientID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmployeeOrder = /* GraphQL */ `
  mutation CreateEmployeeOrder(
    $input: CreateEmployeeOrderInput!
    $condition: ModelEmployeeOrderConditionInput
  ) {
    createEmployeeOrder(input: $input, condition: $condition) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmployeeOrder = /* GraphQL */ `
  mutation UpdateEmployeeOrder(
    $input: UpdateEmployeeOrderInput!
    $condition: ModelEmployeeOrderConditionInput
  ) {
    updateEmployeeOrder(input: $input, condition: $condition) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmployeeOrder = /* GraphQL */ `
  mutation DeleteEmployeeOrder(
    $input: DeleteEmployeeOrderInput!
    $condition: ModelEmployeeOrderConditionInput
  ) {
    deleteEmployeeOrder(input: $input, condition: $condition) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAppSetting = /* GraphQL */ `
  mutation CreateAppSetting(
    $input: CreateAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    createAppSetting(input: $input, condition: $condition) {
      id
      code
      employeeCounter
      orderId
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAppSetting = /* GraphQL */ `
  mutation UpdateAppSetting(
    $input: UpdateAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    updateAppSetting(input: $input, condition: $condition) {
      id
      code
      employeeCounter
      orderId
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppSetting = /* GraphQL */ `
  mutation DeleteAppSetting(
    $input: DeleteAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    deleteAppSetting(input: $input, condition: $condition) {
      id
      code
      employeeCounter
      orderId
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createInsuranceCompanies = /* GraphQL */ `
  mutation CreateInsuranceCompanies(
    $input: CreateInsuranceCompaniesInput!
    $condition: ModelInsuranceCompaniesConditionInput
  ) {
    createInsuranceCompanies(input: $input, condition: $condition) {
      id
      code
      payerType
      name
      idFormat
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateInsuranceCompanies = /* GraphQL */ `
  mutation UpdateInsuranceCompanies(
    $input: UpdateInsuranceCompaniesInput!
    $condition: ModelInsuranceCompaniesConditionInput
  ) {
    updateInsuranceCompanies(input: $input, condition: $condition) {
      id
      code
      payerType
      name
      idFormat
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteInsuranceCompanies = /* GraphQL */ `
  mutation DeleteInsuranceCompanies(
    $input: DeleteInsuranceCompaniesInput!
    $condition: ModelInsuranceCompaniesConditionInput
  ) {
    deleteInsuranceCompanies(input: $input, condition: $condition) {
      id
      code
      payerType
      name
      idFormat
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubAgent = /* GraphQL */ `
  mutation CreateSubAgent(
    $input: CreateSubAgentInput!
    $condition: ModelSubAgentConditionInput
  ) {
    createSubAgent(input: $input, condition: $condition) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubAgent = /* GraphQL */ `
  mutation UpdateSubAgent(
    $input: UpdateSubAgentInput!
    $condition: ModelSubAgentConditionInput
  ) {
    updateSubAgent(input: $input, condition: $condition) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubAgent = /* GraphQL */ `
  mutation DeleteSubAgent(
    $input: DeleteSubAgentInput!
    $condition: ModelSubAgentConditionInput
  ) {
    deleteSubAgent(input: $input, condition: $condition) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEligibiliyFiles = /* GraphQL */ `
  mutation CreateEligibiliyFiles(
    $input: CreateEligibiliyFilesInput!
    $condition: ModelEligibiliyFilesConditionInput
  ) {
    createEligibiliyFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEligibiliyFiles = /* GraphQL */ `
  mutation UpdateEligibiliyFiles(
    $input: UpdateEligibiliyFilesInput!
    $condition: ModelEligibiliyFilesConditionInput
  ) {
    updateEligibiliyFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEligibiliyFiles = /* GraphQL */ `
  mutation DeleteEligibiliyFiles(
    $input: DeleteEligibiliyFilesInput!
    $condition: ModelEligibiliyFilesConditionInput
  ) {
    deleteEligibiliyFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createClaimFiles = /* GraphQL */ `
  mutation CreateClaimFiles(
    $input: CreateClaimFilesInput!
    $condition: ModelClaimFilesConditionInput
  ) {
    createClaimFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateClaimFiles = /* GraphQL */ `
  mutation UpdateClaimFiles(
    $input: UpdateClaimFilesInput!
    $condition: ModelClaimFilesConditionInput
  ) {
    updateClaimFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteClaimFiles = /* GraphQL */ `
  mutation DeleteClaimFiles(
    $input: DeleteClaimFilesInput!
    $condition: ModelClaimFilesConditionInput
  ) {
    deleteClaimFiles(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEmployeeClaims = /* GraphQL */ `
  mutation CreateEmployeeClaims(
    $input: CreateEmployeeClaimsInput!
    $condition: ModelEmployeeClaimsConditionInput
  ) {
    createEmployeeClaims(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEmployeeClaims = /* GraphQL */ `
  mutation UpdateEmployeeClaims(
    $input: UpdateEmployeeClaimsInput!
    $condition: ModelEmployeeClaimsConditionInput
  ) {
    updateEmployeeClaims(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEmployeeClaims = /* GraphQL */ `
  mutation DeleteEmployeeClaims(
    $input: DeleteEmployeeClaimsInput!
    $condition: ModelEmployeeClaimsConditionInput
  ) {
    deleteEmployeeClaims(input: $input, condition: $condition) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createProviderInfo = /* GraphQL */ `
  mutation CreateProviderInfo(
    $input: CreateProviderInfoInput!
    $condition: ModelProviderInfoConditionInput
  ) {
    createProviderInfo(input: $input, condition: $condition) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      taxid
      speciality
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      isDefault
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProviderInfo = /* GraphQL */ `
  mutation UpdateProviderInfo(
    $input: UpdateProviderInfoInput!
    $condition: ModelProviderInfoConditionInput
  ) {
    updateProviderInfo(input: $input, condition: $condition) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      taxid
      speciality
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      isDefault
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProviderInfo = /* GraphQL */ `
  mutation DeleteProviderInfo(
    $input: DeleteProviderInfoInput!
    $condition: ModelProviderInfoConditionInput
  ) {
    deleteProviderInfo(input: $input, condition: $condition) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      taxid
      speciality
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      isDefault
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCompanyStats = /* GraphQL */ `
  mutation CreateCompanyStats(
    $input: CreateCompanyStatsInput!
    $condition: ModelCompanyStatsConditionInput
  ) {
    createCompanyStats(input: $input, condition: $condition) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCompanyStats = /* GraphQL */ `
  mutation UpdateCompanyStats(
    $input: UpdateCompanyStatsInput!
    $condition: ModelCompanyStatsConditionInput
  ) {
    updateCompanyStats(input: $input, condition: $condition) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCompanyStats = /* GraphQL */ `
  mutation DeleteCompanyStats(
    $input: DeleteCompanyStatsInput!
    $condition: ModelCompanyStatsConditionInput
  ) {
    deleteCompanyStats(input: $input, condition: $condition) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
