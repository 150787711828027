import React from "react";
import filterIcon from "../assets/img/Filter-icon.svg";
import filterGreyIcon from "../assets/img/filter.svg";
import selectAllIcon from "../assets/img/Select-All-Icon.svg";
import selectAllGreyIcon from "../assets/img/window.svg";
import binIcon from "../assets/img/Delete-Icon.svg";
import binGreyIcon from "../assets/img/bin.svg";
import addUserGreyIcon from "../assets/img/addUser.svg";
import addUserIcon from "../assets/img/Create-Employee-icon.svg";
import loadCrewGreyIcon from "../assets/img/UserInfo.svg";
import loadCrewIcon from "../assets/img/Load-Crew-Icon.svg";
import XLSGreyIcon from "../assets/img/downloadXLS.svg";
import XLSIcon from "../assets/img/Export-Excel-Icon.svg";
import personalizeGreyIcon from "../assets/img/viewUser.svg";
import personalizeIcon from "../assets/img/Personalize-Icon.svg";
import testNowGreyIcon from "../assets/img/testUser.svg";
import testNowIcon from "../assets/img/Test-Now-icon.svg";
import assignZoneGreyIcon from "../assets/img/pinUser.svg";
import assignZoneIcon from "../assets/img/Assign-Zone-Icon.svg";
import removeZoneGreyIcon from "../assets/img/pinCross.svg";
import removeZoneIcon from "../assets/img/Remove-Zone-Icon.svg";
import acknowledgedPositiveGreyIcon from "../assets/img/tickMark.svg";
import acknowledgedPositiveIcon from "../assets/img/Acknowl-Positive-icon.svg";
import messageGreyIcon from "../assets/img/message.svg";
import messageIcon from "../assets/img/Send-Email-Icon.svg";
import qrGreyIcon from "../assets/img/qr.svg";
import qrIcon from "../assets/img/Send-QR-Icon.svg";
import groupByDepartmentIcon from "../assets/img/groupByDepartment.svg";
import groupByZoneIcon from "../assets/img/groupByZone.svg";
import endQuarantineIcon from "../assets/img/endQuarantine.svg";
import createIcon from "../assets/img/create.svg";
import importIcon from "../assets/img/import.svg";
import downloadIcon from "../assets/img/download.svg";
import generalCallTimeIcon from "../assets/img/generalCallTime.svg";
import employeeIcon from "../assets/img/employee-icon.png";
import checkEligibilityIcon from "../assets/img/Show-All-Icon.svg";
import denialClaim from "../assets/img/Clear-Work-Icon.svg";
import editIcon from "../assets/img/edit-icon.svg";
const Icon = (props) => {
  const { handleClick, label, iconType, disabled, title, style, className } = props;

  const ICONS = {
    filter: filterIcon,
    selectAllIcon: selectAllIcon,
    binIcon: binIcon,
    addUserIcon: addUserIcon,
    loadCrewIcon: loadCrewIcon,
    XLSIcon: XLSIcon,
    personalizeIcon: personalizeIcon,
    testNowIcon: testNowIcon,
    assignZoneIcon: assignZoneIcon,
    removeZoneIcon: removeZoneIcon,
    acknowledgedPositiveIcon: acknowledgedPositiveIcon,
    messageIcon: messageIcon,
    qrIcon: qrIcon,
    groupByDepartmentIcon: groupByDepartmentIcon,
    groupByZoneIcon: groupByZoneIcon,
    endQuarantineIcon: endQuarantineIcon,
    createIcon: createIcon,
    importIcon: importIcon,
    downloadIcon: downloadIcon,
    generalCallTimeIcon: generalCallTimeIcon,
    employeeIcon: employeeIcon,
    checkEligibilityIcon: checkEligibilityIcon,
    denialClaim: denialClaim,
    editIcon: editIcon,
  };

  const DISABLED_ICON = {
    filter: filterGreyIcon,
    selectAllIcon: selectAllGreyIcon,
    binIcon: binGreyIcon,
    addUserIcon: addUserGreyIcon,
    loadCrewIcon: loadCrewGreyIcon,
    XLSIcon: XLSGreyIcon,
    personalizeIcon: personalizeGreyIcon,
    testNowIcon: testNowGreyIcon,
    assignZoneIcon: assignZoneGreyIcon,
    removeZoneIcon: removeZoneGreyIcon,
    acknowledgedPositiveIcon: acknowledgedPositiveGreyIcon,
    messageIcon: messageGreyIcon,
    qrIcon: qrGreyIcon,
    endQuarantineIcon: endQuarantineIcon,
    createIcon: createIcon,
    importIcon: importIcon,
    downloadIcon: downloadIcon,
    generalCallTimeIcon: generalCallTimeIcon,
  };

  return (
    <div className={`header-btns ${className ?? ""}`} title={title} onClick={() => !disabled && handleClick()}>
      <img
        className="header-btn-img"
        src={ICONS[iconType]}
        alt={`${iconType}`}
        style={{ opacity: disabled ? "0.5" : "" }}
      />
      <span className="header-btn-labels mx-auto ellipsis">{label}</span>
    </div>
  );
};

export default Icon;
