/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onCreateEmployee(filter: $filter) {
      id
      firstName
      middleName
      lastName
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onUpdateEmployee(filter: $filter) {
      id
      firstName
      middleName
      lastName
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onDeleteEmployee(filter: $filter) {
      id
      firstName
      middleName
      lastName
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSite = /* GraphQL */ `
  subscription OnCreateSite($filter: ModelSubscriptionSiteFilterInput) {
    onCreateSite(filter: $filter) {
      id
      clientID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSite = /* GraphQL */ `
  subscription OnUpdateSite($filter: ModelSubscriptionSiteFilterInput) {
    onUpdateSite(filter: $filter) {
      id
      clientID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSite = /* GraphQL */ `
  subscription OnDeleteSite($filter: ModelSubscriptionSiteFilterInput) {
    onDeleteSite(filter: $filter) {
      id
      clientID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($filter: ModelSubscriptionClientFilterInput) {
    onCreateClient(filter: $filter) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($filter: ModelSubscriptionClientFilterInput) {
    onUpdateClient(filter: $filter) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($filter: ModelSubscriptionClientFilterInput) {
    onDeleteClient(filter: $filter) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEmployeeOrder = /* GraphQL */ `
  subscription OnCreateEmployeeOrder(
    $filter: ModelSubscriptionEmployeeOrderFilterInput
  ) {
    onCreateEmployeeOrder(filter: $filter) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEmployeeOrder = /* GraphQL */ `
  subscription OnUpdateEmployeeOrder(
    $filter: ModelSubscriptionEmployeeOrderFilterInput
  ) {
    onUpdateEmployeeOrder(filter: $filter) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEmployeeOrder = /* GraphQL */ `
  subscription OnDeleteEmployeeOrder(
    $filter: ModelSubscriptionEmployeeOrderFilterInput
  ) {
    onDeleteEmployeeOrder(filter: $filter) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAppSetting = /* GraphQL */ `
  subscription OnCreateAppSetting(
    $filter: ModelSubscriptionAppSettingFilterInput
  ) {
    onCreateAppSetting(filter: $filter) {
      id
      code
      employeeCounter
      orderId
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAppSetting = /* GraphQL */ `
  subscription OnUpdateAppSetting(
    $filter: ModelSubscriptionAppSettingFilterInput
  ) {
    onUpdateAppSetting(filter: $filter) {
      id
      code
      employeeCounter
      orderId
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAppSetting = /* GraphQL */ `
  subscription OnDeleteAppSetting(
    $filter: ModelSubscriptionAppSettingFilterInput
  ) {
    onDeleteAppSetting(filter: $filter) {
      id
      code
      employeeCounter
      orderId
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateInsuranceCompanies = /* GraphQL */ `
  subscription OnCreateInsuranceCompanies(
    $filter: ModelSubscriptionInsuranceCompaniesFilterInput
  ) {
    onCreateInsuranceCompanies(filter: $filter) {
      id
      code
      payerType
      name
      idFormat
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateInsuranceCompanies = /* GraphQL */ `
  subscription OnUpdateInsuranceCompanies(
    $filter: ModelSubscriptionInsuranceCompaniesFilterInput
  ) {
    onUpdateInsuranceCompanies(filter: $filter) {
      id
      code
      payerType
      name
      idFormat
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteInsuranceCompanies = /* GraphQL */ `
  subscription OnDeleteInsuranceCompanies(
    $filter: ModelSubscriptionInsuranceCompaniesFilterInput
  ) {
    onDeleteInsuranceCompanies(filter: $filter) {
      id
      code
      payerType
      name
      idFormat
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onCreateUsers(filter: $filter) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onUpdateUsers(filter: $filter) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
    onDeleteUsers(filter: $filter) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSubAgent = /* GraphQL */ `
  subscription OnCreateSubAgent($filter: ModelSubscriptionSubAgentFilterInput) {
    onCreateSubAgent(filter: $filter) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSubAgent = /* GraphQL */ `
  subscription OnUpdateSubAgent($filter: ModelSubscriptionSubAgentFilterInput) {
    onUpdateSubAgent(filter: $filter) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSubAgent = /* GraphQL */ `
  subscription OnDeleteSubAgent($filter: ModelSubscriptionSubAgentFilterInput) {
    onDeleteSubAgent(filter: $filter) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEligibiliyFiles = /* GraphQL */ `
  subscription OnCreateEligibiliyFiles(
    $filter: ModelSubscriptionEligibiliyFilesFilterInput
  ) {
    onCreateEligibiliyFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEligibiliyFiles = /* GraphQL */ `
  subscription OnUpdateEligibiliyFiles(
    $filter: ModelSubscriptionEligibiliyFilesFilterInput
  ) {
    onUpdateEligibiliyFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEligibiliyFiles = /* GraphQL */ `
  subscription OnDeleteEligibiliyFiles(
    $filter: ModelSubscriptionEligibiliyFilesFilterInput
  ) {
    onDeleteEligibiliyFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateClaimFiles = /* GraphQL */ `
  subscription OnCreateClaimFiles(
    $filter: ModelSubscriptionClaimFilesFilterInput
  ) {
    onCreateClaimFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateClaimFiles = /* GraphQL */ `
  subscription OnUpdateClaimFiles(
    $filter: ModelSubscriptionClaimFilesFilterInput
  ) {
    onUpdateClaimFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteClaimFiles = /* GraphQL */ `
  subscription OnDeleteClaimFiles(
    $filter: ModelSubscriptionClaimFilesFilterInput
  ) {
    onDeleteClaimFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEmployeeClaims = /* GraphQL */ `
  subscription OnCreateEmployeeClaims(
    $filter: ModelSubscriptionEmployeeClaimsFilterInput
  ) {
    onCreateEmployeeClaims(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEmployeeClaims = /* GraphQL */ `
  subscription OnUpdateEmployeeClaims(
    $filter: ModelSubscriptionEmployeeClaimsFilterInput
  ) {
    onUpdateEmployeeClaims(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEmployeeClaims = /* GraphQL */ `
  subscription OnDeleteEmployeeClaims(
    $filter: ModelSubscriptionEmployeeClaimsFilterInput
  ) {
    onDeleteEmployeeClaims(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProviderInfo = /* GraphQL */ `
  subscription OnCreateProviderInfo(
    $filter: ModelSubscriptionProviderInfoFilterInput
  ) {
    onCreateProviderInfo(filter: $filter) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      taxid
      speciality
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      isDefault
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProviderInfo = /* GraphQL */ `
  subscription OnUpdateProviderInfo(
    $filter: ModelSubscriptionProviderInfoFilterInput
  ) {
    onUpdateProviderInfo(filter: $filter) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      taxid
      speciality
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      isDefault
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProviderInfo = /* GraphQL */ `
  subscription OnDeleteProviderInfo(
    $filter: ModelSubscriptionProviderInfoFilterInput
  ) {
    onDeleteProviderInfo(filter: $filter) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      taxid
      speciality
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      isDefault
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCompanyStats = /* GraphQL */ `
  subscription OnCreateCompanyStats(
    $filter: ModelSubscriptionCompanyStatsFilterInput
  ) {
    onCreateCompanyStats(filter: $filter) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCompanyStats = /* GraphQL */ `
  subscription OnUpdateCompanyStats(
    $filter: ModelSubscriptionCompanyStatsFilterInput
  ) {
    onUpdateCompanyStats(filter: $filter) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCompanyStats = /* GraphQL */ `
  subscription OnDeleteCompanyStats(
    $filter: ModelSubscriptionCompanyStatsFilterInput
  ) {
    onDeleteCompanyStats(filter: $filter) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
