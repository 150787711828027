import api from "api";
import Loader from "components/Loader/Loader";
import MainTable from "components/Table/MainTable";
import { CONFIG } from "constant";
import { INSURANCE_PAID_CLAIM_DETAILS_TABLE } from "constants/personalization";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { userCompanyID } from "utils";

const InsurancePaidClaimsModal = (props) => {
  const { handleClose, insuranceId } = props;
  const [claims, setClaims] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClaims();
  }, [insuranceId]);

  const getClaims = async () => {
    setLoading(true);
    const params = {
      payerid: insuranceId,
      status: "paid",
    };

    if (CONFIG.isLabType) {
      Object.assign(params, { clientID: userCompanyID.get() });
    }
    const res = await api.getClaimList(params);
    setClaims(res.rows);
    setLoading(false);
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={handleClose} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Paid Claim Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="allowVerticalScroll" style={{ paddingTop: 0 }}>
        <div className="d-flex justify-content-between mb-4">
          <div className="paidClaim-subHeading">
            <span className="fw-bold">Payer ID: </span>
            <span className="text-capitalize">{claims.length > 0 ? claims[0].payerid : "-"}</span>
          </div>
          <div className="paidClaim-subHeading">
            <span className="fw-bold">Payer Name: </span>
            <span className="text-capitalize">{claims.length > 0 ? claims[0].payer_name : "-"}</span>
          </div>
        </div>
        <MainTable columns={INSURANCE_PAID_CLAIM_DETAILS_TABLE} rows={claims} cssClass={"non-scrollable-table"} />
      </Modal.Body>
      {loading && <Loader />}
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InsurancePaidClaimsModal;
