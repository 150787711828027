import api from "api";
import InputField from "components/InputField/InputField";
import { MESSAGE_MODES, currencyformatter, CONFIG, INSURANCE_PROVIDER } from "constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { employeesListSelector } from "store/features/employees/employeesSelectors";
import ErrorMessage from "../../../components/Message/ErrorMessage";
import { setMessage } from "store/features/general/generalAction";
import { t } from "../../../stringConstants";
import { convertYYYYMMDDToDate, formatPhoneNumber, isSimilarPayer } from "../../../utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";

const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const EligibilityDetailsModal = (props) => {
  const { handleClose, data, selectedEligibility } = props;
  const [newUser, setNewUser] = useState(data);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [jumpToSection, setJumpToSection] = useState(null);
  const employeesList = useSelector(employeesListSelector);
  const loginUser = useSelector(selectLoginUser);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const eligBaseCondition = (f) => f.benefit_code === "30" && f.benefit_coverage_code === "1";
  const medicareEligCondition = (f) =>
    eligBaseCondition(f) && f.insurance_type_description.toLowerCase() === "medicare part b";

  useEffect(() => {
    if (!data) return;

    // Extract error messages and set them if they exist
    if (data.error?.length) {
      const errorMessage = data.error.map(({ error_mesg }) => error_mesg).join(",");
      setError(errorMessage);
    }

    // Process benefits
    const sortedBenefits = [];
    let additionalPayerName;
    let healthCareBenefitPlan =
      data.payerId === "01192" ? data.benefit?.find(medicareEligCondition) : data.benefit?.find(eligBaseCondition);

    data.benefit?.forEach((benefit) => {
      const { benefit_code, entity_description, entity_name } = benefit;

      if (!benefit_code) return;

      // set additional payers if any
      if (benefit_code === "30" && entity_description?.includes("Primary Payer")) {
        additionalPayerName = benefit;
      }
      if (benefit_code === "30") {
        sortedBenefits.unshift(benefit);
      } else {
        sortedBenefits.push(benefit);
      }
    });

    // Extract start and end dates
    const [planStartDate, planEndDate] = data.plan_begin_date?.split("-") || data.plan_date?.split("-") || [];

    // get Additional Payer Id from Insurance List
    const payerName = additionalPayerName?.entity_name?.[0];

    if (payerName) {
      const additionalPayerElgibility = INSURANCE_PROVIDER.find((provider) => isSimilarPayer(provider, payerName));
      if (additionalPayerElgibility?.value) {
        Object.assign(additionalPayerName, { additionalPayerId: additionalPayerElgibility.value });
      }
    }

    // Create the newUser object
    const newUser = {
      ...data,
      additionalPayerName,
      benefit: sortedBenefits,
      ...(planStartDate && { planStartDate: moment(planStartDate).format("MMM DD, YYYY") }),
      ...(((planStartDate && !planEndDate) || planEndDate === "99991231") && { planEndDate: "toDate" }),
      ...(planEndDate && planEndDate !== "99991231" && { planEndDate: moment(planEndDate).format("MMM DD, YYYY") }),
    };

    setNewUser(newUser);
  }, [data]);

  useEffect(() => {
    if (selectedEligibility) setSelectedUser(employeesList.find((emp) => emp.id === selectedEligibility.employeeID));
  }, [employeesList]);

  useEffect(() => {
    setGlobalSearch(jumpToSection?.label || "");
  }, [jumpToSection]);

  const handleUpdatePatient = async () => {
    const { payerId } = selectedEligibility;
    const { ins_name_f, ins_name_l, ins_dob, ins_number } = data;

    if (validateFieldsAreSimilar()) return;

    try {
      const updatedEmp = {
        ...selectedUser,
        firstName: ins_name_f,
        lastName: ins_name_l,
        dob: convertYYYYMMDDToDate(ins_dob, "YYYY-MM-DD"),
        medicalNo: ins_number,
        medicareNo: payerId === "01192" ? ins_number : emp.medicareNo,
      };
      await api.updateEmployeePersonals(updatedEmp);
      dispatch(setMessage("Updated successfully.", MESSAGE_MODES.success));
    } catch (error) {
      console.log(error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const CollapsableRow = ({ user }) => {
    const additionalPayorInfo = JSON.stringify(user).includes("Additional Payor");
    return (
      <>
        <Card className="p-3">
          <div>
            <label className="modalLineHeaders fw-bold">Insurance Type: </label>
            <div className="form-group-eligibity-wrapper">
              {user?.insurance_type_code && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Code: </label>
                  <span>{user.insurance_type_code}</span>
                </div>
              )}
              {user?.insurance_type_description && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Description: </label>
                  <span>{user.insurance_type_description}</span>
                </div>
              )}
              {user.benefit && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Coords of Beneifts: </label>
                  <span>{convertYYYYMMDDToDate(user.benefit)}</span>
                </div>
              )}
            </div>
            <hr />
          </div>
          <div>
            <label className="modalLineHeaders fw-bold">Plan: </label>
            <div className="form-group-eligibity-wrapper">
              {user?.plan_date && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Date: </label>
                  <span>{convertYYYYMMDDToDate(user.plan_date)}</span>
                </div>
              )}
              {user.plan_number && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Number: </label>
                  <span>{user.plan_number}</span>
                </div>
              )}
              {user.mco_number && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Netwrok Id: </label>
                  <span>{user.mco_number}</span>
                </div>
              )}
            </div>
            <hr />
          </div>

          {additionalPayorInfo && (
            <div>
              <label className="modalLineHeaders fw-bold">Primary Payer: </label>
              <div className="form-group-eligibity-wrapper">
                {user.entity_name?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Payer: </label>
                    <span>{user.entity_name[0]}</span>
                  </div>
                )}
                {user.entity_addr_1?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Address: </label>
                    <span>{user.entity_addr_1 && user.entity_addr_1[0]}</span>
                  </div>
                )}
                {(user.entity_city || user.entity_state) && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">City: </label>
                    <span>
                      {user.entity_city && user.entity_city[0]}, {user.entity_state && user.entity_state[0]}
                    </span>
                  </div>
                )}
              </div>
              <div className="form-group-eligibity-wrapper">
                {user.entity_zip?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Zip: </label>
                    <span>{user.entity_zip[0]}</span>
                  </div>
                )}
                {user.entity_phone?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Phone: </label>
                    <span>{user.entity_phone[0]}</span>
                  </div>
                )}
                {user.entity_website?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Website: </label>
                    <span className="custom-ellipsis d-block" title={user.entity_website[0]}>
                      {user.entity_website[0]}
                    </span>
                  </div>
                )}
              </div>
              <hr />
            </div>
          )}
          <div>
            <label className="modalLineHeaders fw-bold d-block">Notes: </label>
            <span>{user?.entity_name?.join(",")}</span>
          </div>
        </Card>
      </>
    );
  };

  const TableRow = ({ user, index }) => {
    if (!JSON.stringify(user).toLowerCase().includes(globalSearch.toLowerCase())) return <></>;
    const [open, setOpen] = useState(false);
    return (
      <>
        <tr key={index} className="cursor-pointer" onClick={() => setOpen(!open)}>
          <td style={{ width: "10%" }}>{index + 1}</td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.benefit_code}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.benefit_description}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.benefit_coverage_description}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.plan_date ? convertYYYYMMDDToDate(user.plan_date) : "-"}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {`${currencyformatter.format(user.benefit_amount || 0)}`}
          </td>
        </tr>
        {open && (
          <tr className="additionalInfo-row">
            <td colSpan={6}>
              <CollapsableRow user={user} />
            </td>
          </tr>
        )}
      </>
    );
  };

  const validateFieldsAreSimilar = () => {
    if (!selectedUser) return true;
    if (data.ins_name_f && selectedUser.firstName.toLowerCase() !== data.ins_name_f.toLowerCase()) return false;
    if (data.ins_name_l && selectedUser.lastName.toLowerCase() !== data.ins_name_l.toLowerCase()) return false;
    if (data.ins_dob && moment(selectedUser.dob).format("YYYYMMDD") !== convertYYYYMMDDToDate(data.ins_dob, "YYYYMMDD"))
      return false;
    if (data.ins_number && selectedUser.medicalNo?.toLowerCase() !== data.ins_number.toLowerCase()) return false;
    return true;
  };

  const isActive = () => {
    if (!!newUser.payerId === "01192") {
      return !!newUser?.benefit?.some(medicareEligCondition);
    }
    return !!newUser?.benefit?.some(eligBaseCondition);
  };

  const formatAdditionalPayer = () => {
    const { additionalPayerName } = newUser;
    if (!additionalPayerName) return;
    const list = [];
    if (additionalPayerName.entity_addr_1) list.push(additionalPayerName.entity_addr_1[0]);
    if (additionalPayerName.entity_city) list.push(additionalPayerName.entity_city[0]);
    if (additionalPayerName.entity_state) list.push(additionalPayerName.entity_state[0]);
    return list.join(",");
  };

  const handleAddToProfile = async (isAddInProfile) => {
    setLoading(true);
    // check the additional payer eligibility without medical no
    let { exception, status, data, error } = await isEligibileWithAdditionalPayer(null);

    // return in case exception code return
    if (exception) {
      setLoading(false);
      return;
    }

    if (!status) {
      // check the additional payer eligibility with medical no
      let { exception, status, subData, error } = await isEligibileWithAdditionalPayer(newUser.medicalNo);

      if (exception) {
        setLoading(false);
        return;
      }

      if (error) {
        dispatch(setMessage(error, MESSAGE_MODES.error));
      }
      data = subData;
    }

    dispatch(setMessage(`Eligibility Passed`, MESSAGE_MODES.success));

    if (data && isAddInProfile) {
      const { ins_number } = data;

      const objToUpdate = {
        medicalNo: ins_number,
        insuranceCompany: newUser.additionalPayerName.additionalPayerId,
        insuranceCompanyCode: newUser.additionalPayerName.entity_name[0],
      };
      console.log("Going to add in Patient Insurance", objToUpdate);
    }
    setLoading(false);
  };

  const isEligibileWithAdditionalPayer = async (medicalNo) => {
    try {
      const obj = {
        clientID: CONFIG.generalClient,
        locationID: CONFIG.generalFacility,
        subAgentID: CONFIG.generalSubAgent,
        firstName: newUser.ins_name_f,
        lastName: newUser.ins_name_l,
        middleName: newUser.ins_name_m,
        fdos: moment().format("YYYYMMDD"),
        userID: loginUser.sub,
        ...(medicalNo && { medicalNo: medicalNo }),
        userName: loginUser.name,
        subAgentName: newUser.subAgentName,
        locationName: newUser.clientName,
        clientName: newUser.clientName,
        insuranceCompany: newUser.additionalPayerName.additionalPayerId,
        dob: newUser.ins_dob,
        insuranceProvider: newUser.additionalPayerName.entity_name[0],
        street: newUser.ins_addr_1,
        city: newUser.ins_city,
        state: newUser.ins_state,
        zip: newUser.ins_zip,
        countryCode: "+1",
        country: "US",
        payerId: newUser.additionalPayerName.additionalPayerId,
      };

      const res = await api.checkManualEligibility(obj, loginUser);

      const { data, error } = res;

      if (error) {
        return { exception: false, status: false, error: error.error_mesg };
      }

      if (data) {
        const isEligible = !!data?.benefit?.some(eligBaseCondition);
        if (isEligible) {
          return { exception: false, status: true, data: data };
        }
      }
    } catch (e) {
      dispatch(setMessage("Fail to Process the request", MESSAGE_MODES.error));
    }
    return { exception: true };
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size="xl"
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        style={{ paddingLeft: "0" }}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {t("eligibilityDetails")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
            color: "var(--text-black)",
          }}
        >
          <div>
            <div className="form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">First Name: </label>
                <span
                  className={
                    selectedUser && selectedUser.firstName.toLowerCase() !== newUser.ins_name_f?.toLowerCase()
                      ? "error-text"
                      : ""
                  }
                >
                  {newUser.ins_name_f || newUser.firstName}
                </span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Last Name: </label>
                <span
                  className={
                    selectedUser && selectedUser.lastName.toLowerCase() !== newUser.ins_name_l?.toLowerCase()
                      ? "error-text"
                      : ""
                  }
                >
                  {newUser.ins_name_l || newUser.lastName}
                </span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Status:</label>
                <span className={`fw-bold ${isActive() ? "colorGreen" : "colorRed"} `}>
                  {isActive() ? "Active - Eligible" : "InActive - Not Eligible"}
                </span>
              </div>
            </div>

            <div className="form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold ellipsis" title="Plan Begin Date">
                  Plan Date:
                </label>
                <span>{`${newUser.planStartDate || ""} - ${newUser.planEndDate || ""}`}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label
                  className={`modalLineHeaders fw-bold ${
                    selectedUser &&
                    newUser.ins_dob &&
                    (convertYYYYMMDDToDate(selectedUser?.dob) !== convertYYYYMMDDToDate(newUser.ins_dob)
                      ? "error-text"
                      : "")
                  }}`}
                >
                  DOB:{" "}
                </label>
                <span>{newUser.ins_dob ? convertYYYYMMDDToDate(newUser.ins_dob || newUser.dob) : "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Gender: </label>
                <span>{newUser.ins_sex}</span>
              </div>
            </div>
            <div className="form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Insurance No: </label>
                <span
                  className={
                    selectedUser &&
                    newUser.ins_number &&
                    (selectedUser.medicalNo?.toLowerCase() !== newUser.ins_number.toLowerCase() ? "error-text" : "")
                  }
                >
                  {newUser.ins_number || newUser.medicalNo}
                </span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Insurance Address: </label>
                <span>{newUser.ins_addr_1}</span>
              </div>
            </div>
            <div className="form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-50">
                <label className="modalLineHeaders fw-bold">Additional Payer: </label>
                {newUser.additionalPayerName?.entity_name.join(",") && (
                  <div className="d-grid">
                    <span className="error-text">{newUser.additionalPayerName?.entity_name.join(",")}</span>
                    <span>{formatAdditionalPayer()}</span>
                    {newUser.additionalPayerName?.entity_phone && (
                      <span>{`Phone: ${formatPhoneNumber(`+1${newUser.additionalPayerName?.entity_phone[0]}`)}`}</span>
                    )}
                    {/* Temporary Stop this code as It required Patient info */}
                    {/* {newUser.additionalPayerName?.additionalPayerId && (
                      <div className="d-flex">
                        <Button
                          style={{ marginBottom: 10 }}
                          variant="secondary"
                          className="modalButtons headerButton btn-fill"
                          disabled ={loading}
                          onClick={() => {
                            handleAddToProfile(true);
                          }}
                        >
                          Add to Profile
                        </Button>

                        <Button
                          style={{ marginBottom: 10 }}
                          variant="primary"
                          className="modalButtons headerButton btn-fill"
                          disabled ={loading}
                          onClick={() => {
                            handleAddToProfile(false);
                          }}
                        >
                          Check Eligibility
                        </Button>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            </div>
          </div>

          {error && <ErrorMessage error={error} handleChange={() => setError("")} timeLess />}
          <div className="form-group-eligibity-wrapper w-100">
            <InputField
              type="dropDown"
              options={
                newUser.benefit &&
                newUser.benefit.map((benefit) => {
                  return { value: benefit.benefit_code, label: benefit.benefit_description };
                })
              }
              placeholder="Search..."
              value={jumpToSection}
              handleChange={(e) => setJumpToSection(e)}
              groupWrapper="w-100"
              onInputChange={(text) => {
                text && setJumpToSection(null);
              }}
              isClearable
            />
          </div>
          <div className="form-group-wrapper d-block">
            <div className="table-responsive table-wrapper">
              <Table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>#</th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Benefit Code
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Benefit Description
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Benefit Coverage Description
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Plan Date
                    </th>
                    <th className="ellipsis centered" style={{ textAlign: "center" }}>
                      Benefit Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newUser.benefit?.length > 0 &&
                    newUser.benefit.map((user, index) => <TableRow user={user} index={index} key={index} />)}
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          {selectedEligibility && !validateFieldsAreSimilar() && (
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={handleUpdatePatient}
            >
              Update Patient
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EligibilityDetailsModal;
