import React from "react";
import { Modal, Button, Table, Card, Row, Col } from "react-bootstrap";
import { objTypes, AdditionalKeys, fieldLabel, applyCustomOrder } from "constant";
import { insuranceProviderFormatter } from "utils";

const OrderLogsCollapseTable = (props) => {
  const { open } = props;

  const data = applyCustomOrder(Object.keys(open.newRecord), Object.keys(fieldLabel)).filter(
    (f) => !AdditionalKeys.includes(f)
  );

  const TableCollapse = ({ objKeys, newRecord, oldRecord }) => {
    return (
      <>
        {fieldLabel[objKeys] &&
        newRecord[objKeys] &&
        oldRecord[objKeys] &&
        newRecord[objKeys][objTypes[objKeys] || "S"] !== oldRecord[objKeys][objTypes[objKeys] || "S"] ? (
          <tr className="expended-row">
            <td className="ellipsis w-10" style={{ textAlign: "center" }} title={fieldLabel[objKeys]}>
              {fieldLabel[objKeys]}
            </td>
            <td style={{ textAlign: "center" }}>
              {objKeys === "insuranceCompany"
                ? insuranceProviderFormatter(oldRecord[objKeys][objTypes[objKeys] || "S"])
                : objKeys === "eligibilityStatus"
                ? oldRecord[objKeys] && oldRecord[objKeys]["M"]["message"]["S"]
                : oldRecord[objKeys] && oldRecord[objKeys][objTypes[objKeys] || "S"]}
            </td>
            <td style={{ textAlign: "center" }}>
              {objKeys === "insuranceCompany"
                ? insuranceProviderFormatter(newRecord[objKeys][objTypes[objKeys] || "S"])
                : objKeys === "eligibilityStatus"
                ? newRecord[objKeys] && newRecord[objKeys]["M"]["message"]["S"]
                : newRecord[objKeys] && newRecord[objKeys][objTypes[objKeys] || "S"]}
            </td>
          </tr>
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <>
      {data && data.length > 0 && (
        <tr>
          <td colSpan={"12"} className="expended-table-child">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTableChild">
                      <thead>
                        <tr>
                          <th className="fw-bold" style={{ textAlign: "center" }}>
                            Field
                          </th>
                          <th style={{ textAlign: "center" }}>Old Value</th>
                          <th style={{ textAlign: "center" }}>New Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {open.newRecord &&
                          open.oldRecord &&
                          data.map((m, i) => {
                            return (
                              <TableCollapse
                                key={i}
                                objKeys={m}
                                oldRecord={open.oldRecord}
                                newRecord={open.newRecord}
                              />
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default OrderLogsCollapseTable;
