import API from "api";
import { Storage } from "aws-amplify";
import Loader from "components/Loader/Loader";
import {
  ActionEventType,
  AdditionalKeys,
  Audit_Parse,
  CONFIG,
  MESSAGE_MODES,
  applyCustomOrder,
  fieldLabel,
  usersNo,
} from "constant";
import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setMessage } from "store/features/general/generalAction";
import { claimLogsTitle, formatOrderDate, formatViewUpdateClaimList, tdRowDropdown } from "utils";
import EligibilityDetailsModal from "../CheckEligibility/EligibilityDetailsModal";
import OrderLogsCollapseTable from "./components/OrderLogsCollapseTable";

const OrderLogsModal = (props) => {
  const { show, handleClose, order, user, claim } = props;
  const [tracking, setTracking] = useState("");
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eligibilityDetailsData, setEligibilityDetailsData] = useState(null);
  const [submitTimes, setSubmitTimes] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTrackingLink();
  }, []);

  const handleOpenDetailModal = async (item) => {
    if (item.apiData) {
      const data = JSON.parse(item.apiData);
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          res.Body.text().then((string) => {
            // handle the String data return String
            const apidata = JSON.parse(string);
            setEligibilityDetailsData(apidata?.elig || null);
          });
        } catch (err) {
          console.log("Error:-", err.message);
          dispatch(setMessage(err.message, MESSAGE_MODES.error));
        }
      } else {
        setEligibilityDetailsData(data?.elig || null);
      }
    } else {
      setEligibilityDetailsData(item);
    }
  };

  const fetchTrackingLink = async () => {
    if (order) {
      setLoading(true);
      try {
        const logData = await API.getOrderLogs(order.id, claim ? "c" : "o");
        const items = logData.Items.filter((l) => l.eventType === "Shipped");
        if (items.length > 0) {
          if (items[0].tracking_url) {
            setTracking(items[0].tracking_url);
          }
        }
        const editLogslist = logData.Items.filter(
          (item) =>
            item.changeObject &&
            item.changeObject.newRecord &&
            applyCustomOrder(Object.keys(item.changeObject.newRecord), Object.keys(fieldLabel)).filter(
              (f) => !AdditionalKeys.includes(f)
            ).length > 0
        );
        const logsFilter = logData.Items.filter((f) => f.eventType !== "Eligibility" && f.eventType !== "ClaimUpdated");
        setSubmitTimes(logsFilter.filter((f) => f.eventType === "SubmitForBill").length);
        const eligObj = logData.Items?.filter((f) => f.eventType === "Eligibility");
        if (eligObj) logsFilter.push(...eligObj);

        if (editLogslist.length > 0) {
          logsFilter.push(...editLogslist);
        }

        const viewList = logsFilter
          .map((m) => ({
            ...m,
            createdAt: m.createdAt?.replace("2022-02", "2023-02"),
          }))
          .sort((a, b) => {
            const testerB = b.createdAt;
            const testerA = a.createdAt;
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });

        setLogs(formatViewUpdateClaimList(viewList));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("Error:-", err);
      }
    }
  };

  const parseEventData = (event, val) => {
    const EVENT_TYPES = "DeliveredShippedMovedDeclinedPickedPackedReturnReturned";
    if (EVENT_TYPES.includes(event)) {
      return formatOrderDate(val);
    }
    if (event.includes("Eligibility")) {
      return "Passed Eligibility Check";
    }
    return val;
  };

  const handleClickEvent = (item) => {
    if (item.eventType === "Eligibility") handleOpenDetailModal(item);
    if (item.eventType === "OrderShipped" && item.trackingNumber) {
      window.open(`https://www.stamps.com/tracking-details/?t=${item.trackingNumber.replace(/\D/g, "")}`, "_blank");
    }
  };

  const TableRow = ({ item, sr }) => {
    const [open, setOpen] = useState(null);
    return (
      <>
        <tr>
          {tdRowDropdown(
            item.changeObject &&
              item.changeObject.newRecord &&
              applyCustomOrder(Object.keys(item.changeObject.newRecord), Object.keys(fieldLabel)).filter(
                (f) => !AdditionalKeys.includes(f)
              ).length > 0,
            open,
            () => setOpen(!open ? item.changeObject : null),
            "tdWidth10"
          )}
          <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
            {sr}
          </td>
          <td className={`ellipsis `}>{formatOrderDate(item.createdAt)}</td>
          <td
            className={`ellipsis `}
            style={{
              cursor: ActionEventType.includes(item.eventType) ? "pointer" : "",
            }}
            onClick={() => handleClickEvent(item)}
          >
            {Audit_Parse[item.eventType]}
          </td>
          <td
            className={`ellipsis`}
            title={
              (item.eventType !== "ClaimCreated" && claimLogsTitle(item.message)) ||
              parseEventData(item.eventType, Audit_Parse[item.eventData] || item.eventData)
            }
          >
            {(item.eventType !== "ClaimCreated" && claimLogsTitle(item.message)) ||
              parseEventData(item.eventType, Audit_Parse[item.eventData] || item.eventData)}
          </td>
          <td className={`ellipsis`}>{item.userName || "Admin"}</td>

          {(user?.phone_number === "+17777777777" || user?.phone_number === usersNo.ibrahim) && (
            <td
              onClick={async () => {
                await API.deleteLogRecord(item.id);
                dispatch(setMessage("delete Successfully", MESSAGE_MODES.success));
                await fetchTrackingLink();
              }}
            >
              <i className="fas fa-trash cursor-pointer" />
            </td>
          )}
        </tr>
        {open && <OrderLogsCollapseTable open={open} />}
      </>
    );
  };

  return (
    <Modal backdrop="static" show={show} animation={true} onHide={() => handleClose()} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {claim ? "Claim Detail" : "Order Detail"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="table-responsive">
          {CONFIG.isLabType && <strong>{`This Claim Submitted ${submitTimes} Time(s)`}</strong>}
          {loading && <Loader />}
          <Table className="table-hover table-striped logsTable">
            <thead>
              <tr>
                <th className="tdWidth10" />
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  Sr#
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Date</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Event</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Action</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">User</span>
                  </div>
                </th>
                {(user?.phone_number === "+17777777777" || user?.phone_number === usersNo.ibrahim) && (
                  <th className="tdWidth10"></th>
                )}
              </tr>
            </thead>
            <tbody>
              {logs &&
                logs.map((o, index) => {
                  return <TableRow key={index} item={o} sr={index + 1} />;
                })}
            </tbody>
          </Table>
        </div>
        <div>
          {tracking && (
            <a href={tracking} target="_blank">
              Live Tracking <i className="fa fa-external-link" />
            </a>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
      {eligibilityDetailsData && (
        <EligibilityDetailsModal handleClose={() => setEligibilityDetailsData(null)} data={eligibilityDetailsData} />
      )}
    </Modal>
  );
};

export default OrderLogsModal;
