import { Overlay, Popover, Table } from "react-bootstrap";

const ChartLabelsPopovers = (props) => {
  const { showPopover, setShowPopover, target, colors, labels, handleLabelClick } = props;

  return (
    <>
      <Overlay
        show={showPopover}
        placement="auto"
        flip
        target={target}
        rootClose={true}
        onHide={() => setShowPopover(false)}
      >
        <Popover
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={() => setShowPopover(false)}
          id="popover"
          className="popOverAttachmentsOverlay"
        >
          <div className="table-wrapper chart-labels-table">
            <Table className="table">
              <thead>
                <tr className="data-listing-row">
                  <th className="centered">Color</th>
                  <th className="centered">Label</th>
                </tr>
              </thead>
              <tbody>
                {labels.map((label, index) => (
                  <tr className="chartLabelsRows" key={index}>
                    <td className="text-center color-identifier-td">
                      <div className="p-3" style={{ backgroundColor: colors[index] }} />
                    </td>
                    <td className="text-center cursor-pointer" onClick={(e) => handleLabelClick(e, label, index)}>
                      {label}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};

export default ChartLabelsPopovers;
