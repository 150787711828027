import api from "api";
import { API, Auth, DataStore, graphqlOperation } from "aws-amplify";
import { CLAIM_STATUS_LOG_OBJ, CONFIG, EMP_CLAIM_MODAL, ORDER_BY_DIRECTION, CLAIM_SUBMIT_STATUS } from "constant";
import { createEmployeeClaims, deleteEmployeeClaims, updateEmployeeClaims } from "graphql/mutations";
import moment from "moment";
import { userCompanyID } from "utils";
import * as queries from "./graphql/queries";
import { Employee } from "./models";

const apiName = "apif79b844d";

const getOrderGQL = async (type, id, nextToken, param) => {
  let model = [];
  let pageNextToken = null;
  const params = {
    filter: param,
    limit: 60,
  };
  if (type && type === USER_TYPE_USER) {
    Object.assign(params.filter, { employeeID: { eq: id } });
  } else if (type && type === USER_TYPE_CLIENT) {
    Object.assign(params.filter, { clientID: { eq: userCompanyID.get() } });
  } else if (type && type === USER_TYPE_SUBAGENT) {
    Object.assign(params.filter, { subAgentID: { eq: userCompanyID.get() } });
  } else if (userCompanyID.get()) {
    Object.assign(params.filter, { siteID: { eq: userCompanyID.get() } });
  }
  if (nextToken) {
    Object.assign(params, { nextToken: nextToken });
  }

  try {
    do {
      if (pageNextToken) {
        Object.assign(params, { nextToken: pageNextToken });
      }
      const graphqlData = await API.graphql(graphqlOperation(queries.listEmployeeOrders, params));
      model = model.concat(graphqlData.data.listEmployeeOrders.items);
      pageNextToken = graphqlData.data.listEmployeeOrders.nextToken;
    } while (pageNextToken && model.length < 60);
    return { items: model, nextToken: pageNextToken };
  } catch (err) {
    console.log("Error:- EmployeeGraphQl", err.message);
  }
};

const createClaimGQL = async (data, number) => {
  const newEmpClaim = {
    clientID: userCompanyID.get() || CONFIG.generalClient,
    total_charge: data.total_charge?.toString(),
    charge_1: data.charge_1?.toString(),
    units_2: data.units_2?.toString(),
    proc_array: JSON.stringify(data.proc_array),
    provider: JSON.stringify(data.provider)
  };

  const keysNotToInclude = [
    "id",
    "clientID",
    "total_charge",
    "charge_1",
    "units_2",
    "proc_array",
    "provider",
    "createdAt",
    "updatedAt",
    "_version",
  ];

  Object.keys(EMP_CLAIM_MODAL).map(
    (key) =>
      !keysNotToInclude.includes(key) &&
      Object.assign(newEmpClaim, {
        [key]: data[key],
      })
  );

  const res = await API.graphql(graphqlOperation(createEmployeeClaims, { input: newEmpClaim }));
  // if (number) await api.updateCompnayClaimNo(number);

  const resToReturn = { ...res.data.createEmployeeClaims };

  Object.assign(resToReturn, {
    proc_array: resToReturn.proc_array ? JSON.parse(resToReturn.proc_array) : null,
    provider: resToReturn.provider ? JSON.parse(resToReturn.provider) : null,
  });

  return resToReturn;
};

const updateEmployeeClaimsGQL = async (obj) => {
  try {
    const model = await getSingleClaimModalGQL(obj.id);

    console.log("model", model);

    let updatedModel = {
      id: model.id,
      _version: model._version,
      provider: obj.provider ? JSON.stringify(obj.provider) : null,
      proc_array: obj.proc_array ? JSON.stringify(obj.proc_array) : null,
      total_charge: obj.total_charge?.toString(),
      acknowledgeBy: obj.acknowledgeBy ? JSON.stringify(obj.acknowledgeBy) : null,
      assign_details: obj.assign_details ? JSON.stringify(obj.assign_details) : null,
      paid_insurance: obj.paid_insurance ? JSON.stringify(obj.paid_insurance) : null
    };

    if (obj.status === CLAIM_SUBMIT_STATUS.submitted) {
      Object.assign(updatedModel, {
        submissionDate: [...(model.submissionDate?.split(",") || []), moment().toISOString()].join(","),
        reSubmissionDate: moment().toISOString(),
      });
    }
    if (updatedModel.status !== obj.status) {
      Object.assign(updatedModel, {
        orderDate: moment().format("YYYYMMDD"),
      });
    }

    const keysNotToInclude = [
      "id",
      "total_charge",
      "proc_array",
      "provider",
      "acknowledgeBy",
      "assign_details",
      "paid_insurance",
      "submissionDate",
      "reSubmissionDate",
      "orderDate",
      "createdAt",
      "updatedAt",
      "_version",
    ];

    Object.keys(EMP_CLAIM_MODAL).map(
      (key) =>
        !keysNotToInclude.includes(key) &&
        Object.assign(updatedModel, {
          [key]: obj[key],
        })
    );

    const resToSend = await updateSingleClaimGQL(updatedModel);
    return { newImage: resToSend, oldImage: model };
  } catch (err) {
    console.log("updateEmployeeClaimsGQL Error:-", err);
  }
};

const updateClaimsGQL = async (obj, user, provider, refProvider, ordProvider, claimList) => {
  let claimIds = claimList;

  if (!claimIds) {
    const res = await api.getEmployeeClaimsAPI(obj.id);
    claimIds = res.map((claim) => claim.id);
  }
  const ttl = claimIds.length;
  for (let i = 0; i < ttl; i++) {
    let dbClaim = null;
    const claimId = claimIds[i];
    if (claimList && claimList.length > 0) {
      //   const res = await api.getClaimByIdAPI(claimId);
      dbClaim = await getSingleClaimModalGQL(claimId);

      Object.assign(dbClaim, {
        provider: JSON.parse(dbClaim.provider),
      });
    }
    const updatedModal = {
      id: dbClaim.id,
      _version: dbClaim._version,
      ins_addr_1: obj.street,
      ins_addr_2: obj.street2,
      ins_city: obj.ins_city,
      ins_dob: obj.dob,
      ins_name_f: obj.firstName,
      ins_name_l: obj.lastName,
      ins_name_m: obj.middleName || "",
      ins_number: obj.medicalNo,
      ins_sex: obj.gender || obj.sex,
      ins_state: obj.state,
      ins_zip: obj.zip,
      pat_addr_1: obj.street,
      pat_addr_2: obj.street2,
      pat_city: obj.city,
      pat_country: obj.country,
      pat_dob: obj.dob,
      pat_name_f: obj.firstName,
      pat_name_l: obj.lastName,
      pat_name_m: obj.middleName || "",
      pat_phone: obj.phoneNumber,
      pat_sex: obj.sex || obj.gender,
      pat_state: obj.state,
      pat_zip: obj.zip,
      payer_name: obj.insuranceCompanyCode,
      payerid: obj.insuranceCompany,
      provider: JSON.stringify({
        ...(dbClaim.provider || {}),
        ...(provider && {
          prov_id: provider.id,
          prov_name_f: provider.firstName,
          prov_name_l: provider.lastName,
          prov_name_m: provider.middleName,
          prov_npi: provider.npi,
          prov_taxid: provider.taxid,
        }),
        ...(refProvider && {
          ref_id: refProvider.id,
          ref_name_f: refProvider.firstName,
          ref_name_l: refProvider.lastName,
          ref_name_m: refProvider.middleName,
          ref_npi: refProvider.npi,
        }),
        ...(ordProvider && {
          ord_prov_id: ordProvider.id,
          ord_prov_name_f: ordProvider.firstName,
          ord_prov_name_l: ordProvider.lastName,
          ord_prov_name_m: ordProvider.middleName,
          ord_prov_npi: ordProvider.npi,
        }),
      }),
      updatedBy: user?.sub,
      updatedByName: user?.name,
    };

    const resToSend = await updateSingleClaimGQL(updatedModal);
  }
};

const updateEmployeeClaimStatusGQL = async (obj, claimStatus) => {
  dbClaim = await getSingleClaimModalGQL(obj.id);

  const updatedModal = {
    id: dbClaim.id,
    _version: dbClaim._version,
    status: claimStatus,
  };
  await updateSingleClaimGQL(updatedModal);
};

const updateEmployeeClaimStatusWithLogsGQL = async (obj, date, user) => {
  const dbClaim = await getSingleClaimModalGQL(obj.id);

  const updatedModal = {
    id: dbClaim.id,
    _version: dbClaim._version,
    status: obj.status,
    message: obj.message.replace(/'/g, ""),
  };
  const res = await updateSingleClaimGQL(updatedModal);

  const logDate = moment(date, "YYYY-MM-DDTHH:mm").toISOString();

  const logObj = {
    employeeID: obj.employeeID,
    empID: obj.employeeID,
    orderId: obj.remote_claimid.slice(7),
    medicalNo: obj.ins_number.toUpperCase(),
    payerid: obj.payerid,
    payerName: obj.payer_name || "",
    locationID: obj.locationID || "",
    clientID: obj.clientID || "",
    subAgentID: obj.subAgentID || "",
    firstName: obj.pat_name_f,
    lastName: obj.pat_name_l,
    dob: obj.pat_dob,
    message: obj.message || "",
    clientName: obj.clientName || "",
    locationName: obj.locationName || "",
    subAgentName: obj.subAgentName || "",
    claimID: obj.id,
    eventType: CLAIM_STATUS_LOG_OBJ[obj.status],
    eventData: CLAIM_STATUS_LOG_OBJ[obj.status],
    userName: user.name || "System",
    userID: user.sub || null,
    amount: obj.total_charge,
    logDate,
  };

  await api.saveLogs(logObj);

  return { newImage: res, oldImage: dbClaim };
};

const updateSingleClaimGQL = async (claimObj) => {
  const keysToDelete = ["_deleted", "_lastChangedAt", "updatedAt"];
  keysToDelete.forEach((key) => delete claimObj[key]);

  const claimRes = await API.graphql(
    graphqlOperation(updateEmployeeClaims, {
      input: claimObj,
    })
  );

  let resToSend = claimRes.data.updateEmployeeClaims;
  Object.assign(resToSend, {
    provider: JSON.parse(resToSend.provider),
    proc_array: JSON.parse(resToSend.proc_array),
    acknowledgeBy: resToSend.acknowledgeBy ? JSON.parse(resToSend.acknowledgeBy) : null,
    assign_details: resToSend.assign_details ? JSON.parse(resToSend.assign_details) : null,
    paid_insurance: resToSend.paid_insurance ? JSON.parse(resToSend.paid_insurance) : null,
  });

  return resToSend;
};

const getSingleClaimModalGQL = async (id) => {
  const res = await API.graphql(graphqlOperation(queries.getEmployeeClaims, { id }));

  return res.data.getEmployeeClaims;
};

const deleteEmployeeClaimGQL = async (claimId) => {
  const claim = await getSingleClaimModalGQL(claimId);
  const employeeClaimData = {
    id: claim.id,
    _version: claim._version,
  };
  const res = await API.graphql({ query: deleteEmployeeClaims, variables: { input: employeeClaimData } });
  return res.data.deleteEmployeeClaims;
};

const unacknowledgeClaimGQL = async (obj) => {
  const dbClaim = await getSingleClaimModalGQL(obj.id);

  const updatedModal = {
    id: dbClaim.id,
    _version: dbClaim._version,
    acknowledgeBy: null,
  };
  await updateSingleClaimGQL(updatedModal);
};

const acknowledgeClaimGQL = async (obj, user) => {
  const dbClaim = await getSingleClaimModalGQL(obj.id);

  const updatedModal = {
    id: dbClaim.id,
    _version: dbClaim._version,
    acknowledgeBy: JSON.stringify({
      seenByName: user.name,
      seenById: user.sub,
    }),
  };
  const resToReturn = await updateSingleClaimGQL(updatedModal);

  const logObj = {
    employeeID: obj.employeeID,
    empID: obj.employeeID,
    orderId: obj.remote_claimid.slice(7),
    medicalNo: obj.ins_number?.toUpperCase(),
    payerid: obj.payerid,
    payerName: obj.payer_name || "",
    locationID: obj.locationID || "",
    clientID: obj.clientID || "",
    subAgentID: obj.subAgentID || "",
    firstName: obj.pat_name_f,
    lastName: obj.pat_name_l,
    dob: obj.pat_dob,
    message: obj.message || "",
    clientName: obj.clientName || "",
    locationName: obj.locationName || "",
    subAgentName: obj.subAgentName || "",
    claimID: obj.id,
    eventType: "isAcknowledge",
    eventData: "isAcknowledge",
    message: "Done",
    userName: user.name || "System",
    userID: user.sub || null,
    amount: obj.total_charge,
  };

  await api.saveLogs(logObj);
  return resToReturn;
};

const addClaimNoteGQL = async (obj, level) => {
  const dbOrder = await getSingleClaimModalGQL(obj.id);
  if (dbOrder) {
    const updatedModal = {
      id: dbOrder.id,
      _version: dbOrder._version,
      note: obj.note.replace(/'/g, ""),
    };
    await updateSingleClaimGQL(updatedModal);

    if (level === "patient") {
      const modal = await DataStore.query(Employee, (e) => e.id("eq", dbOrder.employeeID));
      const emp = modal[0];
      if (emp) {
        await DataStore.save(
          Employee.copyOf(emp, (updated) => {
            updated.note = obj.note;
            updated.noteAddedBy = obj.userName;
            updated.updatedBy = obj.userID;
            updated.updatedByName = obj.userName;
          })
        );
      }
    }
  }

  if (!obj.note) return;
  const path = "/saveNotesInLogs";
  const params = {
    body: obj,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

export default {
  getOrderGQL,
  createClaimGQL,
  updateEmployeeClaimsGQL,
  updateClaimsGQL,
  updateEmployeeClaimStatusGQL,
  updateEmployeeClaimStatusWithLogsGQL,
  deleteEmployeeClaimGQL,
  acknowledgeClaimGQL,
  addClaimNoteGQL,
  unacknowledgeClaimGQL,
};
