import api from "api";
import Loader from "components/Loader/Loader";
import { INSURANCE_PROVIDER, MEDICATRE_IDS, MESSAGE_MODES } from "constant";
import { AppContext } from "context/app-context";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { CONFIG } from "../../../constant";
import { t } from "../../../stringConstants";
import { convertToUpper, getValidDep, isValidIDNumber, isValidName } from "../../../utils";
import RadioButton from "../../RadioButton/RadioButton";
const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const CheckEligibilityModal = (props) => {
  const { handleClose } = props;
  const appContext = useContext(AppContext);
  const [newUser, setNewUser] = useState({});
  const [address, setAddress] = useState(null);
  const [show, setShow] = useState("");
  const [loading, setLoading] = useState(false);
  const firstNameRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  const onHandleSave = async (isConfirm, newUser) => {
    if (!newUser.firstName || newUser.firstName === " ") {
      setShow("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || newUser.lastName === " ") {
      setShow("Last name is required");
      return;
    }

    if (!isValidName(newUser.lastName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    // stop this check as might possible link with wrong patient
    const existingEmp = null;
    // const existingEmp = appContext.employees.find((item) => {
    //   if (item.firstName === newUser.firstName && item?.lastName === newUser.lastName) {
    //     return item;
    //   }
    // });
    let nameData = {
      clientName: null,
      locationName: null,
      subAgentName: null,
    };
    const obj = newUser;
    if(CONFIG.isLabType){
      Object.assign(obj, {
        clientID:  CONFIG.generalClient,
        locationID: CONFIG.generalFacility,
        subAgentID: CONFIG.generalSubAgent,
      });
    }
    else
    {
      if (existingEmp) {
        let clientName = appContext.companies.find((company) => company.id === existingEmp?.clientID)?.name;
        let locationName = appContext.locations.find((loc) => loc.id === existingEmp?.companyID)?.name;
        let subAgentName = appContext.subAgents.find((item) => item.id === existingEmp?.subAgent)?.name;
        nameData.clientName = clientName;
        nameData.locationName = locationName;
        nameData.subAgentName = subAgentName;
        Object.assign(obj, {
          clientID: existingEmp.clientID,
          locationID: existingEmp.companyID,
          subAgentID: existingEmp.subAgent,
        });
      } else {
        if (appContext.user?.isClient()) {
          Object.assign(obj, {
            clientID: appContext.userCompany.id,
          });
          nameData.clientName = appContext.userCompany.name;
        } else if (appContext.user?.isSite()) {
          Object.assign(obj, {
            clientID: appContext.userLocation.clientID,
            locationID: appContext.userLocation.id,
          });
          nameData.clientName = appContext.companies.find(
            (company) => company.id === appContext.userLocation.clientID
          ).name;
          nameData.locationName = appContext.userLocation.name;
        } else if (appContext.user?.isSubAgent()) {
          Object.assign(obj, {
            clientID: appContext.userSubAgent.clientID,
            locationID: appContext.userSubAgent.locationID,
            subAgentID: appContext.userSubAgent.id,
          });
          nameData.clientName = appContext.companies.find(
            (company) => company.id === appContext.userSubAgent.clientID
          ).name;
          nameData.locationName = appContext.locations.find((loc) => loc.id === appContext.userSubAgent.locationID).name;
          nameData.subAgentName = appContext.userSubAgent.name;
        }
      }
    }

    try {
      setLoading(true);
      const res = await api.checkManualEligibility({ ...obj, ...nameData }, appContext.user);

      if (!res.error) {
        setLoading(false);
        handleClose({
          ...res.data,
          ...nameData,
          ...newUser,
          payerId: newUser.insuranceCompany,
          groupId: newUser.insuranceGroupId,
          createdAt: moment().utc().toISOString(),
          message: "Success",
        });
      } else {
        setLoading(false);
        dispatch(setMessage(res.error.error_mesg, MESSAGE_MODES.error));
        handleClose({
          ...nameData,
          ...newUser,
          payerId: newUser.insuranceCompany,
          groupId: newUser.insuranceGroupId,
          createdAt: moment().utc().toISOString(),
          message: "Unuccess",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
    }
  };

  const handlePhoneNo = (e) => {
    setNewUser({
      ...newUser,
      phoneNumber: e,
    });
  };

  const insuranceObject = useMemo(() => {
    if (newUser.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  return (
    <>
      <Modal
        show
        animation={true}
        size="md"
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        backdrop="static"
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {t("checkEligibility")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div className="centerWrap">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">First Name: </label>
                <input
                  ref={firstNameRef}
                  value={newUser.firstName}
                  className="modalInput"
                  placeholder="First Name"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      firstName: getValidDep(e.target.value),
                    })
                  }
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Last Name: </label>
                <input
                  value={newUser.lastName}
                  className="modalInput"
                  placeholder="Last Name"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      lastName: getValidDep(e.target.value),
                    })
                  }
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">DOB: </label>
                <DatePicker
                  className="w-100 checkEligibilityDOB"
                  openCalendarOnFocus={false}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  format="MM/dd/y"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                    })
                  }
                  value={
                    newUser.dob && new Date(newUser.dob) != "Invalid Date"
                      ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                      : null
                  }
                  maxDate={new Date()}
                  minDate={new Date("1900-01-01")}
                />
              </div>

              <div className="col-lg-12 col-12 mb-3">
                <label className="modalLineHeaders mt-0">Primary Insurance:</label>
                <Select
                  options={INSURANCE_PROVIDER}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select Primary Insurance"
                  className="w-100"
                  value={insuranceObject}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      insuranceCompany: e.value,
                      medicalNo: "",
                      insuranceGroupId: "",
                      insuranceProvider: e.payer_name,
                    })
                  }
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Insurance Number:</label>
                <input
                  value={newUser.medicalNo}
                  className="modalInput"
                  maxLength={16}
                  placeholder="Enter Insurance Number"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      medicalNo: convertToUpper(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {" "}
          {show.length > 0 && <strong style={{ color: "Red" }}>{show}</strong>}
        </div>
        {!loading ? (
          <Modal.Footer>
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>

            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => onHandleSave(true, { ...newUser })}
            >
              Check
            </Button>
          </Modal.Footer>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

export default CheckEligibilityModal;
