import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButton from "components/RadioButton/RadioButton";
import { PREV_CLAIM_ACTIONS, PREV_CLAIM_TABLE, PROC_CODE_OPT } from "constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import { setProceduresFromDate } from "utils";
import EmpPrevClaimRow from "./EmpPrevClaimRow";

const EmployeePrevClaimsModal = (props) => {
  const { user, handleClose, handleSaveClaim, cssClass, empClaimsList } = props;
  

  const [selectedClaim, setSelectedClaim] = useState(null);
  const [selectedDos, setSelectedDos] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [actionToPerform, setActionToPerform] = useState("");

  const handleCreate = (selectedClaim) => {
    if (!actionToPerform) return setError("Choose an action before proceeding!");
    const action = actionToPerform;

    let newClaim = null;
    if (PREV_CLAIM_ACTIONS.auto === action || PREV_CLAIM_ACTIONS.update === action) {
      if (!selectedClaim) {
        setError("Please select any one claim!");
        return;
      }
      newClaim = {
        ins_addr_1: selectedClaim.ins_addr_1,
        ins_addr_2: selectedClaim.ins_addr_2,
        ins_city: selectedClaim.ins_city,
        ins_dob: selectedClaim.ins_dob,
        ins_name_f: selectedClaim.ins_name_f,
        ins_name_l: selectedClaim.ins_name_l,
        ins_name_m: selectedClaim.ins_name_m,
        ins_number: selectedClaim.ins_number,
        ins_sex: selectedClaim.ins_sex,
        ins_state: selectedClaim.ins_state,
        ins_zip: selectedClaim.ins_zip,
        pat_addr_1: selectedClaim.pat_addr_1,
        pat_addr_2: selectedClaim.pat_addr_2,
        pat_city: selectedClaim.pat_city,
        pat_country: selectedClaim.pat_country,
        pat_dob: selectedClaim.pat_dob,
        pat_name_f: selectedClaim.pat_name_f,
        pat_name_l: selectedClaim.pat_name_l,
        pat_name_m: selectedClaim.pat_name_m,
        pat_phone: selectedClaim.pat_phone,
        pat_sex: selectedClaim.pat_sex,
        pat_email: selectedClaim.pat_email,
        pat_state: selectedClaim.pat_state,
        pat_zip: selectedClaim.pat_zip,
        payer_name: selectedClaim.payer_name,
        payerid: selectedClaim.payerid,
        provider: selectedClaim.provider,
        proc_array: selectedClaim.proc_array,
        employeeID: selectedClaim.employeeID,
        accept_assign: selectedClaim.accept_assign,
        bill_addr_1: selectedClaim.bill_addr_1,
        bill_addr_2: selectedClaim.bill_addr_2,
        bill_city: selectedClaim.bill_city,
        bill_id: selectedClaim.bill_id,
        bill_name: selectedClaim.bill_name,
        bill_npi: selectedClaim.bill_npi,
        bill_phone: selectedClaim.bill_phone,
        bill_state: selectedClaim.bill_state,
        bill_taxid: selectedClaim.bill_taxid,
        bill_taxid_type: selectedClaim.bill_taxid_type,
        bill_zip: selectedClaim.bill_zip,
        diag_1: selectedClaim.diag_1,
        diag_2: selectedClaim.diag_2,
        diag_3: selectedClaim.diag_3,
        diag_4: selectedClaim.diag_4,
        diag_5: selectedClaim.diag_5,
        diag_6: selectedClaim.diag_6,
        diag_7: selectedClaim.diag_7,
        diag_8: selectedClaim.diag_8,
      };
    }

    // const keysToDelete = ["createdAt", "updatedAt", "id", "status", "_deleted", "_version", "_lastChangedAt"];
    // if (selectedClaim) {
    //   keysToDelete.forEach((key) => {
    //     if (selectedClaim.hasOwnProperty(key)) {
    //       delete selectedClaim[key];
    //     }
    //   });
    // }

    switch (action) {
      case PREV_CLAIM_ACTIONS.update:
        if (newClaim) {
          handleSaveClaim(PREV_CLAIM_ACTIONS.update, [newClaim]);
        } else {
          setError("Please select any one claim!");
        }
        break;

      case PREV_CLAIM_ACTIONS.auto:
        if (newClaim && selectedDos) {
          if (empClaimsList.some((f) => f.from_date_1 === selectedDos)) {
            setError("Date of Service is already assigned to some other claim!");
            return;
          }
          const claimToProceed = {
            ...newClaim,
            submissionDate: moment().toISOString(),
            orderDate: moment().format("YYYYMMDD"),
          };
          Object.assign(claimToProceed, {
            from_date: selectedDos,
          });

          const claim = setProceduresFromDate(claimToProceed, claimToProceed.from_date);
          console.log("auto: ", claim);
          handleSaveClaim(PREV_CLAIM_ACTIONS.auto, claim);
        } else {
          setError("Claim and Place of services should be filled before proceeding!");
        }
        break;

      case PREV_CLAIM_ACTIONS.new:
        handleSaveClaim(PREV_CLAIM_ACTIONS.new, user);
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      className={`${cssClass}`}
      centered
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Previous Claim List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <Card className="strpied-tabled-with-hover">
            <Card.Body>
              <h4>Select a previous claim below to use as a template</h4>
              {selectedClaim && actionToPerform === PREV_CLAIM_ACTIONS.auto && (
                <>
                  <Form.Group className="form-group-wrapper w-100">
                    <Form.Label className="mt-0  text-capitalize">DATE OF SERVICES</Form.Label>
                    <Form.Control
                      className={`modalInput`}
                      onChange={(e) => setSelectedDos(e.target.value)}
                      value={selectedDos}
                      placeholder="DATE OF SERVICES"
                      maxLength={10}
                      type="Date"
                    />
                  </Form.Group>
                </>
              )}
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr className="data-listing-row">
                      <th />
                      {PREV_CLAIM_TABLE.map((title) => (
                        <th key={title} className="centered">
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {empClaimsList.map((claim, index) => (
                      <EmpPrevClaimRow
                        key={index}
                        claim={claim}
                        index={index}
                        selectedClaim={selectedClaim}
                        setSelectedClaim={setSelectedClaim}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>

          <Card className="strpied-tabled-with-hover">
            <Card.Body>
              <div>
                <Form.Label className="mt-0 text-capitalize">Choose an Action to Perform:</Form.Label>
                <div className="prev-claim-action-container">
                  <RadioButton
                    name="action"
                    id={PREV_CLAIM_ACTIONS.update}
                    checked={actionToPerform === PREV_CLAIM_ACTIONS.update}
                    label="Open Template and Edit"
                    handleChange={(e) => e.target.checked && setActionToPerform(PREV_CLAIM_ACTIONS.update)}
                  />
                  <RadioButton
                    name="action"
                    id={PREV_CLAIM_ACTIONS.auto}
                    checked={actionToPerform === PREV_CLAIM_ACTIONS.auto}
                    label="Create New From Existing"
                    handleChange={(e) => e.target.checked && setActionToPerform(PREV_CLAIM_ACTIONS.auto)}
                  />
                  <RadioButton
                    name="action"
                    id={PREV_CLAIM_ACTIONS.new}
                    checked={actionToPerform === PREV_CLAIM_ACTIONS.new}
                    label="New Claim"
                    handleChange={(e) => {
                      if (e.target.checked) setActionToPerform(PREV_CLAIM_ACTIONS.new);
                      setSelectedClaim(null);
                    }}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Modal.Body>
      {loading && <Loader />}

      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() => handleCreate(selectedClaim)}
          disabled={!actionToPerform}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeePrevClaimsModal;
