import parsePhoneNumber, { AsYouType, isValidNumber } from "libphonenumber-js";
import moment from "moment";
import momentTZ from "moment-timezone";
import { AMAZON_CLIENT_RESULT, LOCATION, REGION, SCHEDULE_CATEGORY, HISTORY_TEST, CONFIG } from "constant";
import { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import { GROUP_TYPES } from "constant";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import FileSaver from "file-saver";
import { jsonToCSV } from "react-papaparse";
import postalCodes from "postal-codes-js";
import { TEST_TYPE_VALUE } from "constant";
import { v4 as uuidv4 } from "uuid";
import { STATUS } from "constant";
import { newRegisterObj } from "constant";
import { Link } from "react-router-dom";
import { INSURANCE_PROVIDER } from "constant";
import { CLAIM_SUBMIT_STATUS } from "constant";
import { CHECKBOX_COLORS } from "constant";
import Status from "components/Status";
import { CHECKBOX_ICON_OPTIONS } from "constant";
import { DIAGNOSIS_OPTIONS } from "constant";

export const downloadDataAsCSV = async (data, fileName) => {
  const content = jsonToCSV(data);
  const fileContent = new Blob([content], { type: "csv" });
  await FileSaver.saveAs(fileContent, `${fileName}.csv`);
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  if (phoneNumber) {
    return formatPhoneNumberIntl(phoneNumber);
  }
  return phoneNumber;
};

export const phoneFormatter = (phone) => {
  if (phone) {
    const d = setPhoneNo(phone);
    return d;
  }
  return phone;
};
export const isValidEmail = (email) => {
  if (
    new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email)
  ) {
    return true;
  } else return false;
};
export const isValidCallTime = (clt) => {
  if (new RegExp(/^([01]\d|2[0-3])(:)([0-5]\d)?$/g).test(clt)) {
    return true;
  }
  return false;
};

export const removeWhiteSpaces = (str) => {
  if (!str) return "";
  return str.replace(/\s/g, "");
};
export const checkValidity = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/[^A-Z0-9]/gi, "");
};

export const RemoveSpaceAndChar = (name) => {
  if (!name) return "";
  return name.replace(/[^A-Z0-9]/gi, "");
};

export const removeSpaceIns = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/\s/g, "");
};
export const toTitleCase = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.toLowerCase().replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase());
};
export const CapsFirstLetter = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
};
export const convertToLower = (val) => {
  const v = val.trim();
  if (v) return v.toLowerCase();
  return val;
};
export const convertToUpper = (val) => {
  const v = val?.trim();
  if (v) return v.toUpperCase();
  return val;
};

export const getPercentage = (obtained, total) => {
  return Math.round((obtained / total) * 100) || 0;
};

export const convertYYYYMMDDToDate = (str, format) => {
  const year = str.slice(0, 4);
  const month = str.slice(4, 6);
  const day = str.slice(6, 8);

  return moment(`${year}-${month}-${day}`).format(format || "MMM DD,YYYY");
};

export const claimLogsTitle = (msg) => {
  if (msg?.toLowerCase() === "submit for bill") {
    return "Submitted for bill";
  } else if (msg?.toLowerCase() === "re-submit for bill") {
    return "Re-Submitted for bill";
  }
  return msg;
};

export const convertMmYyyyToDate = (mmyyyy) => {
  var month = parseInt(mmyyyy.substring(0, 2)) - 1; // Months are zero-indexed
  var year = parseInt(mmyyyy.substring(2));
  return new Date(year, month, 1);
};

export const getCallTime = (clt) => {
  if (!clt) return "";
  const time = clt.split(":");
  if (time[0].length == 1) time[0] = "0" + time[0];
  const calltime = [...time];
  if (calltime[1] % 15 != 0) {
    calltime[1] = calltime[1] - (calltime[1] % 15);
    if (calltime[1] == 0) calltime[1] = "00";
    return calltime.join(":");
  }
  return time.join(":");
};
export const getValidDep = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};
export const getValidReg = (reg) => {
  if (!reg) return "";
  return CapsFirstLetter(reg.replace(/  +/g, " "));
};
export const getValidSpaces = (name) => {
  if (!name) return "";
  return name.replace(/  +/g, " ");
};

export const isValidName = (name) => {
  if (!name) return false;
  return true;
  if (new RegExp(/^(?!.*([a-z])\1{1})[A-Za-z ]{1,75}$/i).test(name)) {
    return true;
  } else return false;
};
export const isValidPhone = (phone) => isValidNumber(phone, "US");
export const validatePhone = (value) => {
  if (value) return isPossiblePhoneNumber(value);
  return false;
};

export const getPageNumbers = (items) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};
export const isValidDob = (dobs) => {
  if (new RegExp(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/).test(dobs)) {
    return true;
  }
  return false;
};

export const isValidString = (str) => {
  if (new RegExp(/[a-z]/g).test(str)) {
    return true;
  }
  return false;
};

export const calcHeight = (value, width) => {
  const lineHeight = 20; // Adjust this value based on your line-height CSS

  // Calculate the number of lines based on the width and line breaks
  const textareaWidth = width; // Subtract padding and border widths
  const lineBreaks = (value.match(/\n/g) || []).length;
  const numberOfLines = Math.floor((value.length * lineHeight) / width) + lineBreaks;
  // Calculate the new height
  const newHeight = numberOfLines * lineHeight;
  return newHeight;
};

export const isValidDobField = (dob, format) => {
  if (!dob) return false;
  return moment(dob, `${format}`, true).isValid();
};
export const parseBooleanValue = (val) => (val ? "Yes" : "No");
export const getVaccinated = (vac) => {
  if (!vac) return false;
  const val = vac.toLowerCase();
  if (val) return val === "y" || val === "yes" ? true : false;
  return false;
};
export const capitalizeLetter = (value) => {
  if (!value) return "";
  if (value.length === 2) return value.toUpperCase();
  return `${value.substring(0, 1).toUpperCase()}${value.substring(1).toLowerCase()}`;
};

export const getTimeZonesArr = () => {
  return momentTZ.tz.names();
};

export const getCrewsName = (empDemos) => {
  if (!empDemos) return "";
  let parsed = typeof empDemos === "string" ? JSON.parse(empDemos) : empDemos;
  let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
  let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
  return `${first} ${last}`;
};
export const onBoardingTest = (test) => {
  if (Array.isArray(test)) return [...test];
  if (test instanceof Object) return [{ ...test }];
  return [];
};

export const emailMsgToCreatedMember = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your health.<br><br>

        Please login to <a href="https://www.ltc.safecampmd.com/member-login">https://www.ltc.safecampmd.com/member-login</a> To view your profile, order tests and update your insurance information.<br><br>

        Username: ${userName}<br>
        Password: ${password}<br><br>

        We thank you again for choosing us to service your critical healthcare needs<br><br>

        Best Regards,<br>
        SafeCamp LTC Team`;

  return msg;
};

export const emailMsgToSubAgent = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To order tests, add members, track shipments, and update any information required.<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const emailMsgToCreatedFacility = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To order tests, add members, add sub agents, track shipments, and update any information required.<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const emailMsgToCreatedClient = (firstName, userName, password) => {
  const msg = `Thank you ${firstName}  for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To create agents/facilities, order tests, add members, track shipments, and update any information required<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const formatOrder = (obj, employees) => {
  const address = [];
  const orderObj = { ...obj };
  const emp = (employees || []).find((f) => f.id === obj.employeeID);
  orderObj.details = typeof orderObj.details === "string" ? JSON.parse(orderObj.details) : orderObj.details;
  address.push(orderObj.details?.street || "");
  if (orderObj.details?.street2) address.push(orderObj.details?.street2 || "");
  address.push(orderObj.details?.city || "");
  return {
    ...orderObj,
    lastName: orderObj.lastName,
    firstName: orderObj.firstName,
    phoneNumber: orderObj.phoneNumber ? `${orderObj.countryCode}${orderObj.phoneNumber}` : "",
    email: orderObj.email,
    siteName: orderObj.locationName,
    medicalNo: orderObj.medicalNo,
    orderId: orderObj.orderId === "-1" ? "-" : orderObj.orderId,
    address: address.join(","),
    employeeClaimID: orderObj.employeeClaimID || "",
    claim: orderObj.claimSubmitDate ? "1" : "0",
    employeeStatus: emp?.status,
    note: {
      ...(orderObj.note ? orderObj.note : {}),
      message: emp?.note || orderObj.note?.message || "",
    },
  };
};

export const formatTest = (testData, clients) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
  }
  const test = { ...testData };
  test.viewedResult = testData.result;
  if (clients && clients.length > 0) {
    const client = clients.find((c) => c.id === test.clientID);
    if (client && client.resultType === "P/F") {
      test.viewedResult = AMAZON_CLIENT_RESULT[test.result];
    }
  }

  test.status = testData.status.toLowerCase() === "pending" && !testData.result ? "New" : testData.status;
  test.test_type =
    testData.test_type === "Antigen"
      ? "Rapid Antigen"
      : testData.test_type === "Other"
      ? "Rapid Accula"
      : testData.test_type;
  return { ...test, name, firstName, lastName };
};

export const getDropDownValue = (val) => {
  if (val === null || val === undefined) return null;
  if (val) return { value: true, label: "Yes" };
  return { value: false, label: "No" };
};
export const formatQuestion = (ques) => {
  return ques.map((q) => ({ ...q, question: JSON.parse(q.question) }));
};
export const getDemoGraphics = (test) =>
  typeof test.employee_demographics === "string" ? JSON.parse(test.employee_demographics) : test.employee_demographics;

export const formatDateOfBirth = (dob) =>
  dob ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}` : "";

export const getAge = (dob) => {
  const val = formatDateOfBirth(dob);
  return dob ? moment().diff(val, "years") : "";
};
export const getValidGender = (gen) => {
  if (gen.toLowerCase() == "male" || gen.toLowerCase() == "m") return "M";
  if (gen.toLowerCase() == "female" || gen.toLowerCase() == "f") return "F";
  if (gen.toLowerCase() == "x") return "X";
  return "";
};

export const formatDate = (date) => (date ? moment(date).format("MMM DD, YYYY") : "");

export const formatDateMDY = (date) => (date ? moment(date.substring(0, 10)).format("MM/DD/YYYY") : "");

export const formatDateMDYTime = (date) => (date ? moment(date).format("MM/DD/YYYY HH:mm") : "");

export const currentDate = () => moment().format("MM/DD/YYYY");

export const getDifferentColors = (colorNum, colors, opacity = 1) => {
  if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
  return "hsl(" + ((colorNum * (360 / colors)) % 360) + ",100%,50%," + opacity + ")";
};

export const formatPDFName = (demos) => {
  let name = "";
  if (demos.lastName) name = `${demos.lastName.substring(0, 1)}${demos.lastName.substring(1).toLowerCase()}`;
  if (name) name = `${name}, `;
  if (demos.firstName) name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName.substring(1).toLowerCase()}`;
  return name;
};

export const parseClaimDate = (date) => {
  if (!date) return "";
  return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
};

export const formatZip = (val) => {
  if (!val) return "";
  const input = val.replace(/\D/g, "");
  if (input.toString().length > 5) {
    return input.substring(0, 5);
  }
  if (input.toString().length === 5) {
    return input.toString();
  }
  return input;
};

const parseObject = (obj, key) => {
  return typeof obj[key] === "string" ? JSON.parse(obj[key]) : obj[key];
};

export const formatClaims = (models) => {
  let claims = [...models];
  claims = claims.map((m) => {
    m["provider"] = parseObject(m, "provider");
    m["proc_array"] = parseObject(m, "proc_array");
    m["acknowledgeBy"] = parseObject(m, "acknowledgeBy");
    m["assign_details"] = parseObject(m, "assign_details");
    m["paid_insurance"] = parseObject(m, "paid_insurance");

    if (m.paidAmount && m.proc_array.some((s) => !s.isClaimProcess)) {
      return { ...m, partialAmount: true, paid_ins_processed: m.paid_insurance?.payer_name || "" };
    }
    let subBy = m.submittedByName || m.updatedByName;
    if (subBy === "0") subBy = m.updatedByName;
    return {
      ...m,
      partialAmount: false,
      reSubmissionDate: m.reSubmissionDate || m.createdAt,
      paid_ins_processed: m.paid_insurance?.payer_name || "",
      submittedByName: subBy,
    };
  });
  return claims;
};

export const changeSort = (
  sortBy,
  sortDescending,
  filteredTests,
  setFilteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  switch (sortBy) {
    case "employee_demographics":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
                ? -1
                : 0
            )
      );
      break;
    case "clientID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) < getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) < getClientName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) > getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) > getClientName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "siteID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "labID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
            )
      );
      break;
    case "result":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
            )
      );
      break;
    case "qaDone":
    case "testDone":
    case "checkIn":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
            })
      );
      break;
    case "eligibilityStatus":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy]?.message || "";
              const testerA = a[sortBy]?.message || "";
              return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy]?.message || "";
              const testerA = a[sortBy]?.message || "";
              return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
            })
      );
      break;
    case "employeeID":
    case "status":
    case "callTime":
    case "isVaccinated":
    case "programName":
    case "zoneColor":
    case "localNo":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
    case "tests":
    case "clientName":
    case "siteName":
    case "locationName":
    case "subAgentName":
    case "gender":
    case "medicareNo":
    case "medicalNo":
    case "insuranceGroupId":
    case "onBoardingTesting":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "totalTests":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
            )
      );
      break;
    default:
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
  }
};

export const sortList = (sortBy, sortDescending, filteredTests) => {
  switch (sortBy) {
    case "employee_demographics":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
              ? -1
              : 0
          );
    case "qaDone":
    case "testDone":
    case "checkIn":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
          });
    case "eligibilityStatus":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy]?.message ?? "";
            const testerA = a[sortBy]?.message ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy]?.message ?? "";
            const testerA = a[sortBy]?.message ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });

    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "clientName":
    case "siteName":
    case "locationName":
    case "subAgentName":
    case "lastName":
    case "departmentName":
    case "tester_name":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
    case "totalTests":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
          );
    default:
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
  }
};

export const isValidFile = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files are allowed";
    }

    if (file.size / 1024 / 1020 > 10) {
      return "max. 10MB file size allow";
    }

    return "";
  }
  return "";
};
export const formatDateMatrix = (d) => moment(d).format("ddd MM/DD");

export const importPhoneNoFormat = (phone) => {
  if (!phone) return;
  const formattedPhoneNo = phone.replace(/\D/g, "");
  if (formattedPhoneNo && formattedPhoneNo.length == 10) {
    return `+1${formattedPhoneNo}`;
  }
  return `+${formattedPhoneNo}`;
};
export const searchPhone = (phone) => {
  if (!phone) return null;
  return phone.includes("+") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const setPhoneNo = (phone) => {
  if (!phone) return null;
  return phone.includes("+1") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const getPhoneNumber = (phone) => {
  if (!phone) return "";
  const countryCode = phone.substring(0, 1);

  if (countryCode !== "+") {
    const phoneNumber = `+${phone.replace(/\D/g, "")}`;
    return phoneNumber;
  }
  return phone;
};

export const insuranceProviderFormatter = (insuranceCompany) => {
  if (!insuranceCompany) return "-";
  const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
  if (!item) return "-";
  return item?.label;
};

export const getStateLength = (obj) => {
  if (
    !obj.country ||
    obj.isoCode === "US" ||
    obj.isoCode === "CA" ||
    obj.country === "United States" ||
    obj.country === "Canada"
  ) {
    return 2;
  }
  return 8;
};
export const formatZipCode = (id) => {
  if (!id) return id;
  let val = id.split("-").join("");
  return val.match(/.{1,5}/g).join("-");
};

export const isValidLocation = (loc) => {
  if (!loc) return null;
  const val = loc.toLowerCase();
  return LOCATION.includes(val);
};

export const isValidRegion = (reg) => {
  if (!reg) return null;
  const val = reg.toLowerCase();
  return REGION.includes(val);
};

export const getInitials = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");
};

export const medFlowInLocalStorage = {
  save: () => localStorage.setItem("medFlowHR", "medFlow"),
  get: () => localStorage.getItem("medFlowHR") || null,
  clear: () => localStorage.clear(),
};

export const loggedInUser = {
  save: (obj) => localStorage.setItem("ltcUserInfo", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("ltcUserInfo")) {
      return JSON.parse(localStorage.getItem("ltcUserInfo"));
    }
    return {
      name: "",
      email: "",
      phone: "",
    };
  },
  clear: () => localStorage.removeItem("ltcUserInfo"),
};

export const patientDataInLocalStorage = {
  save: (obj) => localStorage.setItem("client", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("client")) {
      return JSON.parse(localStorage.getItem("client"));
    }
    return newRegisterObj;
  },
  clear: () => localStorage.clear(),
};

export const sortingFilterInLC = {
  save: (obj) => localStorage.setItem("medflowhr", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("medflowhr")) {
      return JSON.parse(localStorage.getItem("medflowhr"));
    }
    return {
      employees: {},
      clients: {},
      orders: {},
      facilities: {},
      users: {},
      subAgents: {},
    };
  },
  clear: () => localStorage.removeItem("medflowhr"),
};
export const userCompanyID = {
  save: (id) => localStorage.setItem("cid", id),
  get: () => localStorage.getItem("cid") || null,
  clear: () => localStorage.removeItem("cid"),
};
export const userCompanyLogo = {
  save: (img) => localStorage.setItem("ucl", img),
  get: () => localStorage.getItem("ucl") || null,
  clear: () => localStorage.removeItem("ucl"),
};

export const formatDateOfBirthDOB = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.length === 8) {
    const month = dob.substring(0, 2);
    const day = dob.substring(2, 4);
    const year = dob.substring(4);
    return moment(`${year}${month}${day}`).format("MM/DD/YYYY");
  } else return moment(dob).format("MM/DD/YYYY");
};

export const formatDOB = (dobs) => {
  if (!dobs) return "";
  const dob = dobs.split(/\/|-|,/g);
  if (dob[0].length === 1) {
    dob[0] = "0" + dob[0];
  }
  if (dob[1].length === 1) {
    dob[1] = "0" + dob[1];
  }
  return moment(`${dob[2]}-${dob[0]}-${dob[1]}`).format("YYYY-MM-DD");
};

export const calculateTdWidth = (width, numberOfCol) =>
  screen.width >= "768" && screen.width <= "1024" ? 100 : Math.ceil(width / numberOfCol);

export const personalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = arr
      .filter((f) => f.isCheck)
      .map((f) => f.id)
      .join(",");

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = objData[key].split(",");
    } catch (err) {}

    return NEW_PERSONALIZE.map((f) => {
      return { ...f, isCheck: arrData?.indexOf(f.id.toString()) !== -1 };
    });
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const draggablePersonalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = JSON.parse(objData[key]);
    } catch (err) {}

    if (!Array.isArray(arrData)) return NEW_PERSONALIZE;

    let objToReturn = arrData?.map((f) => {
      const obj = NEW_PERSONALIZE.find((obj) => obj.id == f.id) || null;
      return { ...obj, isCheck: NEW_PERSONALIZE.findIndex((obj) => obj.id == f.id) !== -1, width: f.width };
    });

    const lsData = arrData?.map((obj) => obj.id);
    if (!lsData) return NEW_PERSONALIZE;
    const nonCheckedData = NEW_PERSONALIZE.filter((obj) => !lsData.includes(obj.id));
    objToReturn = [
      ...objToReturn,
      ...nonCheckedData.map((data) => {
        return { ...data, isCheck: false, width: "100%" };
      }),
    ];
    return objToReturn;
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const tdCheckBoxIcon = (icon, colorTheme, shape, size) => {
  return (
    <td style={{ textAlign: "center", textOverflow: "visible" }}>
      <div className="star-icon-wrapper">
        <Status
          type={shape}
          size={size}
          color={colorTheme}
          crossIcon={icon === CHECKBOX_ICON_OPTIONS.cross}
          tickIcon={icon === CHECKBOX_ICON_OPTIONS.tick}
          questionIcon={icon === CHECKBOX_ICON_OPTIONS.question}
        />
      </div>
    </td>
  );
};

export const tdEmail = (email) => (
  <td
    key={email}
    className=" ellipsis"
    style={{
      textDecoration: "underline",
      color: "var(--text-danger)",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => email && window.open(`mailto:${email}`)}
    title={email}
  >
    {email}
  </td>
);

export const tdRowDropdown = (showDropdown, isOpen, handleClick, cssClass) => {
  return (
    <td className={cssClass ?? ""}>
      {showDropdown && (
        <i
          onMouseLeave={(e) => (e.target.style.color = "inherit")}
          onMouseOver={(e) => {
            e.target.style.color = "#8B0000";
            e.target.style.cursor = "pointer";
          }}
          onClick={handleClick}
          aria-hidden="true"
          className={isOpen ? "fas fa-minus" : "fas fa-plus"}
        />
      )}
    </td>
  );
};

export const getHours = (time) => {
  return time.split(":")[0] + ":00";
};

export const formatOrderedDate = (date) =>
  date ? `${date.substring(4, 6)}/${date.substring(6, 8)}/${date.substring(0, 4)}` : "";
export const formatOrderedDateAws = (date) =>
  date ? `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}` : "";

export const tdOrderDate = (item, user) => {
  return (
    <td
      className="ellipsis"
      style={{
        textAlign: item.textAlign,
        textOverflow: item.textOverflow,
      }}
      title={formatOrderedDate(user.orderDate)}
    >
      {formatOrderedDate(user.orderDate)}
    </td>
  );
};

export const tdPhone = (phoneNumber) => (
  <td
    key={phoneNumber}
    className="ellipsis"
    style={{
      textDecoration: "underline",
      color: "var(--text-danger)",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)}
    title={formatPhoneNumber(phoneNumber)}
  >
    {formatPhoneNumber(phoneNumber)}
  </td>
);

export const tdLink = (item, dates, itemID, filterKey) => (
  <td
    className="ellipsis"
    style={{
      textAlign: "center",
      textOverflow: "none",
    }}
  >
    {item > 0 ? (
      <Link
        style={{ color: "#42cef5" }}
        to={{
          pathname: "/admin/orders",
          state: {
            name: itemID,
            term: filterKey,
            filterName: dates,
            filterTerm: dates,
          },
        }}
      >
        {formatNumber(item)}
      </Link>
    ) : (
      item
    )}
  </td>
);

export const tdTrackingID = (number) => (
  <td
    className="ellipsis"
    style={{
      textDecoration: "underline",
      color: "#A82632",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() =>
      number && window.open(`https://www.stamps.com/tracking-details/?t=${number.replace(/\D/g, "")}`, "_blank")
    }
    title={number}
  >
    {number}
  </td>
);

export const tdCheckBox = (item, user, openExternalTest) => {
  return <td style={{ textAlign: "center", textOverflow: "visible" }}>-</td>;
};

export const tdProgramStatus = (item, user) => {
  return (
    <td
      style={{ textAlign: "center", textOverflow: "visible" }}
      title={
        user.isSchedule === STATUS.startProgram
          ? "Program Started"
          : user.isSchedule === STATUS.assignProgram
          ? "Program Assigned"
          : "Program Unassigned"
      }
    >
      {user.isSchedule === STATUS.startProgram || user.isSchedule === STATUS.zoneAssign ? (
        <ViewCheckBox id="filter" bgColor="green" />
      ) : user.isSchedule === STATUS.assignProgram ? (
        <ViewCheckBox id="filter" bgColor="yellow" />
      ) : (
        "-"
      )}
    </td>
  );
};
export const tdZoneColor = (item, user) => {
  return (
    <td
      style={{
        textAlign: item.textAlign,
        textOverflow: item.textOverflow,
      }}
      title={user.zoneColor}
    >
      {user.zoneColor ? (
        <div
          className="m-auto"
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: `${removeSpaceIns(user.zoneColor)}`,
          }}
        ></div>
      ) : (
        ""
      )}
    </td>
  );
};
export const formatTimeZone = (Tz) => {
  if (!Tz) return "";
  if (Tz === "Pakistan Standard Time") return "PKT";
  return Tz.match(/\b\w/g).join("");
};
export const statusOptions = () => {
  return [
    { value: "0", label: "Empty" },
    {
      value: "1",
      label: <ViewCheckBox id="filter" bgColor="green" />,
    },
    {
      value: "2",
      label: <ViewCheckBox id="filter" bgColor="yellow" />,
    },
  ];
};

export const isValidEmailAttachment = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif", "pdf", "csv", "xls", "xlsx", "doc", "docx", "txt"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files, PDF, CSV are allowed";
    }

    if (file.size > 10000000) {
      return "Maximum file size should be 10MB!";
    }

    return "";
  }
  return "";
};

export const formatNumberWithDecimal = (num) => {
  const parsedNumber = parseFloat(num);
  if (isNaN(parsedNumber)) {
    return num;
  }

  const hasDecimal = /\./.test(num);
  const formattedNumber = hasDecimal ? parsedNumber.toFixed(2) : `${parsedNumber}.00`;
  return formattedNumber;
};

export const formatNumber = (num) => {
  if (!num) return 0;
  return num.toLocaleString("en-US");
};

export const formatEmployeesData = (employees, subAgents, locations, companies, providers) => {
  if (employees.length === 0) return [];

  return employees.map((emp) => {
    const subAgent = subAgents?.find((s) => s.id === emp.subAgent);
    const location = locations?.find((s) => s.id === emp.companyID);
    const client = companies?.find((c) => c.id === emp.clientID);
    const provider = providers?.find((p) => p.id === emp.renderingProvider);
    const refProvider = providers?.find((p) => p.id === emp.referringProvider);
    const ordProvider = providers?.find((p) => p.id === emp.orderingProvider);
    return {
      ...emp,
      phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
      subAgent: subAgent ? { ...subAgent, value: subAgent.id, label: subAgent.name } : null,
      location: location ? { ...location, value: location.id, label: location.name } : null,
      client: client ? { ...client, value: client.id, label: client.name } : null,
      gender: emp.sex,
      secondaryInsurance: emp.insuranceDetails?.insuranceCompany || "",
      secondaryInsNumber: emp.insuranceDetails?.medicalNo || "",
      clientName: client ? client.name : "N/A",
      locationName: location ? location.name : "N/A",
      subAgentName: subAgent ? subAgent.name : "N/A",
      subAgentID: subAgent ? subAgent.id : null,
      providerName: provider?.name,
      providerFirstName: provider?.firstName,
      providerLastName: provider?.lastName,
      providerMidName: provider?.middleName,
      providerID: provider?.id,
      providerNpi: provider?.npi,
      providerTaxId: provider?.taxId,
      refProviderID: refProvider?.id,
      refProviderFirstName: refProvider?.firstName,
      refProviderName: refProvider?.name,
      refProviderLastName: refProvider?.lastName,
      refProviderMiddleName: refProvider?.middleName,
      refProviderNpi: refProvider?.npi,
      ordProviderID: ordProvider?.id,
      ordProviderFirstName: ordProvider?.firstName,
      ordProviderName: ordProvider?.name,
      ordProviderLastName: ordProvider?.lastName,
      ordProviderMiddleName: ordProvider?.middleName,
      ordProviderNpi: refProvider?.npi,
    };
  });
};

export const formatLocations = (locations, companies) => {
  if (locations.length === 0) return [];

  return locations.map((emp) => {
    const client = companies.find((s) => s.id === emp.companyID);

    return {
      ...emp,
      clientName: client ? client.name : "N/A",
      client,
    };
  });
};

export const getValidName = (name) => {
  if (!name) return "";
  return toTitleCase(name.trim().replace(/  +/g, " "));
};

export const isValidIdNum = (id) => {
  if (!id) return false;
  if (new RegExp(/^(?!.*([a-zA-Z0-9])\1{4})[a-zA-Z0-9]{5,16}$/).test(id)) {
    return true;
  } else return false;
};
export const isValidChequeNumber = (id) => {
  if (!id) return false;
  if (new RegExp(/^\d{6}$/).test(id)) {
    return true;
  } else return false;
};
export const isValidAmount = (id) => {
  console.log("amount", id);
  if (!id) return false;
  if (new RegExp(/^\d+(\.\d+)?$/).test(id)) {
    return true;
  }
  return false;
};

export const isValidPhoneWithCode = (phone) => {
  if (phone) return isPossiblePhoneNumber(phone);
  return false;
};

export const formatOrderDate = (date) => (date ? moment(date).format("MM-DD-YYYY hh:mm A") : "");

export const isValidIDNumber = (val) => {
  if (!val) return false;
  if (new RegExp(/^(?=.*[0-9])([a-zA-Z0-9]{8,})+$/).test(val)) {
    return true;
  }
  return false;
};

export const isValidZipCode = (code, zip) => {
  if (!zip) return false;
  const validate = postalCodes.validate(`${code}`, `${zip}`);

  return typeof validate !== "string" ? validate : false;
};

export const getPhoneNo = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }

    const phone = formatPhoneNumberIntl(phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    phoneArr.splice(0, 1);
    const phoneNo = phoneArr.join("");
    return [countryCode, phoneNo, phone];
  } catch (err) {
    console.log("Error", err);
  }
  return ["", phone_number_value, phone_number_value];
};

export const getPreRegistrationLink = (id, type = "") => {
  if (CONFIG.isLive) {
    return `https://ltc.safecampmd.com/order-test${type}/${id}`;
  }
  return `https://staging.ltc.safecampmd.com/order-test${type}/${id}`;
};

export const isValidUrl = (file) => {
  if (!file) return false;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = typeof file === "object" ? URL.createObjectURL(file) : file;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

export const DataFilteringBinarySearch = (searchQuery, data) => {
  let start = 0;
  let end = data.length - 1;
  let mid;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);

    if (data[mid].value.toLowerCase().includes(searchQuery.toLowerCase())) {
      break;
    }

    if (data[mid].value.toLowerCase() < searchQuery.toLowerCase()) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  if (start <= end) {
    const filteredData = [];

    let i = mid;

    while (i >= 0 && data[i].value.toLowerCase().includes(searchQuery.toLowerCase())) {
      filteredData.unshift(data[i]);
      i--;
    }

    i = mid + 1;

    while (i < data.length && data[i].value.toLowerCase().includes(searchQuery.toLowerCase())) {
      filteredData.push(data[i]);
      i++;
    }

    return filteredData;
  }

  return [];
};

export const formatEmployeeClaim = (claim, setting, isMulti) => {
  const data = { ...claim, ...(claim.provider || {}) };

  const keysToDelete = [
    "mod1_2",
    "mod2_2",
    "mod3_2",
    "mod4_2",
    "proc_code_2",
    "thru_date_2",
    "units_2",
    "place_of_service_2",
    "proc_code_2",
    "remote_chgid_2",
  ];
  if (data) {
    keysToDelete.forEach((key) => {
      if (data.hasOwnProperty(key)) {
        delete data[key];
      }
    });
  }
  let procArray = [];
  let totalCharge = 0;
  if (data.proc_array.length > 0 && !isMulti) {
    for (let i = 0; i < data.proc_array.length; i++) {
      const proc = data.proc_array[i];
      const obj = {
        diag_ref_1: proc.diag_ref_1,
        diag_ref_2: proc.diag_ref_2,
        diag_ref_3: proc.diag_ref_3,
        diag_ref_4: proc.diag_ref_4,
      };
      totalCharge = totalCharge + parseInt(proc.charge);
      const index = i + 1;
      Object.assign(data, {
        [`diag_ref_${index}`]: Object.values(obj)
          .filter((f) => f)
          .join(","),
        [`from_date_${index}`]: proc.from_date,
        [`thru_date_${index}`]: proc.from_date,
        [`charge_${index}`]: parseInt(proc.charge),
        [`units_${index}`]: proc.units,
        [`proc_code_${index}`]: proc.proc_code,
        [`place_of_service_${index}`]: proc.place_of_service,
        [`remote_chgid_${index}`]: `${data.remote_claimid}_${index}`,
        [`mod1_${index}`]: proc.mod1,
        [`mod2_${index}`]: proc.mod2,
        [`mod3_${index}`]: proc.mod3,
        [`mod4_${index}`]: proc.mod4,
      });
      procArray.push({ ...proc, remote_chgid: `${data.remote_claimid}_${index}` });
    }
  }
  const [countryCode, phoneNo] = getPhoneNo(data.bill_phone);
  Object.assign(data, {
    total_charge: totalCharge,
    bill_phone: phoneNo,
    accept_assign: "Y",
    pat_country: "",
    pat_rel: "18",
    proc_array: procArray,
    facility_name: setting.name,
    facility_npi: setting.npi,
    facility_addr_1: setting.street,
    facility_addr_2: setting.street2,
    proc_array: procArray,
    facility_city: setting.city,
    facility_state: setting.state,
    facility_zip: setting.zip,
    payer_icn: claim.status === CLAIM_SUBMIT_STATUS.denied && claim.payerid !== "01192" ? claim.claimNo : "",
    assigned_comment: claim.assign_details?.message.replace(/'/g, "") || "",
  });
  return data;
};

export const removeKeysFromObject = (obj, keysToRemove) => {
  if (!obj || typeof obj !== "object" || !Array.isArray(keysToRemove)) {
    return obj;
  }

  keysToRemove.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      delete obj[key];
    }
  });

  return obj;
};

export const setProceduresFromDate = (claimToBeEdited, date) => {
  const claim = { ...claimToBeEdited };
  const proc_arr_to_save = claim.proc_array || [];
  for (let i = 0; i < proc_arr_to_save.length; i++) {
    const obj = { ...proc_arr_to_save[i] };
    obj.from_date = date;
    proc_arr_to_save.splice(i, 1, obj);
  }
  Object.assign(claimToBeEdited, {
    proc_array: proc_arr_to_save,
    from_date_1: date,
  });
  return claimToBeEdited;
};

export const formatViewUpdateClaimList = (arr) => {
  return arr.map((obj) => {
    if (obj.eventType === "ClaimUpdated" && obj.changeObject) {
      let changeObject = {};
      Object.entries(obj.changeObject).forEach(([item, value]) => {
        let record = {};
        Object.entries(value).forEach(([m, v]) => {
          if (v.M) {
            record = { ...record, ...v.M };
          }
          if (v.L) {
            // for (const proc of v.L) {
            //   let procObj = {};
            //   if (proc.M) {
            //     Object.entries(procObj.M).forEach(([p, val], index) => {
            //       procObj[`${p}_${index + 1}`] = val;
            //     });
            //   }
            // }
            record = { ...record, ...v.L[0].M };
          }
          record[m] = v;
        });
        changeObject[item] = record;
      });
      return { ...obj, changeObject };
    } else {
      return obj;
    }
  });
};

export const convertToDropDownOption = (arr, isCallFromClaimPage) => {
  let items = [...arr];
  if (items.indexOf("Date of Birth") === -1) items.push("Date of Birth");
  if (isCallFromClaimPage) {
    const keysToDelete = ["Processed By Secondary Ins", "Resubmitted Claims", "Crossover Claims", "Blank"];
    return items
      .filter((item) => !keysToDelete.includes(item))
      .map((v) => {
        return { value: v, label: v };
      });
  }
  return items
    .filter((item) => item !== "Black")
    .map((v) => {
      return { value: v.replace(/ /g, "_"), label: v };
    });
};

export const parseErrorMessageString = (input) => {
  if (!input) return [];
  const errorMessages = input.split("|");
  let parsedData = [];

  errorMessages.forEach((errorMessage) => {
    const [code, _, field, message] = errorMessage.split(":");
    const obj = { code, field, message };
    if (errorMessage.includes("(resolve)")) {
      Object.assign(obj, { resolve: "(resolve)" });
    }
    parsedData.push(obj);
  });

  if (parsedData.length === 1 && !parsedData[0].field && !parsedData[0].messages) {
    const errorMessages = input.split(",");
    const nestedPushed = [];
    errorMessages.forEach((errorMessage) => {
      const [code, _, field, message] = errorMessage.split(":");
      const obj = { code, field, message };
      if (errorMessage.includes("(resolve)")) {
        Object.assign(obj, { resolve: "(resolve)" });
      }
      nestedPushed.push(obj);
    });
    parsedData = nestedPushed;
  }

  return parsedData;
};

export const createErrorMessageString = (input) => {
  if (!input || !Array.isArray(input) || input.length === 0) return "";

  const errorMessages = input.map((error) => {
    if (!error) return "";
    const { code, field, message, resolve } = error;
    return `${code ? `${code}:` : ""}${field ? `${field}:` : ""}${message || ""}${resolve || ""}`;
  });

  return errorMessages.join("|");
};

export const getIntVal = (val) => {
  if (!val) return 0;
  try {
    return parseInt(val);
  } catch (err) {
    return 0;
  }
};

export const getFloatVal = (val) => {
  if (!val) return 0;
  try {
    return parseFloat(val);
  } catch (err) {
    return 0;
  }
};

export const isMatchString = (originalFirstName, originalLastName, firstName, lastName) => {
  const similarityThreshold = 0.9; // Set the similarity threshold to 90%

  const firstNameSimilarity = calculateSimilarity(originalFirstName, firstName);
  const lastNameSimilarity = calculateSimilarity(originalLastName, lastName);

  return firstNameSimilarity >= similarityThreshold && lastNameSimilarity >= similarityThreshold;
};

const calculateSimilarity = (originalValue, value) => {
  const minLength = Math.min(originalValue.length, value.length);
  const maxLength = Math.max(originalValue.length, value.length);

  let matchingChars = 0;
  for (let i = 0; i < minLength; i++) {
    if (originalValue[i].toLowerCase() === value[i].toLowerCase()) {
      matchingChars++;
    }
  }

  const similarity = matchingChars / maxLength;
  return similarity;
};

export const trimObject = (obj) => {
  const trimmedObj = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const trimmedKey = key.trim();
      const value = obj[key];
      let trimmedValue;

      if (typeof value === "string") {
        trimmedValue = value.trim();
      } else if (typeof value === "object") {
        trimmedValue = trimObject(value);
      } else {
        trimmedValue = value;
      }

      trimmedObj[trimmedKey] = trimmedValue;
    }
  }

  return trimmedObj;
};

const findDiagnosisDesc = (value) => {
  const option = DIAGNOSIS_OPTIONS.find((opt) => opt.value.toLowerCase().includes(value.toLowerCase()));
  if (option) return option.label;
  return "";
};

export const generateHL7Message = (claim) => {
  console.log("claim", claim);

  const mshSegment = `MSH|^~\&|${claim.prov_name}|SendingFacility||ReceivingFacility|${moment().format(
    "YYYYMMDDHHMM"
  )}||ORM^O01|Q2500661889|T|2.3`;
  const pidSegment = `PID|1||${claim.remote_claimid}||${claim.pat_name_l}^${claim.pat_name_f}^${
    claim.pat_name_m || ""
  }||${moment(claim.pat_dob).format("YYYYMMDD")}|${claim.pat_sex}|||${claim.pat_addr_1 || ""}^^${
    claim.pat_city || ""
  }^${claim.pat_state || ""}^${claim.pat_zip || ""}||${claim.pat_phone || ""}^CP^${claim.pat_email || ""}||||||`;
  const pvSegment = `PV1|1||SendingFacility|||||||||`;
  const in1Segment = `IN1|1|||${claim.payer_name || ""}|${claim.ins_addr_1 || ""}^^${claim.ins_city || ""}^${
    claim.ins_state || ""
  }^${claim.ins_zip || ""}|||||||Plan Effective Date|Plan Expiration Date|||${claim.ins_name_l}^${
    claim.ins_name_f
  }|Self|${moment(claim.ins_dob).format("YYYYMMDD")}|${claim.pat_addr_1 || ""}^^${claim.pat_city || ""}^${
    claim.pat_state || ""
  }^${claim.pat_zip || ""}|||||||||||||||||InsurancePolicy#|||||||||||T|||`;

  const gt1segement = `GT1|1||${claim.pat_name_l}^${claim.pat_name_f}||${claim.pat_addr_1 || ""}^^${
    claim.pat_city || ""
  }^${claim.pat_state || ""}^${claim.pat_zip || ""}|||${moment(claim.pat_dob).format("YYYYMMDD")}|${
    claim.pat_sex
  }||Self|`;

  const dg1Segment = `DG1|1|ICD-10|${claim.diag_1}|${findDiagnosisDesc(claim.diag_1)}|`;

  const hl7Message = [mshSegment, pidSegment, pvSegment, in1Segment, gt1segement, dg1Segment].join("\r");

  return hl7Message;
};

export const calculateStringSimilarity = (str1, str2) => {
  const matrix = [];
  
  if (!str1 && !str2) return 0;

  for (let i = 0; i <= str1.length; i++) {
    matrix[i] = [i];
    for (let j = 1; j <= str2.length; j++) {
      if (i === 0) {
        matrix[i][j] = j;
      } else {
        const cost = str1[i - 1] !== str2[j - 1] ? 1 : 0;
        matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
      }
    }
  }

  const maxLen = Math.max(str1.length, str2.length);
  const distance = matrix[str1.length][str2.length];
  return 1 - distance / maxLen;
};

export const isSimilarPayer = (provider, payerName) => {
  const similarityThreshold = 0.8;
  const { label, payer_alt_names } = provider;

  if (label.toLowerCase() === payerName.toLowerCase()) {
    return true;
  }

  if (calculateStringSimilarity(label.toLowerCase(), payerName.toLowerCase()) > similarityThreshold) {
    return true;
  }

  if (payer_alt_names) {
    const similarAltNames = payer_alt_names.some(
      (altName) =>
        altName.alt_payer_name &&
        calculateStringSimilarity(altName.alt_payer_name.toLowerCase(), payerName.toLowerCase()) > similarityThreshold
    );

    if (similarAltNames) {
      return true;
    }
  }

  return false;
};
